import { AppState } from 'domain/reducers';
import api from 'libs/api';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { noticeActions } from './actions';
import { noticeIsLoadingSelector } from './selectors';

export const thunkNotice =
  (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    const state = getState();
    const isNoticeLoading = noticeIsLoadingSelector(state);
    if (isNoticeLoading) {
      return;
    }
    try {
      dispatch(noticeActions.request());
      const response = await api.account.notices.get();
      dispatch(noticeActions.success({ notices: response.data.resource }));
    } catch (e) {
      //@ts-ignore
      dispatch(noticeActions.failure(e));
    }
  };
