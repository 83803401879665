import Flex from 'components/Flex';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TIconFillingRule } from 'theme';

interface IIconWrapper {
  className?: string;
  iconFillingRule?: TIconFillingRule;
}

const IconWrapper: FunctionComponent<IIconWrapper> = ({
  children,
  className,
  iconFillingRule = [],
}) => (
  <Filled className={className} iconFillingRule={iconFillingRule}>
    {children}
  </Filled>
);

export default IconWrapper;

const Filled = styled(Flex)<{ iconFillingRule: TIconFillingRule }>`
  ${(props) =>
    props.iconFillingRule.length > 0
      ? props.theme.conditionallyFillSvg(props.theme.colors.accentNormal, props.iconFillingRule)
      : props.theme.fillSvg(props.theme.colors.accentNormal)}
`;
