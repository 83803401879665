import Flex from 'components/Flex';
import Text from 'components/Text';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as DownloadIcon } from 'icons/download.svg';
import { ReactComponent as EyeIcon } from 'icons/eye.svg';
import { ReactComponent as DefaultFileTypeIcon } from 'icons/file-types/default.svg';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { IFileView } from '../FileBlock';
import useAccount from 'hooks/useAccount';

const CommonFileView: FunctionComponent<IFileView> = ({
  caption,
  icon: FileIcon = DefaultFileTypeIcon,
  showDownloadIcon = false,
  showRemoveIcon = false,
  handleDownload = () => false,
  handleRemove = () => false,
  openGallery,
  imageIndex,
  isImage,
}) => {
  const { account } = useAccount();

  return (
    <Wrapper className="mt8" justifyContent="space-between" alignItems="center">
      <Container alignItems="center">
        <Clicked onClick={openGallery} hasIndex={Boolean(imageIndex)}>
          {openGallery && (
            <DownloadArea alignItems="center" justifyContent="center">
              <EyeIcon />
            </DownloadArea>
          )}
          {FileIcon}
        </Clicked>
        {(!account.is_hide_attach_title || !isImage) && (
          <FileName size="xs" className="ml16">
            {caption}
          </FileName>
        )}
      </Container>
      <IconWrapper>
        {showDownloadIcon && (
          <IconBlock onClick={handleDownload} alignItems="center" data-test="download-file">
            <CustomDownloadIcon />
          </IconBlock>
        )}
        {showRemoveIcon && (
          <IconBlock onClick={handleRemove} alignItems="center" data-test="remove-file">
            <CustomCloseIcon />
          </IconBlock>
        )}
      </IconWrapper>
    </Wrapper>
  );
};

export default CommonFileView;

const DownloadArea = styled(Flex)`
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  ${(props) => props.theme.fillSvg(props.theme.colors.snow)};
`;

const Wrapper = styled(Flex)`
  position: relative;
  align-items: center;
  padding: 10px;
  border: 2px solid ${(props) => props.theme.colors.border};
  margin-bottom: 6px;
  margin-right: 16px;
  box-sizing: border-box;
  ${(props) => props.theme.media.sm`
    padding: 7px;
    max-width: 200px;
  `}
  &:hover ${DownloadArea} {
    display: flex;
  }
`;

const Container = styled(Flex)`
  margin-right: 16px;
  min-width: 0;
  ${(props) => props.theme.media.sm`
    margin-right: 10px;
    max-width: 75%;
  `}
`;

const FileName = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Clicked = styled(Flex)<{ onClick?: (index: number) => void; hasIndex: boolean }>`
  position: relative;
  ${(props) => (props.hasIndex ? 'cursor: pointer;' : '')}
`;

const IconWrapper = styled(Flex)`
  padding: 5px;
`;

const IconBlock = styled(Flex)<{ onClick: any }>`
  padding: 5px;
  &:hover {
    ${(props) => props.theme.fillSvg(props.theme.colors.accentNormal)}
  }
`;

const CustomCloseIcon = styled(CloseIcon)`
  border-radius: 50%;
  border: 2px solid;
  border-color: ${(props) => props.theme.colors.border};
  transition: all 0.5s ease 0s;
  &:hover {
    border-color: ${(props) => props.theme.colors.accentNormal};
  }
`;

const CustomDownloadIcon = styled(DownloadIcon)`
  padding: 8px;
  border-radius: 4px;
  border: 2px solid;
  border-color: ${(props) => props.theme.colors.border};
  transition: all 0.5s ease 0s;
  &:hover {
    border-color: ${(props) => props.theme.colors.accentNormal};
  }
  ${(props) => props.theme.media.sm`
    padding: 4px;
  `}
`;
