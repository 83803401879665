import { ReactComponent as WebsiteIcon } from 'icons/menu/website.svg';
import { THEME_NO_SITE } from 'libs/constants';
import { NavMenuItem } from 'pages/App/Header/Menu/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from 'theme/ThemeContext';

const WebsiteMenuItem = () => {
  const { name } = useTheme();

  return (
    <NavMenuItem
      to="/"
      className="m10"
      as={Link}
      disabled={name === THEME_NO_SITE}
      data-test="menu-link-go-to-website"
    >
      <WebsiteIcon />
      <p className="ml24">Website</p>
    </NavMenuItem>
  );
};

export default WebsiteMenuItem;

export const WebsiteWrapper = styled.div`
  padding: 15px;
`;

export const MobileWebsiteWrapper = styled(WebsiteWrapper)`
  border-bottom: 2px solid ${(props) => props.theme.colors.noticeGrey};
`;
