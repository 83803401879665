import React, { FunctionComponent, ReactNode } from 'react';
import './style.css';

interface IWysiwygContent {
  children: ReactNode;
}

const WysiwygContent: FunctionComponent<IWysiwygContent> = ({ children }) => (
  <div className="wysiwyg-content">{children}</div>
);

export default WysiwygContent;
