import Flex from 'components/Flex';
import styled from 'styled-components';

const Row = styled(Flex)<{ media?: string }>`
  justify-content: space-between;
  margin-left: -8px;
  margin-right: -8px;
  ${(props) => props.theme.media[props.media || 'xs']`
    flex-direction: column;
    align-items: initial;
    & > div {
      width: initial !important;
    }
  `}
  & > div {
    flex: 1;
    margin-left: 8px;
    margin-right: 8px;
    width: calc(50% - 16px);
  }
`;

const TrRow = styled('tr')<{ media?: string }>`
  margin-left: -8px;
  margin-right: -8px;
  ${(props) => props.theme.media[props.media || 'xs']`
    & > div {
      width: initial !important;
    }
  `}
  & > div {
    flex: 1;
    margin-left: 8px;
    margin-right: 8px;
    width: calc(50% - 16px);
  }
`;

export const ErrorRow = styled(TrRow)<{ hasError?: boolean }>`
  border-left: 2px solid ${(props) => (props.hasError ? props.theme.colors.error : 'transparent')};
`;

export default Row;
