import Button from 'components/Button';
import BasicCard, { WideFormBody } from 'components/Card/Basic';
import Flex from 'components/Flex';
// import OverlaySpinner from 'components/OverlaySpinner';
import { FormikProps } from 'formik';
import useUser from 'hooks/useUser';
import { FormContainer } from 'pages/Profile/styled';
import React, { useEffect } from 'react';
import styled from 'styled-components';

export interface PaymentMethodCard {
  component: (values?: any) => JSX.Element;
  title: string;
}

interface IPaymentMethodForm {
  formCards: PaymentMethodCard[];
}

function PaymentMethodForm<T>({ formCards, ...rest }: IPaymentMethodForm & FormikProps<T>) {
  const { user, fullName } = useUser();
  const { setFieldValue, handleSubmit, isSubmitting } = rest;

  useEffect(() => {
    if (user) {
      setFieldValue('user_name', fullName, false);
      setFieldValue('user_email', user.email, false);
    }
  }, [user]);

  return (
    <FormContainer onSubmit={handleSubmit}>
      {/*<OverlaySpinner visible={isSubmitting} />*/}
      {formCards.map((item, index) => (
        <BasicCard key={index} title={item.title} className="mt16">
          <Body>{item.component(rest)}</Body>
        </BasicCard>
      ))}
      <Flex alignItems="center" direction="column" className="mt32">
        <Button type="submit" disabled={isSubmitting}>
          Save Payment Information
        </Button>
      </Flex>
    </FormContainer>
  );
}

export default PaymentMethodForm;

const Body = styled(WideFormBody)`
  padding: 30px 50px;
`;
