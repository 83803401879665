import Flex from 'components/Flex';
import OverlaySpinner from 'components/OverlaySpinner';
import Container from 'components/Website/Container';
import WebsiteMenuMobileAdaptive from 'components/Website/WebsiteMenuMobileAdaptive';
import useAuth from 'hooks/useAuth';
import useCommon from 'hooks/useCommon';
import useProfile from 'hooks/useProfile';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useDocumentMeta from 'hooks/useDocumentMeta';
import { THEME_NO_SITE, WEBSITE_URL } from 'libs/constants';
import { HeaderLeftComponent } from 'pages/App/Header';
import WebsiteHeadButton from 'pages/App/Header/ButtonLayouts/WebsiteHeadButton';
import WebsiteMenuDesktop from 'pages/App/Header/Menu/WebsiteMenu/DesktopView';
import ClassifiedsList from 'pages/Website/Classifieds/List';
import ClassifiedsSpecific from 'pages/Website/Classifieds/Specific';
import CustomPage from 'pages/Website/CustomPage';
import Documents from 'pages/Website/Documents';
import HomePage from 'pages/Website/HomePage';
import NewsList from 'pages/Website/News/List';
import NewsSpecific from 'pages/Website/News/Specific';
import React, { FunctionComponent, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { useTheme } from 'theme/ThemeContext';
import { useNavigate } from 'react-router-dom';

const WebsitePage: FunctionComponent = () => {
  const { name, isSync } = useTheme();
  const { isAuthorized } = useAuth();
  const { isNoProfile } = useProfile();
  const { isLoaded, common, type, title } = useCommon();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSync && isLoaded) {
      if (isAuthorized) {
        if (type) {
          if (name === THEME_NO_SITE) {
            navigate(isNoProfile ? '/join-to' : '/dashboard');
          }
        } else {
          navigate('/404');
        }
      } else {
        if (!type) {
          window.location.href = `//${WEBSITE_URL}`;
        } else if (isNoProfile && name === THEME_NO_SITE) {
          navigate('/sign-in');
        }
      }
    }
  }, [common, type, name, isLoaded, isNoProfile, isAuthorized, isSync]);

  useDocumentTitle(title);
  useDocumentMeta({ name: 'apple-mobile-web-app-title', content: `CondoGenie - ${title}` });

  return isLoaded ? (
    <>
      <WebsiteHeadButton className="ml8" />
      <HeaderLeftComponent>
        <WebsiteMenuMobileAdaptive />
      </HeaderLeftComponent>
      <Flex>
        <WebsiteMenuDesktop />
        <Container>
          <Routes>
            <Route path="/documents" element={<Documents />} />
            <Route path="/announcements/:id" element={<NewsSpecific />} />
            <Route path="/announcements" element={<NewsList />} />
            <Route path="/classifieds/:id" element={<ClassifiedsSpecific />} />
            <Route path="/classifieds" element={<ClassifiedsList />} />
            <Route path="/:slug" element={<CustomPage />} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </Container>
      </Flex>
    </>
  ) : (
    <OverlaySpinner visible={!isLoaded} />
  );
};

export default WebsitePage;
