import BasicCard from 'components/Card/Basic';
import ImageLayout, { Container } from 'components/ImageLayout';
import { Formik } from 'formik';
import useFetchResource from 'hooks/useFetchResource';
import useOnSubmit from 'hooks/useOnSubmit';
import api from 'libs/api';
import React, { FunctionComponent } from 'react';
import { Body } from '../styled';
import Form, { IEmailNotificationSettingsValues } from './Form';
import { useParams } from 'react-router-dom';

const EmailNotificationsSettings: FunctionComponent = () => {
  const { hash } = useParams();
  const initialValues = {
    daily_summary: true,
    email_notification_amenity: true,
    email_notification_classifieds: true,
    email_notification_documents: true,
    email_notification_events: true,
    email_notification_recurring_events: true,
    email_notification_message_forum: true,
    email_notification_news: true,
    email_notification_tasks: true,
    email_notification_completed_task: true,

    email_notification_unit_message: true,
    unsubscribe_all_emails: false,
  };

  const { resource } = useFetchResource<IEmailNotificationSettingsValues>({
    api: api.profile.unsubscribe.getOne,
    initialParams: { hash },
    initialValues,
  });

  const onEmailNotificationsSubmit = useOnSubmit<IEmailNotificationSettingsValues>({
    api: api.profile.unsubscribe.update,
    params: { hash },
    successMessage: 'Email notification settings has been updated',
  });

  return (
    <ImageLayout direction="column">
      <Container>
        <BasicCard title="E-mail notifications" className="mt16">
          <Body>
            <Formik<IEmailNotificationSettingsValues>
              initialValues={resource}
              onSubmit={onEmailNotificationsSubmit}
              enableReinitialize={true}
              component={Form}
            />
          </Body>
        </BasicCard>
      </Container>
    </ImageLayout>
  );
};

export default EmailNotificationsSettings;
