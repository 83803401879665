import ImageLayout, { Container } from 'components/ImageLayout';
import React from 'react';
import { Outlet } from 'react-router-dom';

const AppDomainSignUpPages = () => {
  return (
    <ImageLayout direction="column">
      <Container>
        <Outlet />
      </Container>
    </ImageLayout>
  );
};

export default AppDomainSignUpPages;
