import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useTransitionScroll = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    currentPath !== location.pathname && window.scrollTo(0, 0);
    setCurrentPath(location.pathname);
  }, [location]);
};

export default useTransitionScroll;
