import Button from 'components/Button';
import Flex from 'components/Flex';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { buildFullUrl } from 'libs/utils';
import React, { FunctionComponent, useMemo } from 'react';
import useCommon from 'hooks/useCommon';

interface IRegisterNotice {
  className?: string;
  showLogin?: boolean;
}

const RegisterNotice: FunctionComponent<IRegisterNotice> = ({ className, showLogin = false }) => {
  const theme = useTheme();
  const { isCommonTypeAccount, isCommonTypeCompany, title } = useCommon();
  const registerProfileUrl = useMemo(() => {
    let path = '';
    if (isCommonTypeAccount) {
      path = `/check-email?redirect_to=${window.location.origin}/condo-sign-up`;
    } else if (isCommonTypeCompany) {
      path = `/check-email?redirect_to=${window.location.origin}/company-sign-up`;
    } else {
      path = '/search';
    }
    return buildFullUrl(process.env.REACT_APP_APP_DOMAIN!, path);
  }, [
    process.env.REACT_APP_APP_DOMAIN!,
    isCommonTypeCompany,
    isCommonTypeAccount,
    window.location.origin,
  ]);
  return (
    <Flex className={className} direction="column">
      <Text align="center" color={theme.colors.dark} className="mb16">
        {`Don’t have an account with ${title}?`}
        <br />
        Register for an account by clicking the button below.
      </Text>
      <Button onClick={registerProfileUrl} styleType="blueFill">
        Register for an account
      </Button>
      {showLogin && (
        <Button
          onClick={buildFullUrl(process.env.REACT_APP_APP_DOMAIN!, '/sign-in')}
          styleType="link"
        >
          <Text color={theme.colors.accentNormal} weight="700">
            Sign in
          </Text>
        </Button>
      )}
    </Flex>
  );
};
export default RegisterNotice;
