import { PROJECT_TITLE } from 'libs/constants';
import { useEffect } from 'react';

export default function useDocumentTitle(title?: string, isPrefix = true) {
  useEffect(() => {
    let documentTitle = `${PROJECT_TITLE} - Condo Success System`;
    if (title) {
      documentTitle = isPrefix ? `${title} - ${PROJECT_TITLE}` : title;
    }
    document.title = documentTitle;
  }, [title]);
}
