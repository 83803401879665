import { getIn } from 'formik';
import theme from 'theme/index';

const Styles = {
  clearIndicator: () => ({
    'box-sizing': 'border-box',
    color: theme.colors.darkGrey,
    display: 'flex',
    padding: '8px',
    transition: 'color 150ms',
  }),
  control: (provided: any, state?: any) => {
    return {
      ...provided,
      '&:hover': {
        borderColor: theme.colors.focusBorder,
      },
      borderColor: getIn(state, ['selectProps', 'error'])
        ? `${theme.colors.error} !important`
        : theme.colors.border,
      borderRadius: '30px',
      borderWidth: 2,
      boxShadow: 'none',
      boxSizing: 'border-box',
      color: theme.colors.dark,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '16px',
      padding: '5px 10px',
      transition: 'all 0.3s',
    };
  },
  dropdownIndicator: () => ({
    'box-sizing': 'border-box',
    color: theme.colors.darkGrey,
    display: 'flex',
    padding: '8px',
    transition: 'color 150ms',
  }),
  menu: (provided: any) => ({
    ...provided,
    border: `2x solid ${theme.colors.border}`,
    borderRadius: '4px',
    // boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.06)',
    padding: '8px 0',
    zIndex: 2,
  }),
  menuList: (provided: any) => ({
    ...provided,
    overflowY: 'scroll',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#F4F4F4',
    borderRadius: 30,
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    alignSelf: 'center',
    color: '#676767',
    fontSize: 14,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    ':hover': {
      backgroundColor: 'none',
    },
    cursor: 'pointer',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    alignItems: 'center',
    backgroundColor:
      state.isSelected || state.isFocused ? theme.colors.optionGrey : theme.colors.snow,
    color: theme.colors.darkGrey,
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'space-between',
    lineHeight: '24px',
    padding: '9px 16px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: theme.colors.inputText,
  }),
};

export default Styles;
