import { isAfter, startOfDay } from 'date-fns';
import { addTimeToDate, formatDate } from 'libs/dateTimeHelpers';
import { validateRecurring } from 'libs/utils';
import { MAX_FILE_SIZE } from 'libs/constants';
import * as Yup from 'yup';
import { IEmailInvoice } from 'hooks/useEmailInvoices';

export const stringRequired = Yup.string().required('Required');
export const stringNullableRequired = stringRequired.nullable();
export const numberRequired = Yup.number().required('Required');
export const arrayRequired = Yup.array().required('Field is required');
export const numberNotRequired = Yup.number().nullable().typeError('Not a number');

export const location = Yup.object().shape({
  address: stringRequired,
  postcode: stringRequired.nullable(),
  country: Yup.object().shape({
    short_code: stringNullableRequired,
    title: stringNullableRequired,
  }),
  region: Yup.object().shape({
    short_code: stringNullableRequired,
    title: stringNullableRequired,
  }),
  city: Yup.object().shape({
    title: stringRequired,
  }),
});

export const address = stringRequired;
export const city = stringNullableRequired; //TODO remove
export const country = stringNullableRequired;
export const region = stringNullableRequired;
export const postalCode = stringRequired;
export const timeZone = stringRequired;
export const checkboxRequired = (message: string) => Yup.boolean().oneOf([true], message);

export const acceptRights = checkboxRequired('You must accept rights');

export const fileValidation = Yup.mixed()
  .required()
  .test('file', 'File size upload limit 50 MB', function (value: any) {
    if (!value) return true;
    return value.size <= MAX_FILE_SIZE * 1024 * 1024;
  });

export const filesValidation = Yup.array().test(
  'file',
  'File size upload limit 50 MB',
  function (value?: any | any[]) {
    return value
      ? !Array.isArray(value)
        ? fileValidation.isValid(value)
        : value
            .filter((item: any) => item instanceof File)
            .reduce((accumulator: number, item: File) => accumulator + item.size, 0) <=
          MAX_FILE_SIZE * 1024 * 1024
      : true;
  }
);

export const buildingFieldArrayRequired = Yup.array().of(
  Yup.object().shape({
    building_id: stringRequired,
    unit_numbers: Yup.string().when('building_id', (id: string) =>
      id
        ? Yup.array().of(Yup.string().required('Unit number is required'))
        : Yup.array().of(Yup.string().notRequired())
    ),
  })
);

export const requiredCheckbox = checkboxRequired('Required');

export const email = stringRequired.trim().email('Email is not valid');
export const emailNotRequired = Yup.string().email('Email is not valid').notRequired();
export const confirmEmail = Yup.string()
  .email('Email is not valid')
  .oneOf([Yup.ref('email')], "Users email doesn't match confirmation")
  .required('Email confirm is required');
export const confirmPassword = Yup.string()
  .oneOf([Yup.ref('password')], "Users password doesn't match confirmation")
  .required('Password confirm is required');

export const password = stringRequired.min(
  4,
  (params): string => `Users password is too short(minimum is ${params.min} characters)`
);

export const firstName = stringRequired.min(
  2,
  (params): string => `Users first name is too short(minimum is ${params.min} characters)`
);
export const lastName = stringRequired
  .notOneOf([Yup.ref('first_name')], 'Users first name and last name must be different')
  .min(2, (params): string => `Users last name is too short(minimum is ${params.min} characters)`);
export const pets = Yup.array().of(
  Yup.object().shape(
    {
      numbers_of_pets: Yup.number()
        .nullable()
        .when('type_of_pet', {
          is: (val) => val,
          then: Yup.number()
            .required('Required')
            .transform((cv: number) => (isNaN(cv) ? undefined : cv))
            .integer('must be an integer')
            .min(0, 'must be greater than or equal to 0'),
          otherwise: Yup.number(),
        }),
      type_of_pet: Yup.string()
        .nullable()
        .when('numbers_of_pets', {
          is: (val) => val,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
    },
    [['numbers_of_pets', 'type_of_pet']]
  )
);

export const vehicles = Yup.array().of(
  Yup.object().shape({
    parking_stall: Yup.string().matches(/^[0-9a-zA-Z\s]+$/, 'Unacceptable symbols, only 0-9,a-z'),
  })
);

export const unit = stringRequired;
export const userStatus = stringNullableRequired;

export const depositAmount = Yup.number()
  .min(0, 'Must be greater than or equal to 0')
  .nullable()
  .typeError('Not a number');

export const amenityFeeAmount = Yup.number()
  .min(0, 'Must be greater than or equal to 0')
  .test('fee_amount', 'Enter fee amount', function (value?: number) {
    return !Boolean(this.resolve(Yup.ref('fee_period'))) || typeof value === 'number';
  })
  .nullable()
  .typeError('Not a number');

export const buildingFloors = Yup.array().of(
  Yup.object().shape({
    address,
    amount_of_floors: stringRequired,
    floors: Yup.array().of(
      Yup.object().shape({
        from: numberRequired.min(0, 'Must be greater than or equal to 0').typeError('Required'),
        to: Yup.mixed().when('from', (value: any) => {
          let validation = numberRequired.positive('must be a positive number');
          if (value) {
            validation = validation
              .min(Number(value || 0), `Must be greater than or equal to ${value}`)
              .typeError('Required');
          }
          return validation;
        }),
      })
    ),
    postal_code: stringRequired,
    prefix: stringRequired,
  })
);

export const atLeastOnePhone = Yup.string().test(
  'at-least-one-number',
  'You must provide at least one correct phone number',
  function () {
    return Boolean(this.resolve(Yup.ref('cell_num')) || this.resolve(Yup.ref('phone_number')));
  }
);

export const createDesignatedGroupsOrUsers = (userKey = 'users', groupKey = 'groups') =>
  Yup.string().test(
    'at-least-one-user-or-group',
    'You must provide at least one user or group',
    function () {
      return Boolean(
        this.resolve(Yup.ref(userKey)).length || this.resolve(Yup.ref(groupKey)).length
      );
    }
  );

export const designatedGroupsOrUsers = createDesignatedGroupsOrUsers();

export const assignmentMinutes = (timeRequired: boolean) =>
  timeRequired ? stringRequired : Yup.string();

export const groupsRequired = Yup.array().min(1, 'At least 1 group must be selected');

export const finishTime = (startKey = 'start_time') =>
  Yup.string().test('log', 'Must be greater than start time', function (finish) {
    const start = this.resolve(Yup.ref(startKey));
    if (!start) {
      return true;
    }
    if (!finish) {
      return this.createError({
        message: 'Required',
      });
    }
    return isAfter(addTimeToDate(new Date(), finish), addTimeToDate(new Date(), start));
  });

export const startAmenityHours = Yup.string().when('end_working', (finish: boolean) => {
  const rule = Yup.string()
    .transform((str) => str || '')
    .trim();
  return finish ? rule.required('Required') : rule.notRequired();
});

export const amenityFeePeriod = Yup.number().when('fee_amount', (amount: string) =>
  amount ? stringNullableRequired : Yup.string().notRequired().nullable()
);

export const requiredWysiwyg = stringRequired
  .transform((value) => value.replace(/<p>(<br>|[ ]*)<\/p>/g, ''))
  .trim();

export const dueDateTask = ({
  startDateKey = 'start_recurring_date',
  initialValue,
  isEdit,
}: {
  startDateKey?: string;
  initialValue: Date;
  isEdit: boolean;
}) =>
  Yup.date().test('due_date', 'Required', function (dueDate?: Date) {
    const startDate = this.resolve(Yup.ref(startDateKey));
    if (!dueDate) {
      return this.createError({
        message: 'Required',
      });
    }

    if (
      !startDate &&
      isAfter(startOfDay(new Date()), dueDate) &&
      (formatDate(initialValue) !== formatDate(dueDate) || !isEdit)
    ) {
      return this.createError({
        message: 'Must be greater than yesterday',
      });
    }
    if (isAfter(new Date(startDate), dueDate)) {
      return this.createError({
        message: 'Due date must be greater than start date',
      });
    }
    return true;
  });

export const startRecurring = (
  endDateKey = 'end_recurring_date',
  scheduleKey = 'recurring_schedule'
) =>
  Yup.date().test('start_recurring_required', 'Required', function (startDate?: Date) {
    const endDate = this.resolve(Yup.ref(endDateKey));
    const schedule = this.resolve(Yup.ref(scheduleKey));
    if (schedule && !startDate) {
      return false;
    }
    if (!endDate) {
      return true;
    }
    return Boolean(startDate);
  });

export const endRecurring = (
  startDateKey = 'start_recurring_date',
  scheduleKey = 'recurring_schedule'
) =>
  Yup.date().test('end_recurring', 'Required', function (endDate?: Date) {
    const startDate = this.resolve(Yup.ref(startDateKey));
    if (!startDate) {
      return true;
    }
    if (endDate) {
      if (isAfter(startDate, endDate)) {
        return this.createError({
          message: 'End recurring date must be greater than start date',
        });
      }
      const schedule = this.resolve(Yup.ref(scheduleKey));
      if (schedule && !validateRecurring(startDate, endDate, schedule)) {
        return this.createError({
          message: 'End recurring date must be in ranges',
        });
      }
    }
    return Boolean(endDate);
  });

export const recurringSchedule = (
  startDateKey = 'start_recurring_date',
  endDateKey = 'end_recurring_date'
) =>
  Yup.string()
    .transform((cv) => cv || '')
    .test('recurring_schedule_required', 'Required', function (schedule: string) {
      const start = this.resolve(Yup.ref(startDateKey));
      const end = this.resolve(Yup.ref(endDateKey));
      if (start || end) {
        return Boolean(schedule);
      }
      return true;
    });

export const RecurringMonthlyOption = Yup.string()
  .nullable()
  .when(
    ['show_recurring', 'recurring_schedule', 'has_recurring_monthly_rule'],
    (showRecurring: boolean, recurring_schedule: string, has_recurring_monthly_rule: string) => {
      return showRecurring &&
        recurring_schedule === 'monthly' &&
        has_recurring_monthly_rule === 'true'
        ? stringRequired
        : undefined;
    }
  );

export const requiredDependsOn = (dependent: string) =>
  Yup.string().test(dependent, 'Required', function (current) {
    if (this.resolve(Yup.ref(dependent))) {
      return !!current;
    }
    return true;
  });

export const invoiceEmailsRequired = Yup.array().test(
  'invoice_emails_attributes',
  'Required',
  function (attrs: IEmailInvoice[]) {
    return attrs.filter((e) => !e._destroy).length !== 0;
  }
);

export const groupPermissions = (message: string) =>
  Yup.object().test(
    'params',
    message,
    (permissions: { [index: string]: { [index: string]: boolean } }) => {
      return (
        Object.values(permissions).filter((permission) => Object.values(permission).includes(true))
          .length !== 0
      );
    }
  );
