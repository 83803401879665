import Button from 'components/Button';
import { Body } from 'components/Card';
import Flex from 'components/Flex';
import Modal from 'components/Modal';
import Basic from 'components/Modal/Basic';
import Text from 'components/Text';
import useOutsidePopupToggle from 'hooks/useOutsidePopupToggle';
import useTheme from 'hooks/useTheme';
import useToggle from 'hooks/useToggle';
import { ReactComponent as QuestionIcon } from 'icons/question.svg';
import { ReactComponent as VideoTutorialsIcon } from 'icons/video-tuts.svg';
import { ReactComponent as DocTutorialsIcon } from 'icons/article.svg';
import { ReactComponent as SupportTutorialsIcon } from 'icons/mail.svg';
import HeaderNavMenuTemplate, {
  NavMenuLayout,
} from 'pages/App/Header/Menu/components/HeaderNavMenuTemplate';
import { IMenuItem } from 'pages/App/Header/Menu/UserMenu/useUserMenu';
import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import HeaderLinkTemplate, { IconContainer } from '../HeaderLinkTemplate';

interface IVideoTutorials {
  className?: string;
  pathToVideo?: string;
  pathToDoc?: string;
}

const VideoTutorialsHeaderLink: FunctionComponent<IVideoTutorials> = ({
  className = '',
  pathToVideo,
  pathToDoc,
}) => {
  const theme = useTheme();
  const [modalIsOpen, toggleModal] = useToggle(false);
  const { toggle, isMenuOpen, nodeRef } = useOutsidePopupToggle<HTMLDivElement>();

  const items = useMemo((): IMenuItem[] => {
    const items = [];
    if (pathToVideo) {
      items.push({
        icon: <StyledVideoTutorialsIcon />,
        onClick: () => {
          toggle();
          toggleModal();
        },
        title: 'Video Tutorial',
        visible: true,
      });
    }
    if (pathToDoc) {
      items.push({
        icon: <StyledDocTutorialsIcon />,
        onClick: () => {
          window.open(pathToDoc, '_blank')?.focus();
        },
        title: 'Help Guides & Articles',
        visible: true,
      });
    }
    // @ts-ignore
    if (window.zE) {
      items.push({
        icon: <StyledSupportTutorialsIcon />,
        onClick: () => {
          // @ts-ignore
          window.zE.activate();
        },
        title: 'Contact CondoGenie Support',
        visible: true,
      });
    }

    return items;
  }, [isMenuOpen, pathToDoc, pathToVideo]);

  const questionIcon = useMemo(
    () => (
      <IconContainer
        alignItems="center"
        justifyContent="center"
        backgroundColor={theme.colors.darkBlue}
      >
        <QuestionIcon />
      </IconContainer>
    ),
    []
  );
  return (
    <>
      {pathToVideo && (
        <Modal isOpen={modalIsOpen} close={toggleModal} contentMaxWidth={800}>
          <Basic close={toggleModal}>
            <VideoBody>
              <VideoBox frameBorder="0" allowFullScreen={true} scrolling="auto" src={pathToVideo} />
            </VideoBody>
          </Basic>
        </Modal>
      )}
      <HeaderLinkTemplate
        className={className}
        desktopView={
          <Button
            icon={questionIcon}
            onClick={toggle}
            className="ml16"
            styleType="blue"
            padding="3px 15px 3px 3px"
          >
            <Text size="xs" color={theme.colors.darkBlue} weight="bold">
              CondoGenie Help
            </Text>
          </Button>
        }
        mobileView={
          <ButtonTemplate onClick={toggle} className="ml4">
            {questionIcon}
          </ButtonTemplate>
        }
      >
        {isMenuOpen && (
          <HeaderNavMenuTemplate nodeRef={nodeRef} items={items} layout={StyledMenu} />
        )}
      </HeaderLinkTemplate>
    </>
  );
};

export default VideoTutorialsHeaderLink;

const VideoBody = styled(Body)`
  padding: 0 10px 10px 10px;
`;

const StyledVideoTutorialsIcon = styled(VideoTutorialsIcon)`
  width: 20px;
  height: 16px;
  path {
    fill: #216dc1 !important;
  }
`;

const StyledDocTutorialsIcon = styled(DocTutorialsIcon)`
  width: 20px;
  height: 16px;
  path {
    fill: #216dc1 !important;
  }
`;

const StyledSupportTutorialsIcon = styled(SupportTutorialsIcon)`
  width: 20px;
  height: 16px;
  path {
    fill: #216dc1 !important;
  }
`;

const VideoBox = styled.iframe`
  width: 100%;
  min-height: 415px;
`;

export const ButtonTemplate = styled(Flex)<{ onClick?: () => void }>`
  cursor: pointer;
`;

const StyledMenu = styled(NavMenuLayout)`
  margin-top: 0;
  width: 270px;
  top: 60px;
  ${(props) => props.theme.media.xs`
    right: 0px;
    top: 80px;
  `};
`;
