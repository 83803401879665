import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export function useDidUpdateEffect(callback: EffectCallback, deps?: DependencyList): void {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    return callback();
  }, deps);
}
