import { getIn } from 'formik';
import useCommon from 'hooks/useCommon';
import api, { TApiMethodNames } from 'libs/api';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

export default function useApi() {
  const { type } = useCommon();

  const getApiPath = useCallback(
    (apiPath: string, method: TApiMethodNames) => {
      const path = apiPath.split('.');
      path.push(method);
      path.unshift(type);
      const apiMethod = getIn(api, path, undefined);
      if (type && apiMethod === undefined) {
        toast.error('Api method not found');
        return false;
      }
      return apiMethod;
    },
    [type]
  );

  return { getApiPath };
}
