import { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components/macro';
import { ITheme } from 'theme';

export type Sizes = 'lg' | 'md' | 'sm' | 'xs';
type Aligns = 'left' | 'center' | 'right' | 'justify' | 'inherit';

export interface IText {
  className?: string;
  color?: string;
  onClick?: () => void;
  size?: Sizes;
  theme?: ITheme;
  weight?: string;
  fontFamily?: string;
  align?: Aligns;
  dangerouslySetInnerHTML?: { __html: string };
  lineHeight?: number;
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap';
  textTransform?: 'none' | 'uppercase' | 'lowercase' | 'capitalize';
  wordBreak?: 'normal' | 'break-word' | 'inherit';
}

const Text = styled.p<IText>`
  ${(props) => sizeStyles[props.size!]};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color || props.theme.colors.lightBlack};
  font-family: ${(props) => props.fontFamily || 'Roboto'};
  font-weight: ${(props) => props.weight};
  line-height: ${(props) => props.lineHeight}px;
  white-space: ${(props) => props.whiteSpace || 'normal'};
  text-transform: ${(props) => props.textTransform || 'none'};
  word-break: ${(props) => props.wordBreak || 'inherit'};
  & > a {
    color: ${(props) => props.theme.colors.accentNormal};
  }
  p {
    padding-bottom: 16px;
    &:last-child {
      padding-bottom: 16px;
    }
  }
  ${(props) => props.theme.media.sm`
     font-size: 14px;
     line-height: 18px;
  `}
`;

Text.defaultProps = {
  align: 'inherit',
  lineHeight: 24,
  size: 'sm',
  weight: 'normal',
};

const sizeStyles: { [index in Sizes]: FlattenSimpleInterpolation } = {
  lg: css`
    font-size: 22px;
    line-height: 32px;
  `,
  md: css`
    font-size: 18px;
    line-height: 24px;
  `,
  sm: css`
    font-size: 16px;
    line-height: 24px;
  `,
  xs: css`
    font-size: 14px;
    line-height: 18px;
  `,
};

export default Text;
