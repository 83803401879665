import styled from 'styled-components';

export default styled.h1`
  color: ${(props) => props.theme.colors.darken};
  font-family: Gotham Pro;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.04em;
  padding-top: 60px;
`;
