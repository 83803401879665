import { css } from 'styled-components/macro';

const inputStyles = css<{ error: boolean }>`
  box-sizing: border-box;
  width: 100%;
  transition: all 0.3s;
  outline: none;
  padding: 14px 22px;
  font-weight: 500;
  border: 2px solid
    ${(props) => (props.error ? props.theme.colors.error : props.theme.colors.border)};
  line-height: 16px;
  font-size: 16px;
  font-family: Roboto;
  color: ${(props) => props.theme.colors.dark};
  ::placeholder {
    color: ${(props) => props.theme.colors.inputText};
  }
  &:focus {
    border-color: ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.focusBorder};
  }
`;

export default inputStyles;
