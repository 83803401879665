import Flex from 'components/Flex';
import history from 'libs/history';
import React, { FunctionComponent, useEffect } from 'react';
import MobileMenuHandler from '../components/MobileMenuHandler';
import { MobileMenuBody, MobileMenuOverlay } from '../styled';
import useToggleMenu from '../useToggleMenu';
import Footer from './Footer';
import useLeftMenu from './useLeftMenu';
import WebsiteMenuItem, { MobileWebsiteWrapper } from './WebsiteMenuItem';

interface IAuthorizedMenu {
  className?: string;
}

const LeftMenuMobile: FunctionComponent<IAuthorizedMenu> = ({ className }) => {
  const { open, toggleOpen } = useToggleMenu();
  const { renderItems } = useLeftMenu({ toggle: toggleOpen });

  useEffect(() => {
    const unlistenLocation = history.listen(() => {
      if (open) {
        toggleOpen();
      }
    });

    return () => {
      unlistenLocation();
    };
  }, [open]);

  return (
    <div className={className}>
      <MobileMenuHandler onClick={toggleOpen} open={open} boundary="md" />
      <MobileMenuOverlay close={!open} onClick={toggleOpen} boundary="md" />
      <MobileMenuBody boundary="md" close={!open}>
        <Flex justifyContent="space-between" direction="column">
          <div>
            {renderItems()}
            <MobileWebsiteWrapper>
              <WebsiteMenuItem />
            </MobileWebsiteWrapper>
          </div>
          <Footer />
        </Flex>
      </MobileMenuBody>
    </div>
  );
};

export default LeftMenuMobile;
