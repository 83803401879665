import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components/macro';

export interface IFlex {
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  direction?: 'row' | 'column' | 'row-reverse';
  children?: ReactNode;
  className?: string;
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  flex?: number;
}

const Flex: FunctionComponent<IFlex> = styled('div')<IFlex>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  flex-wrap: ${(props) => props.wrap};
  flex: ${(props) => props.flex};
`;

export default Flex;
