import { ImmutableObject } from 'seamless-immutable';

/**
 *
 * @param {array} path
 * @returns {function(*): (any | *)}
 */
export const peek =
  (...path: string[]) =>
  (obj: ImmutableObject<Record<string, never>>) =>
    obj.getIn(path);

export const peekOr =
  (path: string[], def: string) => (obj: ImmutableObject<Record<string, never>>) =>
    obj.getIn(path, def);

export function raw(data: any) {
  return data;
}
