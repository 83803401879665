import styled from 'styled-components';

export const Title = styled.h1`
  font-family: Gotham Pro Bold;
  line-height: 64px;
  font-size: 46px;
  text-align: center;
  letter-spacing: -0.04em;
  margin-bottom: 48px;
  ${(props) => props.theme.media.sm`
    font-size: 34px;
    line-height: 44px;
  `}
`;

export const Subtitle = styled.h5`
  font-family: Gotham Pro Bold;
  line-height: 24px;
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 5px;
  ${(props) => props.theme.media.sm`
    font-size: 16px;
    line-height: 18px;
  `}
`;
