import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { thunkLogOut } from 'domain/env/effects';
import { envTokensSelector } from 'domain/env/selectors';
import { AppState } from 'domain/reducers';
import store from 'domain/store';
import { getIn } from 'formik';
import { HTTP_STATUSES } from 'libs/constants';
import Qs from 'qs';

export type TResponceItemSerializer<T> = (data: { resource: T }) => T;

/** @type {AxiosInstance} */
const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
});

http.defaults.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets' });

function addAuthenticateHeaderIfTokenExist(state: AppState, config: AxiosRequestConfig) {
  const tokens = envTokensSelector(state);
  if (tokens) {
    config.headers = Object.assign(config.headers, tokens);
  }
}

function logoutOnUnauthorizedError(state: AppState, error: AxiosError) {
  if (state.env.isAuthorized && getIn(error, 'response.status') === HTTP_STATUSES.unauthorized) {
    // @ts-ignore
    store.dispatch(thunkLogOut());
  }
}
//
// function redirectToNotFound(state: AppState, error: AxiosError) {
//   if (getIn(error, 'response.status') === HTTP_STATUSES.notFound) {
//     window.location.replace(
//       `${location.protocol}//${process.env.REACT_APP_APP_DOMAIN}${process.env.REACT_APP_FRONTEND_DOMAIN}:${location.port}/search`
//     );
//   }
// }

http.interceptors.request.use((config) => {
  const state = store.getState();

  addAuthenticateHeaderIfTokenExist(state, config);

  return config;
});

http.interceptors.response.use(undefined, (error) => {
  const state = store.getState();

  logoutOnUnauthorizedError(state, error);
  // redirectToNotFound(state, error); // mb need to redirect if show by id

  return Promise.reject(error);
});

export default http;
