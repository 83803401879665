import Label from 'components/Form/Label';
import StyledErrorMessage from 'components/Form/StyledErrorMessage';
import Wrapper from 'components/Form/Wrapper';
import { ErrorMessage, FieldProps } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import TagsInput from 'react-tagsinput';
import styled from 'styled-components';

interface ITagsInputComponent {
  className?: string;
  label?: string;
  placeholder?: string;
  checkValue?: (str: string) => boolean;
  onChange?: (tags: any[], changed: any[], changedIndexes: number[]) => void;
}

const TagsInputComponent: FunctionComponent<ITagsInputComponent & FieldProps> = ({
  label,
  className,
  placeholder,
  field,
  form: { setFieldValue, touched, errors },
  checkValue = () => true,
  onChange = (tags: any[], changed: any[], changedIndexes: number[]) =>
    setFieldValue(field.name, tags),
  ...rest
}) => {
  const handleChange = useCallback(
    (values: any[], changed: any[], changedIndexes: number[]) => {
      if (!values.length || checkValue(values[values.length - 1])) {
        onChange(values, changed, changedIndexes);
      }
    },
    [field.value, checkValue, onChange]
  );

  const pasteSplit = useCallback(
    (value) =>
      value
        .replace(/,|\//g, '')
        .split(' ')
        .map((item: string) => item.trim()),
    []
  );
  const addKeys = useMemo(() => [9, 13, 32], []);

  return (
    <Wrapper className={className}>
      <label>
        <Label>{label}</Label>
      </label>
      <StyledTagsInput
        error={Boolean(touched[field.name] && errors[field.name])}
        inputProps={{ placeholder }}
        value={field.value}
        onChange={handleChange}
        addOnPaste={true}
        pasteSplit={pasteSplit}
        addOnBlur={true}
        addKeys={addKeys}
        {...rest}
      />
      <ErrorMessage name={field.name} component={StyledErrorMessage} />
    </Wrapper>
  );
};

export default TagsInputComponent;

const StyledTagsInput = styled(TagsInput)<{ error: boolean }>`
  box-sizing: border-box;
  width: 100%;
  padding: 10px 22px;
  border: 2px solid
    ${(props) => (props.error ? props.theme.colors.error : props.theme.colors.border)};
  color: ${(props) => props.theme.colors.dark};
  border-radius: 30px;
  resize: none;

  .react-tagsinput--focused {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }

  .react-tagsinput-tag {
    background-color: #f4f4f4;
    border-radius: 30px;
    color: ${(props) => props.theme.colors.dark};
    display: inline-block;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-right: 8px;
    padding: 12px;
  }

  .react-tagsinput-remove {
    margin-left: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  .react-tagsinput-tag a::before {
    content: ' ×';
  }

  .react-tagsinput-input {
    border: none;
    background: transparent;
    outline: none;
    line-height: 16px;
    font-size: 16px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    font-weight: 500;
    width: 300px;
    padding: 13px 0;
  }
`;
