import Button from 'components/Button';
import Flex from 'components/Flex';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Flex)`
  height: 100%;
  width: 320px;
  ${(props) => props.theme.media.xs`
    width: 100%;
  `}
`;

export const BoldLink = styled(Link)`
  color: ${(props) => props.theme.colors.accentNormal};
  font-weight: bold;
  line-height: 24px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  padding-top: 26px;
`;

export const LargeButton = styled(Button)`
  width: 100%;
  padding: 17px;
`;

export const Footer = styled.div`
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.media.xs`
     padding-top: 45px;
  `}
`;
