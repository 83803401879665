import ActionLink from 'components/ActionLink';
import Attachments, { TAttachment } from 'components/Attachments';
import ContentItem from 'components/Website/ContentItem';
import HorizontalLine from 'components/Website/HorizontalLine';
import PageTitle from 'components/Website/PageTitle';
import SiteContent from 'components/Website/SiteContent';
import useAuth from 'hooks/useAuth';
import useCommon from 'hooks/useCommon';
import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import React, { FunctionComponent, useMemo } from 'react';

const NewsSpecific: FunctionComponent = () => {
  const { title: commonTitle } = useCommon();
  const { isAuthorized } = useAuth();
  const { resource } = useFetchResource<Array<{ file: TAttachment; title: string }>>({
    api: api.website.documents.getAll,
    initialValues: [],
    serializer: (data) => data.resources,
  });

  const documents = useMemo(
    () =>
      resource.map(({ file, title }) => ({
        ...file,
        name: title,
      })),
    [resource]
  );

  return (
    <SiteContent>
      {isAuthorized && (
        <>
          <ContentItem>
            <PageTitle>Private Documents</PageTitle>
          </ContentItem>
          <ContentItem>
            <HorizontalLine />
          </ContentItem>
          <ContentItem>
            <p>
              <ActionLink to="/documents?archive=active">Go to the Dashboard</ActionLink> to view
              all the Documents available for residents of {commonTitle}
            </p>
          </ContentItem>
        </>
      )}
      <ContentItem>
        <PageTitle className="mt0">Public Documents</PageTitle>
      </ContentItem>
      <ContentItem>
        <HorizontalLine />
      </ContentItem>
      <ContentItem>
        <Attachments files={documents} />
      </ContentItem>
    </SiteContent>
  );
};

export default NewsSpecific;
