import OverlaySpinner from 'components/OverlaySpinner';
import useAuth from 'hooks/useAuth';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import UserSignIn from 'pages/Auth/UserSignIn';
import CompanyRedirectPage from 'pages/Company/Auth/CompanyRedirectPage';
import CommonDeleted from 'pages/App/Layouts/CommonDeleted';
import Suspended from 'pages/Auth/AuthorizedSignUp/AccountSuspended';
import React, { useCallback, useMemo, useState, useEffect, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import useTransitionScroll from 'hooks/useTransitionScroll';
import Main from './Layouts/Main';
import SignUpRedirectPage from './Layouts/SignUpRedirectPage';
import { getIn } from 'formik';
import useCommon from 'hooks/useCommon';
import usePushNotifications from 'hooks/usePushNotifications';
import routes from 'pages/Auth/UserSignIn/routes';
import RouteComponent from 'components/Route';
import JoinTo from 'pages/JoinTo';
import InviteExpired from './Layouts/InviteExpired';

const App = () => {
  useTransitionScroll();
  const { isAuthorized } = useAuth();
  const { common, type, isCommonTypeAccount } = useCommon();

  const { askPushNotifications } = usePushNotifications(
    `${process.env.REACT_APP_PUSH_NOTIFICATIONS_KEY}`
  );

  useEffect(() => {
    let timeoutID = -1;

    function clearPushNotification() {
      clearTimeout(timeoutID);
      document.removeEventListener('click', registerAndClearPushNotification);
    }

    function registerAndClearPushNotification() {
      clearPushNotification();
      timeoutID = setTimeout(askPushNotifications, 1000);
    }

    isAuthorized &&
      isCommonTypeAccount &&
      common.profile?.id &&
      common.profile?.approved &&
      document.addEventListener('click', registerAndClearPushNotification);
    return clearPushNotification;
  }, [isAuthorized, isCommonTypeAccount, common.profile]);

  const makeRedirect = useCallback((redirectTo) => {
    if (/^https?:\/\//.test(redirectTo)) {
      window.location.href = redirectTo;
      return '/';
    } else {
      return redirectTo;
    }
  }, []);

  const [isAuthorizedDebounced, setIsAuthorizedDebounced] = useState(false);
  useDidUpdateEffect(() => {
    setIsAuthorizedDebounced(true);
    setTimeout(() => setIsAuthorizedDebounced(false));
  }, [isAuthorized]);

  const redirectTo = useMemo(() => {
    const URLParams = new URLSearchParams(window.location.search);
    return URLParams.get('redirect_to');
  }, [window.location.search]);

  const isRedirectToSuspended = useMemo(() => {
    const isSuspended = getIn(common, [type, 'is_suspended']);
    if (isSuspended && !isCommonTypeAccount) {
      return true;
    }

    if (
      isSuspended &&
      isCommonTypeAccount &&
      window.location.pathname.indexOf('/choose-payment-type') < 0 &&
      window.location.pathname.indexOf('/update-payment') < 0 &&
      window.location.pathname.indexOf('/payment/') < 0
    ) {
      return true;
    }

    return false;
  }, [isCommonTypeAccount, type, common]);

  if (isAuthorizedDebounced) {
    return <OverlaySpinner visible={true} />;
  }

  return (
    <Routes>
      <Route path="/common-deleted" element={<CommonDeleted />} />
      <Route path="/suspended" element={<Suspended />} />
      <Route path="/invite-cancelled" element={<InviteExpired />} />
      {getIn(common, [type, 'discarded_at']) && (
        <Route element={<Navigate to="/common-deleted" />} />
      )}
      {isRedirectToSuspended && <Route path="/*" element={<Navigate to="/suspended" />} />}
      <Route path="/signup" element={<SignUpRedirectPage />} />
      <Route path="/company-join/*" element={<CompanyRedirectPage />} />
      <Route path="/*" element={<Main />} />
      <Route element={<UserSignIn />}>
        <Route
          path="/reset/*"
          element={
            <RouteComponent
              title="Reset Email"
              component={lazy(() => import('pages/Auth/UserSignIn/Reset'))}
            />
          }
        />
        {isAuthorized && <Route path="/join-to" element={<JoinTo />} />}
        {!isAuthorized &&
          routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <RouteComponent
                  title={route.title}
                  isTitlePrefix={route.isTitlePrefix}
                  component={route.component}
                />
              }
            />
          ))}
      </Route>
      {isAuthorized && (
        <>
          <Route path="/forgot" element={<Navigate to="/profile?tab=3" />} />
          <Route
            path="/sign-in"
            element={<Navigate to={redirectTo ? makeRedirect(redirectTo) : '/dashboard'} />}
          />
        </>
      )}
    </Routes>
  );
};

export default App;
