import Flex from 'components/Flex';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TDisplaySize } from 'theme';

interface IMobileMenuHandler {
  open: boolean;
  onClick: () => void;
  boundary: TDisplaySize;
}

const MobileMenuHandler: FunctionComponent<IMobileMenuHandler> = ({ onClick, open, boundary }) => (
  <Wrapper onClick={onClick} open={open} boundary={boundary}>
    <Toggle>
      <div />
      <div />
      <div />
    </Toggle>
    Menu
  </Wrapper>
);

export default MobileMenuHandler;

const Toggle = styled.div`
  div {
    background-color: ${(props) => props.theme.colors.inputText};
    height: 2px;
    width: 24px;
    margin-right: 12px;
    &:first-child {
      width: 20px;
      margin-bottom: 5px;
    }
    &:last-child {
      margin-top: 5px;
      width: 13px;
    }
  }
`;

const Wrapper = styled(Flex)<IMobileMenuHandler>`
  display: none;
  ${(props) => props.theme.media[props.boundary]`
    display: flex;
  `}
  color: ${(props) => props.theme.colors.dark};
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
  &: hover
    ${({ theme }) => `{
  color: ${theme.colors.accentHover};
    ${Toggle} {
      div {
        background-color: ${theme.colors.accentHover};
      }
    }}`}
    ${({ open, theme }) =>
      open &&
      `
    color: ${theme.colors.accentNormal};
    ${Toggle} {
      div {
        background-color: ${theme.colors.accentNormal};
      }
    }
  `};
`;
