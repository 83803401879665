import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'libs/constants';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

interface IContact {
  className?: string;
}

const Contact: FunctionComponent<IContact> = ({ className }) => (
  <Root className={className}>
    Do you have questions?
    <br />
    Please, call <a href={`tel:+${SUPPORT_PHONE}`}>{SUPPORT_PHONE}</a> or e-mail us{' '}
    <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
  </Root>
);

export default Contact;

const Root = styled.p`
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  text-decoration: none;
  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.accentNormal};
  }
  ${(props) => props.theme.media.sm`
    font-size: 16px;
    line-height: 24px;
  `}
`;
