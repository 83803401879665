import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { ReactComponent as BackIcon } from 'icons/back.svg';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import HeaderLinkTemplate, { IconContainer } from '../HeaderLinkTemplate';

interface IBackToWebsiteHeaderLink {
  className?: string;
}

const BackToWebsiteHeaderLink: FunctionComponent<IBackToWebsiteHeaderLink> = ({
  className = '',
}) => {
  const theme = useTheme();

  return (
    <HeaderLinkTemplate
      className={className}
      desktopView={
        <Text color={theme.colors.dark} size="md" lineHeight={28}>
          <Link to="/">Back to website</Link>
        </Text>
      }
      mobileView={
        <Link to="/">
          <IconContainer>
            <BackIcon />
          </IconContainer>
        </Link>
      }
    />
  );
};

export default BackToWebsiteHeaderLink;
