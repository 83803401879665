import { AppState } from 'domain/reducers';
import { peek } from 'libs/selectors';
import { createSelector } from 'reselect';

const baseSelector = (state: AppState) => state.videoTutorials;

export const tutorialsShowSelector = createSelector(baseSelector, peek('show'));

export const tutorialsVideoUrlSelector = createSelector(baseSelector, peek('videoUrl'));

export const tutorialsDocUrlSelector = createSelector(baseSelector, peek('docUrl'));
