import Checkbox from 'components/Form/Checkbox';
import Field from 'components/Form/Field';
import Text from 'components/Text';
import React from 'react';

export const Confirmation = () => (
  <>
    <Text size="md">
      <a href="https://condogenie.com/pad-agreement/" target="_blank" rel="noopener noreferrer">
        Click here to view PAD/EFT Agreement
      </a>
    </Text>
    <Field
      name="is_agreement_payment"
      component={Checkbox}
      label="I agree to the PAD/EFT Agreement"
      className="mt16"
    />
  </>
);
