import { commonActions } from 'domain/env/actions';
import { thunkCommon } from 'domain/env/effects';
import {
  envAccountNameSelector,
  envCommonIsLoadedSelector,
  envCommonSelector,
  envCommonTypeSelector,
  envCompanyNameSelector,
  envIsErrorSelector,
  envIsLoadingSelector,
} from 'domain/env/selectors';
import { ICommon } from 'domain/env/types';
import { COMMON_TYPE_ACCOUNT, COMMON_TYPE_COMPANY, PROJECT_TITLE } from 'libs/constants';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { createStructuredSelector } from 'reselect';
import useHandleHttpError from './useHandleHttpError';

const selector = createStructuredSelector({
  accountTitle: envAccountNameSelector,
  companyTitle: envCompanyNameSelector,
  isError: envIsErrorSelector,
  isLoaded: envCommonIsLoadedSelector,
  isLoading: envIsLoadingSelector,
  resource: envCommonSelector,
  type: envCommonTypeSelector,
});

export type ISetCommon = (common: ICommon) => void;

interface IUseCommon {
  common: ICommon;
  type: string;
  title: string;
  setCommon: ISetCommon;
  fetchCommon: () => void;
  isCommonTypeAccount: boolean;
  isCommonTypeCompany: boolean;
  isCommonTypeUndefined: boolean;
  isLoaded: boolean;
  isLoading: boolean;
  isError: boolean;
}

export default function useCommon(): IUseCommon {
  const dispatch = useDispatch();
  const handleHttpError = useHandleHttpError();
  const navigate = useNavigate();
  const { accountTitle, companyTitle, resource, isLoaded, isLoading, isError, type } =
    useMappedState(selector);

  const fetchCommon = () => {
    dispatch(thunkCommon(navigate, handleHttpError));
  };

  const setCommon: ISetCommon = (common) => {
    dispatch(commonActions.success({ common }));
  };

  const isCommonTypeAccount = useMemo(() => type === COMMON_TYPE_ACCOUNT, [type]);
  const isCommonTypeCompany = useMemo(() => type === COMMON_TYPE_COMPANY, [type]);
  const isCommonTypeUndefined = useMemo(() => type === undefined, [type]);

  const title = useMemo(() => {
    const result = isCommonTypeCompany ? companyTitle : accountTitle;
    return result || PROJECT_TITLE;
  }, [isCommonTypeCompany, accountTitle, companyTitle]);

  return {
    common: resource,
    fetchCommon,
    isCommonTypeAccount,
    isCommonTypeCompany,
    isCommonTypeUndefined,
    isError,
    isLoaded,
    isLoading,
    setCommon,
    title,
    type,
  };
}
