import { IOption } from 'components/Form/Select/SingleSelect';
import { FocusEvent, forwardRef, FunctionComponent, useCallback, useMemo } from 'react';
import React from 'react';
import Select from 'react-select';
import { SelectComponentsConfig } from 'react-select/lib/components';
import { Props } from 'react-select/lib/Select';
import { ActionMeta, ValueType } from 'react-select/lib/types';
import components from '../Select/components';
import styles from '../Select/styles';

interface IClearSelect {
  id?: string;
  name?: string;
  options?: IOption[];
  error?: boolean;
  onBlur?: (e?: FocusEvent) => void;
  onChange: (value: ValueType<IOption>, meta: ActionMeta) => void;
  onClear?: () => void;
  value: ValueType<IOption>;
  placeholder?: string;
  isClearable?: boolean;
  component?: FunctionComponent<Props<IOption>>;
  components?: SelectComponentsConfig<IOption>;
  className?: string;
}

const ClearSelect = forwardRef<Select<IOption>, IClearSelect>((props, ref) => {
  const Component = useMemo(() => props.component || Select, [props.component]);

  const onChangeWithClearable = useCallback(
    (value: ValueType<IOption>, meta: ActionMeta) => {
      const { onClear, onChange, isClearable } = props;
      onClear && isClearable && meta.action === 'clear' ? onClear() : onChange(value, meta);
    },
    [props.onClear, props.onChange, props.isClearable]
  );

  return (
    <Component
      ref={ref}
      className="basic-single"
      classNamePrefix="select"
      menuPlacement="auto"
      styles={styles}
      components={{
        IndicatorSeparator: components.IndicatorSeparator,
      }}
      {...props}
      onChange={onChangeWithClearable}
    />
  );
});

export default ClearSelect;
