import Card from 'components/Card';
import OverlaySpinner from 'components/OverlaySpinner';
import SearchAssociationHeaderLink from 'pages/App/Header/ButtonLayouts/SearchAssociation';
import React from 'react';
import styled from 'styled-components';
import Table from './Table';
import useDashboard from './useDashboard';

const AppDomainDashboard = () => {
  const { accounts, companies, isLoading } = useDashboard();

  return (
    <>
      <SearchAssociationHeaderLink />
      <AppDomainCard>
        {isLoading ? (
          <OverlaySpinner visible={isLoading} />
        ) : (
          <>
            <Table title="Choose your account" entities={accounts} />
            <Table title="Choose your Property Management Company" entities={companies} />
          </>
        )}
      </AppDomainCard>
    </>
  );
};

export default AppDomainDashboard;

const AppDomainCard = styled(Card)`
  margin: 64px 20% 0;
  padding: 16px 10% 16px;
  position: relative;
  ${(props) => props.theme.fillSvg(props.theme.colors.inputText)};
  @media (max-width: 1500px) {
    margin: 64px 15% 0;
  }
  ${(props) => props.theme.media.lg`
    margin: 64px 10% 0;
  `};
  ${(props) => props.theme.media.md`
    margin: 64px 7% 0;
  `};
  ${(props) => props.theme.media.sm`
    margin: 48px 4% 0;
  `};
  ${(props) => props.theme.media.xs`
    margin: 32px 10px 0;
    padding: 8px 5% 8px;
  `};
`;
