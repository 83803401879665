import { setProfileAction } from 'domain/env/actions';
import { thunkCommon } from 'domain/env/effects';
import {
  envCommonIsLoadedSelector,
  envIsErrorSelector,
  envIsLoadingSelector,
  envProfileIdSelector,
  envProfileSelector,
} from 'domain/env/selectors';
import { IProfile } from 'domain/env/types';
import { TAllPermissions, TPermissionType } from 'hooks/usePermission';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { createStructuredSelector } from 'reselect';
import useHandleHttpError from './useHandleHttpError';

const selector = createStructuredSelector({
  isError: envIsErrorSelector,
  isLoaded: envCommonIsLoadedSelector,
  isLoading: envIsLoadingSelector,
  profileId: envProfileIdSelector,
  resource: envProfileSelector,
});

export type ISetProfile = (profile: IProfile) => void;

interface IUseProfile {
  profile: IProfile;
  profileId: number;
  setProfile: ISetProfile;
  hasAccess: (
    permission: TAllPermissions,
    type?: TPermissionType,
    defaultResponse?: boolean
  ) => boolean;
  hasEveryAccess: (
    permission: TAllPermissions,
    type?: TPermissionType[],
    defaultResponse?: boolean
  ) => boolean;
  isAdmin: (role: boolean) => boolean;
  isLoading: boolean;
  isError: boolean;
  isNoProfile: boolean;
}

export default function useProfile(): IUseProfile {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resource, profileId, isLoaded, isLoading, isError } = useMappedState(selector);
  const handleHttpError = useHandleHttpError();

  const hasAccess = useCallback(
    (permission: string, type?: TPermissionType, defaultResponse = true) => {
      if (isLoaded) {
        defaultResponse = false;
      }

      if (resource.permissions && Array.isArray(resource.permissions[permission])) {
        return type
          ? resource.permissions[permission].includes(type)
          : resource.permissions[permission].length;
      }
      return defaultResponse;
    },
    [resource.permissions, isLoaded]
  );

  const hasEveryAccess = useCallback(
    (permission: string, types?: TPermissionType[], defaultResponse = true) =>
      types ? types.every((type) => hasAccess(permission, type, defaultResponse)) : defaultResponse,
    [hasAccess]
  );

  const isAdmin = useCallback((role) => resource.is_admin === role, [resource]);

  const isNoProfile = useMemo(() => !resource.id && !isLoading, [resource.id, isLoading]);

  useEffect(() => {
    if (isNoProfile && !isLoaded) {
      dispatch(thunkCommon(navigate, handleHttpError));
    }
  }, [navigate]);

  const setProfile: ISetProfile = (profile) => {
    dispatch(setProfileAction({ profile }));
  };

  return {
    hasAccess,
    hasEveryAccess,
    isAdmin,
    isError,
    isLoading,
    isNoProfile,
    profile: resource,
    profileId,
    setProfile,
  };
}
