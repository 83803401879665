import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as Spinner } from './spinner.svg';

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export default styled(Spinner)`
  animation: ${animation} 0.8s infinite linear;
`;
