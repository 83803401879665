import Actions from 'components/Actions';
import Button from 'components/Button';
import Card from 'components/Card';
import Flex from 'components/Flex';
import Modal from 'components/Modal';
import { StyledClose } from 'components/Modal/Basic';
import { IModal } from 'components/Modal/index';
import Small from 'components/PageTitle/Small';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IConfirmModal extends IModal {
  confirm: () => void;
  cancel?: () => void;
  question: string;
  titleSubmit?: string;
  titleCancel?: string;
}

const ConfirmModal: FunctionComponent<IConfirmModal> = ({
  isOpen,
  close,
  confirm,
  cancel,
  question,
  titleSubmit = 'Yes',
  titleCancel = 'No',
}) => (
  <Modal isOpen={isOpen} close={close}>
    <Card>
      <Header>
        <StyledClose onClick={close} />
      </Header>
      <ConfirmModalBody direction="column" alignItems="center">
        <Small className="mt16 mb32">{question}</Small>
        <Actions className="mt24" justifyContent="center">
          <Button type="submit" onClick={confirm} className="mr8">
            {titleSubmit}
          </Button>
          <Button styleType="secondary" onClick={cancel || close} className="ml8">
            {titleCancel}
          </Button>
        </Actions>
      </ConfirmModalBody>
    </Card>
  </Modal>
);

export default ConfirmModal;

export const ConfirmModalBody = styled(Flex)`
  padding: 0 63px 32px;
`;

const Header = styled(Flex)`
  padding: 25px 25px 15px;
`;
