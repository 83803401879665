import { useEffect } from 'react';

const useUrlSearchParams = (param: string, successCallback: (needle: string) => any) => {
  useEffect(() => {
    const URLParams = new URLSearchParams(window.location.search);
    const needle = URLParams.get(param);
    if (needle) {
      successCallback(needle);
    }
  }, []);
};

export default useUrlSearchParams;
