import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios, { AxiosResponse, Cancel } from 'axios';
import { HTTP_STATUSES } from 'libs/constants';
import { getIn } from 'formik';
import { capitalize } from 'libs/utils';

const getMessage = (status: number, data: string[], field?: string): string => {
  const key = field ? ['errors', field] : ['errors'];
  if (Array.isArray(getIn(data, key))) {
    key.push('0');
  }

  let message = '';
  switch (status) {
    case 401:
    case 403:
      message = "You're not authorize to this action";
      break;
    case 404:
      message = 'Not found';
      break;
    case 422:
      message = 'Validation error';
      break;
    default:
      message = 'Network error, please try again';
      break;
  }
  return capitalize(getIn(data, key)).trim() || message;
};

export default function useHandleHttpError() {
  const navigate = useNavigate();

  return function (
    error: { response: AxiosResponse } | Cancel | unknown,
    field?: string,
    useNotFound?: boolean
  ): void {
    // Can't import Cancel as
    // import { Cancel } from 'axios';
    // Because in this case Cancel is interface but need class.
    if (!(error instanceof axios.Cancel)) {
      const status = getIn(error, 'response.status');
      const data = getIn(error, 'response.data');

      if (useNotFound && status === HTTP_STATUSES.notFound) {
        navigate('/404', { replace: true });
        return;
      }

      toast.error(getMessage(status, data, field), {
        toastId: status || 500,
      });
    }
  };
}
