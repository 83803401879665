import Button from 'components/Button';
import useCommon from 'hooks/useCommon';
import useProfile from 'hooks/useProfile';
import { PROJECT_TITLE } from 'libs/constants';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import useJoinTo from 'pages/JoinTo/useJoinTo';
import HeaderLinkTemplate from '../HeaderLinkTemplate';
import useNavigator from 'hooks/useNavigator';

interface IWebsiteHeadButton {
  className?: string;
}

interface IComponentData {
  desktopTitle: string;
  mobileTitle: string;
  onClick: () => void;
}

const WebsiteHeadButton: FunctionComponent<IWebsiteHeadButton> = ({ className = '' }) => {
  const { isNoProfile } = useProfile();
  const { isCommonTypeAccount, isCommonTypeCompany, isCommonTypeUndefined } = useCommon();
  const { onJoinHandler } = useJoinTo();
  const { navigateCallback } = useNavigator();

  const [data, setData] = useState<IComponentData>({
    desktopTitle: PROJECT_TITLE,
    mobileTitle: 'Condo',
    onClick: navigateCallback('/dashboard'),
  });

  const hideButton = useMemo(() => isCommonTypeUndefined && isNoProfile, [isNoProfile]);

  useEffect(() => {
    if (isCommonTypeAccount && isNoProfile) {
      setData({
        desktopTitle: 'Join account',
        mobileTitle: 'Join',
        onClick: onJoinHandler,
      });
    } else if (isCommonTypeCompany && isNoProfile) {
      setData({
        desktopTitle: 'Join company',
        mobileTitle: 'Join',
        onClick: onJoinHandler,
      });
    }
  }, [isCommonTypeAccount, isCommonTypeCompany, isNoProfile, onJoinHandler]);

  return (
    <HeaderLinkTemplate
      className={className}
      desktopView={!hideButton && <Button onClick={data.onClick}>{data.desktopTitle}</Button>}
      mobileView={
        !hideButton && (
          <Button onClick={data.onClick} size="xs">
            {data.mobileTitle}
          </Button>
        )
      }
    />
  );
};

export default WebsiteHeadButton;
