import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface IProps {
  siteBackground: string;
}

const Header: FunctionComponent<IProps> = ({ siteBackground, children }) => (
  <Wrapper siteBackground={siteBackground}>
    <Shade>{children}</Shade>
  </Wrapper>
);

export default Header;

const Wrapper = styled.header<{ siteBackground: string }>`
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  background: url(${(props) => props.siteBackground}) center no-repeat;
  background-size: cover;
`;

const Shade = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.3);
`;
