import { ErrorMessage, FieldProps, getIn } from 'formik';
import { ReactComponent as Done } from 'icons/done.svg';
import React, { FunctionComponent } from 'react';
import fadeIn from 'react-animations/lib/fade-in';
import styled, { keyframes } from 'styled-components/macro';
import StyledErrorMessage from '../StyledErrorMessage';

interface IInput {
  className?: string;
  label?: string;
  placeholder?: string;
  onClick?: () => void;
  value?: string;
  checked?: boolean;
}

const Checkbox: FunctionComponent<IInput & FieldProps> = ({
  label,
  className,
  placeholder,
  field,
  form,
  checked,
  value,
  ...rest
}) => {
  const fieldValue = getIn(form, `values.${field?.name}`, []);
  const isChecked = Array.isArray(fieldValue)
    ? fieldValue.map((el) => el.toString()).includes(value?.toString())
    : fieldValue;
  return (
    <Root className={className}>
      <Wrapper>
        <StyledInput {...field} {...rest} type="checkbox" checked={checked || isChecked} />
        <Checkmark>
          <Icon />
        </Checkmark>
        <Label>{label}</Label>
      </Wrapper>
      <ErrorMessage name={field?.name || ''} component={StyledErrorMessageLeft} />
    </Root>
  );
};

export default Checkbox;

const fadeInAnimation = keyframes`${fadeIn}`;

const Root = styled.div`
  position: relative;
  margin-bottom: 18px;
`;

export const Icon = styled(Done)`
  position: absolute;
  animation: 0.3s ${fadeInAnimation};
`;

export const Checkmark = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1px;
  left: 0;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 4px;
  background: ${(props) => props.theme.colors.snow};
  transition: all 0.3s;
  ${Icon} {
    display: none;
  }
`;

export const Wrapper = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  &:hover {
    ${Checkmark} {
      transition: border-color 0.4s ease-in-out;
      border-color: ${(props) => props.theme.colors.accentNormal};
    }
  }
`;

export const Label = styled.span`
  display: block;
  padding-left: 36px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.noticeGrey};
  b {
    color: ${(props) => props.theme.colors.accentNormal};
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  visibility: hidden;
  &:checked ~ ${Checkmark} {
    border-color: ${(props) => props.theme.colors.accentNormal};
    background-color: ${(props) => props.theme.colors.accentNormal};
    ${Icon} {
      display: block;
    }
  }
  &:disabled ~ ${Checkmark} {
    cursor: auto;
    border-color: ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.colors.optionGrey};
    ${(props) => props.theme.fillSvg(props.theme.colors.border)};
  }
`;

const StyledErrorMessageLeft = styled(StyledErrorMessage)`
  left: 0;
`;
