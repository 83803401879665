import useAuth from 'hooks/useAuth';
import { buildFullUrl } from 'libs/utils';
import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SignUpRedirectPage: FunctionComponent = () => {
  const { isAuthorized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const URLParams = new URLSearchParams(window.location.search);
    const redirect = URLParams.get('redirect_to');
    if (!redirect) {
      buildFullUrl(process.env.REACT_APP_APP_DOMAIN || '', '/search')();
      return;
    }
    if (isAuthorized) {
      window.location.href = redirect;
      return;
    }
    navigate(`/check-email?redirect_to=${redirect}`);
  }, []);
  return null;
};

export default SignUpRedirectPage;
