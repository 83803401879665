import Flex from 'components/Flex';
import { ReactComponent as CheckedIcon } from 'icons/checked.svg';
import React, { Fragment, FunctionComponent } from 'react';
import rotateIn from 'react-animations/lib/rotate-in';
import styled, { css, keyframes } from 'styled-components/macro';

interface IStepper {
  className?: string;
  stepCount: number;
  currentStep: number;
}

const Stepper: FunctionComponent<IStepper> = ({ className, stepCount, currentStep }) => {
  const stepIndex = currentStep - 1;
  const isLastStepActive = currentStep === stepCount;
  return (
    <Flex className={className}>
      <Divider short={true} active={true} stepCount={stepCount} />
      {Array(stepCount)
        .fill(0)
        .map((item, index) => {
          const isFinished = stepIndex > index;
          const isFilled = stepIndex === index || isFinished;
          const isNotLastStep = index !== stepCount - 1;
          const isStepActive = stepIndex >= index;
          return (
            <Fragment key={index}>
              <Item alignItems="center" justifyContent="center" isFilled={isFilled}>
                {isFinished ? <CheckedIcon /> : index + 1}
              </Item>
              {isNotLastStep && <Divider active={isStepActive} />}
            </Fragment>
          );
        })}
      <Divider short={true} active={isLastStepActive} stepCount={stepCount} />
    </Flex>
  );
};

export default Stepper;

const rotateInAnimation = keyframes`${rotateIn}`;

const Item = styled(Flex)<{ isFilled: boolean }>`
  ${({ theme, isFilled }) => css`
    color: ${theme.colors.noticeGrey};
    border: 2px solid ${theme.colors.border};
    border-radius: 50%;
    box-sizing: border-box;
    background-color: ${theme.colors.snow};
    width: 40px;
    height: 40px;
    font-weight: bold;
    line-height: 24px;
    font-size: 16px;
    position: relative;
    ${isFilled &&
    css`
      background-color: ${theme.colors.accentNormal};
      color: ${theme.colors.snow};
      border-color: ${theme.colors.snow};
      ${theme.shadow.common};
    `};
    svg {
      animation: 0.4s ${rotateInAnimation};
    }
    &:first-child {
      &:before {
        content: '';
        width: 32px;
        height: 2px;
        background-color: ${theme.colors.accentNormal};
        position: absolute;
        right: 100%;
        margin-right: 2px;
      }
    }
    &:last-child {
      margin-right: 32px;
      &:after {
        width: 32px;
      }
    }
  `}
`;

const Divider = styled.div<{ short?: boolean; active: boolean; stepCount?: number }>`
  height: 2px;
  background: ${({ active, theme }) =>
    active ? theme.colors.accentNormal : theme.colors.lightGrey};
  background: ${(props) =>
    `linear-gradient(to right, ${props.theme.colors.accentNormal} 50%, ${props.theme.colors.lightGrey} 50%)`};
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  align-self: center;
  ${(props) => (props.active ? `background-position: left bottom;` : ``)};
  ${({ short, stepCount = 3 }) =>
    short ? `width: ${100 / (4 + Math.pow(stepCount - 1, 3))}%;` : `flex: 1;`};
`;
