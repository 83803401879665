import ImageLayout, { Card, Container, SubTitle, Title } from 'components/ImageLayout';
import WizardForm from 'components/WizardForm';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useDocumentMeta from 'hooks/useDocumentMeta';
import useOnSubmit from 'hooks/useOnSubmit';
import api from 'libs/api';
import { PROJECT_TITLE } from 'libs/constants';
import { convertToFormData } from 'libs/utils';
import React, { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import { validationErrorSerializer } from 'components/Form/Location/FormikField';

const DOCUMENT_TITLE = 'Register an Account';

export interface IValues {
  accept_rights: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  avatar?: object;
  confirm_email: string;
  confirm_password: string;
  confirm_success_url: string;
  cell_num: string;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  phone_number: string;
  postal_code: string;
  location: {
    postcode: string;
    address: string;
    apartment: string;
    city: {
      title: string;
      id?: string;
    };
    region: {
      title: string;
      short_code: string;
    };
    country: {
      title: string;
      short_code: string;
    };
  };
}

const initialValues = {
  accept_rights: false,
  cell_num: '',
  confirm_email: '',
  confirm_password: '',
  confirm_success_url: '',
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  phone_number: '',
  postal_code: '',
  location: {
    postcode: '',
    address: '',
    apartment: '',
    city: { title: '' },
    region: { title: '', short_code: '' },
    country: { title: '', short_code: '' },
  },
};

const UserSignUp: FunctionComponent = () => {
  useDocumentTitle(DOCUMENT_TITLE);
  useDocumentMeta({ name: 'robots', content: 'noindex' });
  const navigate = useNavigate();

  const onSubmit = useOnSubmit({
    api: api.auth.signUp,
    onSuccess: ({ data: { resource } }) => {
      navigate(`/user-sign-up-finish?email=${resource.email}`);
    },
    serializer: (values: IValues) =>
      convertToFormData(
        {
          ...values,
          confirm_success_url: `${window.location.origin}/sign-in${window.location.search}`,
        },
        'resource',
        ['avatar']
      ),
    successMessage: 'Thank you for registration! Confirm please your email to login.',
    validationErrorSerializer: validationErrorSerializer('location'),
  });

  return (
    <>
      <ImageLayout direction="column" justifyContent="space-between">
        <Container>
          <Title>
            Register for your personal account at
            <br /> {PROJECT_TITLE}.
          </Title>
          <SubTitle>
            Not a resident, owner, or member at {PROJECT_TITLE}?{' '}
            <Link to="/search">Click here to find your association</Link>
          </SubTitle>
          <Card>
            <WizardForm<IValues>
              steps={[Step1, Step2]}
              onSubmit={onSubmit}
              initialValues={initialValues}
            />
          </Card>
        </Container>
      </ImageLayout>
    </>
  );
};

export default UserSignUp;
