import { IAdministrationPageCard } from 'components/AdministrationCards/PageCard';
import { ReactComponent as AccountSettings } from 'icons/administration/account_settings.svg';
import { ReactComponent as Groups } from 'icons/administration/groups.svg';
import { ReactComponent as Invoices } from 'icons/administration/invoices.svg';
import { ReactComponent as Maintenance } from 'icons/administration/maintenance.svg';
import { ReactComponent as Plan } from 'icons/administration/plan_and_billing.svg';
import { ReactComponent as UserResidentIcon } from 'icons/administration/user_and_residents.svg';

export const administrationPages: IAdministrationPageCard[] = [
  {
    description:
      'Manage your user and resident information. Approve users, specify access, view user details.',
    icon: UserResidentIcon,
    iconFillingRule: [1, 1, 1, 1, 1],
    link: '/administration/users',
    permission: 'user_and_residents',
    permissionTypes: ['read', 'edit'],
    title: 'User and Residents',
  },
  {
    description: 'Create and edit website pages, specify header image.',
    icon: Maintenance,
    iconFillingRule: [0, 0, 0, 0, 1],
    link: '/administration/website/pages',
    permission: 'website_maintenance',
    permissionTypes: ['read', 'edit'],
    title: 'Website maintenance',
  },
  {
    description: 'Create the groups and have easy management and sharing.',
    icon: Groups,
    iconFillingRule: [1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1],
    link: '/administration/groups',
    permission: 'user_groups',
    permissionTypes: ['read', 'edit'],
    title: 'Groups',
  },
];

export const companyPages: IAdministrationPageCard[] = [
  {
    description: 'Maintain your account settings such as address, contact information, time zones.',
    icon: AccountSettings,
    iconFillingRule: [0, 1],
    link: '/administration/settings',
    permission: 'company_settings',
    permissionTypes: ['read', 'edit'],
    title: 'Company settings',
  },
  {
    description: 'View and change your current plan.',
    icon: Plan,
    iconFillingRule: [1],
    link: '/administration/:guid/update-payment',
    permission: 'payment_information',
    permissionTypes: ['create'],
    title: 'Payment information',
  },
  {
    description: 'View your invoices.',
    icon: Invoices,
    iconFillingRule: [0, 0, 0, 0, 0, 0, 0, 1],
    link: '/administration/invoices',
    permission: 'invoices',
    permissionTypes: ['read'],
    title: 'Invoices',
  },
];
