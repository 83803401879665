import styled from 'styled-components';

export const Subtitle = styled.h4`
  margin-top: 50px;
  font-family: Gotham Pro Bold;
  line-height: 32px;
  font-size: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.dark};
  margin-bottom: 17px;
  ${(props) => props.theme.media.xs`
    font-size: 16px;
    line-height: 21px;
    margin-top: 22px;
  `}
`;

export default Subtitle;
