import { FILTER_CHECKED_STRING_VALUE } from 'components/ListFilters/Checkbox';
import usePrevious from 'hooks/usePrevious';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionMeta, ValueType } from 'react-select/lib/types';

export interface IFilterOption {
  label: string;
  value: string;
}

export interface IUseSearchParams {
  params: MutableRefObject<URLSearchParams>;
  changeURLParam: (key: string) => (value?: string) => void;
  getOrderBy: (key: string) => string;
  filterChange: (key: string) => (selected: ValueType<IFilterOption>, action: ActionMeta) => void;
  filterToggle: (key: string, checkedValue?: string) => (value: boolean) => void;
  getParam: (key: string) => string;
}

export default function useSearchParams(search: string): IUseSearchParams {
  const navigate = useNavigate();
  const params = useRef(new URLSearchParams(search));
  const getOrderBy = (key: string) => {
    const orderBy = params.current.get('order_by') || '';
    const [orderKey, orderValue] = orderBy.split(' ');
    return orderKey === key ? orderValue : '';
  };

  const isControlChanged = useRef(false);

  const changeURLParam = useCallback(
    (key: string) =>
      (value = '') => {
        if (value) {
          params.current.set(key, value);
        } else {
          isControlChanged.current = true;
          params.current.delete(key);
        }
        navigate({
          pathname: window.location.pathname,
          search: params.current.toString(),
        });
      },
    []
  );
  const filterChange = useCallback(
    (key) => (selected: ValueType<IFilterOption>) => {
      if (selected) {
        params.current.set(key, (selected as IFilterOption).value);
      } else {
        isControlChanged.current = true;
        params.current.delete(key);
      }
      navigate({
        pathname: window.location.pathname,
        search: params.current.toString(),
      });
    },
    []
  );
  const filterToggle = useCallback(
    (key, checkedValue = FILTER_CHECKED_STRING_VALUE) =>
      (value: boolean) => {
        if (value) {
          isControlChanged.current = true;
          params.current.delete(key);
        } else {
          params.current.set(key, checkedValue);
        }
        navigate({
          pathname: window.location.pathname,
          search: params.current.toString(),
        });
      },
    []
  );
  const getParam = useCallback((key: string) => {
    return params.current.get(key) || '';
  }, []);

  const prevSearch = usePrevious(search);
  useEffect(() => {
    if (!isControlChanged.current && search === '' && prevSearch) {
      navigate({
        pathname: window.location.pathname,
        search: prevSearch,
      });
    }
    isControlChanged.current = false;
  }, [search, prevSearch]);

  return { params, changeURLParam, getOrderBy, filterChange, filterToggle, getParam };
}
