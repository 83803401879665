import React, { FunctionComponent } from 'react';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { MixedSchema } from 'yup';
import LocationField from 'components/Form/Location/FormikField';
import Flex from 'components/Flex';
import Button from 'components/Button';
import Field from 'components/Form/Field';

export interface IProps {
  initialValues: IFormValues;
  validationSchema?: MixedSchema;
  onSubmit: (values: IFormValues, actions: FormikActions<IFormValues>) => void;
}

export interface IFormValues {
  is_location_confirmed: boolean;
  time_zone: string;
  name: string;
  number_of_units: number;
  location: {
    postcode: string;
    address: string;
    city: {
      title: string;
      id?: string;
    };
    region: {
      title: string;
      short_code: string;
    };
    country: {
      title: string;
      short_code: string;
    };
  };
}

const Form: FunctionComponent<IProps> = ({ initialValues, validationSchema, onSubmit }: IProps) => (
  <Formik<IFormValues>
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    initialValues={initialValues}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Field
          name="location"
          component={LocationField}
          className="mt8"
          placeholder="Search your address..."
          initValue={initialValues.location?.address}
        />
        <Flex justifyContent="center" className="mt48">
          <Button type="submit">Confirm</Button>
        </Flex>
      </form>
    )}
  </Formik>
);

export default Form;
