import React, { FunctionComponent, useMemo } from 'react';
import * as Yup from 'yup';
import Form, { IFormValues } from './Form';
import { useProfileInformation } from 'hooks/common/useProfileInformation';
import useProfile from 'hooks/useProfile';
import useOnSubmit from 'hooks/useOnSubmit';
import useApi from 'hooks/useApi';
import { convertToFormData } from 'libs/utils';
import * as validations from 'libs/validations';
import { IProfile } from 'domain/env/types';
import { getIn } from 'formik';
import { validationErrorSerializer } from 'components/Form/Location/FormikField';

export interface IProps {
  onSuccess?: (profile: IProfile) => void;
}

interface IProfileInformation {
  first_name: string;
  last_name: string;
  cell_num?: string;
  phone_number?: string;
  location: {
    postcode: string;
    address: string;
    apartment: string;
    city: {
      title: string;
      id?: string;
    };
    region: {
      title: string;
      short_code: string;
    };
    country: {
      title: string;
      short_code: string;
    };
  };
}

const ConfirmLocation: FunctionComponent<IProps> = ({ onSuccess }) => {
  const { profile, setProfile } = useProfile();
  const { getApiPath } = useApi();
  const { profileInformation } = useProfileInformation<IProfileInformation>({
    id: profile ? profile.id.toString() : undefined,
    initialValues: {
      first_name: '',
      last_name: '',
      location: {
        postcode: '',
        address: '',
        apartment: '',
        city: { title: '' },
        region: { title: '', short_code: '' },
        country: { title: '', short_code: '' },
      },
    },
  });
  const initialValues: IFormValues = useMemo(
    () => ({
      location: profileInformation.location,
      is_location_confirmed: true,
      cell_num: profileInformation.cell_num || '',
      phone_number: profileInformation.phone_number || '',
      first_name: profileInformation.first_name,
      last_name: profileInformation.last_name,
    }),
    [profileInformation]
  );

  const validationsSchema: any = {
    location: validations.location,
    phone_number: validations.atLeastOnePhone,
    cell_num: validations.atLeastOnePhone,
    first_name: validations.firstName,
    last_name: validations.lastName,
  };

  const onSubmit = useOnSubmit<IFormValues>({
    api: getApiPath('profile.tabs.info', 'update'),
    onSuccess: (response) => {
      const resource = getIn(response, ['data', 'resource']);
      setProfile({ ...profile, is_location_confirmed: true });
      if (onSuccess) {
        onSuccess(resource);
      }
    },
    params: { id: profile.id },
    serializer: (data) => convertToFormData(data, 'resource', ['avatar']),
    successMessage: 'Your location updated successfully',
    validationErrorSerializer: validationErrorSerializer('location'),
  });

  return (
    <>
      {initialValues.first_name && (
        <Form
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationsSchema)}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default ConfirmLocation;
