import Autocomplete from 'components/Autocomplete';
import Flex from 'components/Flex';
import { IOption } from 'components/Form/Select/SingleSelect';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import Contact from './Contact';
import { Subtitle, Title } from './styled';

interface ISearchForm {
  query?: string;
  onSearch: (value?: string) => void;
  debouncedOnChange: (value?: string) => void;
  suggestions: IOption[];
  handleSelectAccount: (value?: IOption) => void;
}

const SearchForm: FunctionComponent<ISearchForm> = ({
  query,
  onSearch,
  debouncedOnChange,
  suggestions,
  handleSelectAccount,
}) => (
  <Content direction="column" justifyContent="space-around" alignItems="center">
    <Flex direction="column" alignItems="center">
      <Title>Search for Your Condominimum or Association</Title>
      <Subtitle>Enter your Condominimum or Association name below</Subtitle>
      <AutocompleteWrap>
        <Autocomplete
          initialValue={query}
          inputProps={{
            placeholder: 'Search...',
          }}
          onSearch={onSearch}
          onChange={debouncedOnChange}
          suggestions={suggestions}
          onSelect={handleSelectAccount}
        />
      </AutocompleteWrap>
    </Flex>
    <Contact />
  </Content>
);

export default SearchForm;

export const Content = styled(Flex)`
  flex: 1;
  margin-bottom: -40px;
`;

const AutocompleteWrap = styled.div`
  width: 100%;
  max-width: 525px;
`;
