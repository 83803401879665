import React from 'react';
import Field from 'components/Form/Field';
import TagsInput from 'components/Form/TagsInput';
import Text from 'components/Text';
import useEmailInvoices from 'hooks/useEmailInvoices';
import { FormikProps } from 'formik';

export function PropertyInfo<T>({ values, setFieldValue }: FormikProps<T>) {
  const {
    handleChange: handleInvoiceEmailsChange,
    validateEmail,
    invoiceEmails,
  } = useEmailInvoices<T>('invoice_emails_attributes', values, setFieldValue);

  return (
    <>
      <Text size="xs">
        Enter the legal number of the condominium corporation or association. If there is no legal
        number for your association, please enter the assciation name.
      </Text>
      <Field name="legal_name" label="Legal number" placeholder="Legal" className="mt16" />
      <Text size="xs">
        Please provide an e-mail to which we should send invoice/receipt to. This may be your
        e-mail, an accounting e-mail, or a general e-mail that will receive receipts for your
        records. If you would like us to send the invoice/receipt to multiple recipients please
        specify mutliple e-mail's in sequence.
      </Text>
      <Field
        name="invoice_emails_attributes"
        component={TagsInput}
        checkValue={validateEmail}
        tagDisplayProp="email"
        label="E-mail invoices to"
        placeholder="E-mail"
        className="mt16"
        onChange={handleInvoiceEmailsChange}
        value={invoiceEmails}
        onlyUnique
      />
      <Text size="xs">
        If your association / corporation is managed by a management company, please enter the
        management company's name
      </Text>
      <Field name="company_name" label="Company name" placeholder="Company name" className="mt16" />
    </>
  );
}
