import { setCompanyAction } from 'domain/env/actions';
import {
  envCommonCompanySelector,
  envIsErrorSelector,
  envIsLoadingSelector,
} from 'domain/env/selectors';
import { TCompany } from 'domain/env/types';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { createStructuredSelector } from 'reselect';

const selector = createStructuredSelector({
  isError: envIsErrorSelector,
  isLoading: envIsLoadingSelector,
  resource: envCommonCompanySelector,
});

export type TSetCompany = (company: TCompany) => void;

interface IUseCompany {
  company: TCompany;
  setCompany: TSetCompany;
  isLoading: boolean;
  isError: boolean;
}

export default function useCompany(): IUseCompany {
  const dispatch = useDispatch();
  const { resource, isLoading, isError } = useMappedState(selector);

  const setCompany: TSetCompany = (company) => {
    dispatch(setCompanyAction({ company }));
  };

  return {
    company: resource,
    isError,
    isLoading,
    setCompany,
  };
}
