import { Checkmark, Icon, Label, StyledInput, Wrapper } from 'components/Form/Checkbox';
import React, { ChangeEvent, FunctionComponent, useCallback, useState } from 'react';
import styled from 'styled-components/macro';

export const FILTER_CHECKED_STRING_VALUE = '1';

export interface IFilterCheckbox {
  label?: string;
  initialValue?: string;
  onClick: (value: boolean) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => boolean;
  visible?: boolean;
  checkedValue?: string;
}

const SingleFilterCheckbox: FunctionComponent<IFilterCheckbox & { className?: string }> = ({
  label,
  className,
  initialValue = '',
  onChange = () => false,
  onClick,
  visible = true,
  checkedValue = FILTER_CHECKED_STRING_VALUE,
}) => {
  const [checked, setChecked] = useState<boolean>(initialValue === checkedValue);

  const onClickAction = useCallback(() => {
    setChecked(!checked);
    onClick(checked);
  }, [onClick, checked]);

  return visible ? (
    <Root className={className}>
      <Wrapper>
        <StyledInput
          type="checkbox"
          checked={checked}
          onClick={onClickAction}
          onChange={onChange}
        />
        <Checkmark>
          <Icon />
        </Checkmark>
        {label && <Label>{label}</Label>}
      </Wrapper>
    </Root>
  ) : null;
};

export default SingleFilterCheckbox;

const Root = styled.div`
  margin-left: 24px;
  margin-top: 24px;
  position: relative;
`;
