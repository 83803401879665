import { Dispatch, SetStateAction, useCallback, useState } from 'react';

interface IUseStepsParam {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  goToPrevStep: () => void;
  goToNextStep: () => void;
}

const useSteps = (initialValue = 1): IUseStepsParam => {
  const [step, setStep] = useState(initialValue);

  const goToNextStep = useCallback(() => setStep((value) => value + 1), []);
  const goToPrevStep = useCallback(() => setStep((value) => value - 1), []);

  return { step, setStep, goToPrevStep, goToNextStep };
};

export default useSteps;
