import Card from 'components/Card';
import { Header as CardHeader } from 'components/Card/Basic';
import Flex from 'components/Flex';
import Text from 'components/Text';
import useOutsidePopupToggle from 'hooks/useOutsidePopupToggle';
import useTheme from 'hooks/useTheme';
import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg';
import { fullUrlPath } from 'libs/utils';
import React, { Fragment, FunctionComponent, Ref, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components/macro';
import useCommonPicker from './useCommonPicker';

const CommonPicker: FunctionComponent = () => {
  const theme = useTheme();
  const { toggle, isMenuOpen, nodeRef } = useOutsidePopupToggle<HTMLDivElement>();
  const [referenceElement, setReferenceElement] = useState<null | HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<null | HTMLDivElement>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });
  useEffect(() => {
    nodeRef.current = popperElement;
  }, [popperElement]);
  const { items: groupItems } = useCommonPicker();

  if (groupItems.every(({ items }) => items.length === 0)) {
    return null;
  }

  return (
    <>
      <AccountDropDownButton
        justifyContent="center"
        alignItems="center"
        onClick={toggle}
        isMenuOpen={isMenuOpen}
        ref={setReferenceElement}
      >
        <ArrowDown width="40px" height="40px" />
      </AccountDropDownButton>
      {isMenuOpen && (
        <AccountDropDown ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {groupItems.map(
            ({ items, name }) =>
              items.length > 0 && (
                <Fragment key={name}>
                  <AccountDropDownHeader>
                    <Text size="xs" color={theme.colors.dark} weight="bold">
                      {name}
                    </Text>
                  </AccountDropDownHeader>
                  {items.map((item) => (
                    <Option
                      href={fullUrlPath(item.subdomain, '')}
                      key={item.id}
                      data-test="go-to-common"
                    >
                      {item.name}
                    </Option>
                  ))}
                </Fragment>
              )
          )}
        </AccountDropDown>
      )}
    </>
  );
};

export default CommonPicker;

const AccountDropDownButton = styled(Flex)<{
  onClick: () => void;
  isMenuOpen: boolean;
  ref: Ref<HTMLDivElement>;
}>`
  border: 2px solid ${(props) => props.theme.colors.lightGrey};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 24px;
  cursor: pointer;
  transition: all 0.3s;
  ${(props) =>
    props.isMenuOpen &&
    `
    border-color: ${props.theme.colors.accentNormal};
    background-color: ${props.theme.colors.accentNormal};
    ${props.theme.fillSvg(props.theme.colors.snow)};
    & svg {
      transition: transform 0.3s;
      transform: rotate(180deg);
    }
  `}
  ${(props) => props.theme.media.sm`
    margin-left: 14px;
  `};
`;

const AccountDropDown = styled(Card)`
  position: absolute;
  right: 20px;
  top: 100%;
  z-index: 200;
  width: fit-content;
  max-width: 250px;
  overflow: auto;
  max-height: 335px;
  ${(props) => props.theme.media.md`
    left: 0;
  `};
  ${(props) => props.theme.media.sm`
    left: 0;
    right: 0;
  `};
`;

const AccountDropDownHeader = styled(CardHeader)`
  padding: 17px 22px;
  border-bottom: 0;
  background: ${(props) => props.theme.colors.optionGrey};
`;

const Option = styled.a`
  display: block;
  font-family: Gotham Pro Bold;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.darkGrey};
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  --max-lines: 2;
  max-height: calc(20px * var(--max-lines));
  &:hover {
    background-color: ${(props) => props.theme.colors.optionGrey};
  }
`;
