import { MutableRefObject, useCallback, useRef, useState } from 'react';

export default function useOutsidePopupToggle<T extends HTMLElement>(
  defaultToggle = false
): {
  toggle: () => void;
  isMenuOpen: boolean;
  nodeRef: MutableRefObject<T | null>;
} {
  const [isMenuOpen, toggleMenu] = useState(defaultToggle);
  const toggle = useCallback(() => {
    if (!isMenuOpen) {
      document.addEventListener('click', handleOutsideClick, false);
    } else {
      document.removeEventListener('click', handleOutsideClick, false);
    }
    toggleMenu(!isMenuOpen);
  }, [isMenuOpen]);

  const nodeRef: MutableRefObject<T | null> = useRef(null);

  const handleOutsideClick = useCallback((e) => {
    if (nodeRef.current && nodeRef.current.contains(e.target)) {
      return;
    }
    document.removeEventListener('click', handleOutsideClick, false);
    if (nodeRef.current) {
      toggleMenu(false);
    }
  }, []);

  return { toggle, isMenuOpen, nodeRef };
}
