import Button from 'components/Button';
import Flex from 'components/Flex';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IProp {
  title: string;
  message: string | JSX.Element;
  actionTitle?: string;
  onClick?: () => void;
}

const ErrorView: FunctionComponent<IProp> = ({ title, message, actionTitle, onClick }) => (
  <ErrorContainer direction="column" alignItems="center" justifyContent="center">
    <ErrorTitle>{title}</ErrorTitle>
    <ErrorMessage>{message}</ErrorMessage>
    {onClick && actionTitle && (
      <Button onClick={onClick} styleType="secondary">
        {actionTitle}
      </Button>
    )}
  </ErrorContainer>
);

export default ErrorView;

const ErrorContainer = styled(Flex)`
  width: 100vw;
  background: #82aa00;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  height: calc(100vh - 58px);
  ${(props) => props.theme.media.md`
    height: calc(100vh - 90px);
  `}
`;

const ErrorTitle = styled.h2`
  font-size: 40px;
  margin-bottom: 40px;
  color: #ffffff;
  white-space: pre-line;
  text-align: center;
  max-width: 900px;
`;

const ErrorMessage = styled.p`
  font-size: 25px;
  margin-bottom: 50px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  max-width: 900px;
`;
