import React, { FunctionComponent, useCallback } from 'react';
import { FieldAttributes, FieldProps } from 'formik';
import Field from 'components/Form/Field';
import Input, { IInput } from 'components/Form/Input';
import PhoneInput from '.';

const PhoneComponent: FunctionComponent<IInput & FieldProps> = ({ field, form, ...rest }) => {
  const changeHandler = useCallback(
    (phone) => form.setFieldValue(field.name, phone),
    [field, form]
  );

  return <Input field={{ ...field, onChange: changeHandler }} form={{ ...form }} {...rest} />;
};

//TODO replace any
const FormikField: FunctionComponent<FieldAttributes<any>> = ({ name, ...rest }) => {
  const validate = useCallback(() => {
    const input = document.getElementById(name);
    if (input) {
      const iti = window.intlTelInputGlobals.getInstance(input);
      if (iti.getNumber()) {
        return iti.isValidNumber() ? '' : 'Phone number has an invalid format';
      }
    }
  }, []);

  return (
    <Field
      name={name}
      id={name}
      inputComponent={PhoneInput}
      component={PhoneComponent}
      placeholder=""
      data-lpignore="true"
      validate={validate}
      {...rest}
    />
  );
};

export default FormikField;
