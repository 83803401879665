import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { ReactComponent as SearchIcon } from 'icons/search.svg';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import HeaderLinkTemplate, { IconContainer } from '../HeaderLinkTemplate';

interface ISearchAssociationHeaderLink {
  className?: string;
}

const SearchAssociationHeaderLink: FunctionComponent<ISearchAssociationHeaderLink> = ({
  className = '',
}) => {
  const theme = useTheme();

  return (
    <HeaderLinkTemplate
      className={className}
      desktopView={
        <Text color={theme.colors.dark} size="md">
          <Link to="/search">Click here to find your association</Link>
        </Text>
      }
      mobileView={
        <Link to="/search">
          <IconContainer justifyContent="center" alignItems="center">
            <SearchIcon />
          </IconContainer>
        </Link>
      }
    />
  );
};

export default SearchAssociationHeaderLink;
