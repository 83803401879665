import { IRenderRoute } from 'hooks/useRoutes';
import { lazy } from 'react';

const routes: IRenderRoute[] = [
  {
    component: lazy(() => import('pages/Auth/UserSignIn/SignIn')),
    path: '/sign-in',
    title: 'Sign In To CondoGenie',
    isTitlePrefix: false,
    type: 'read',
  },
  {
    component: lazy(() => import('pages/Auth/UserSignIn/Forgot')),
    path: '/forgot',
    title: 'Forgot Email',
    type: 'read',
  },
  {
    component: lazy(() => import('pages/Auth/UserSignIn/ForgotEmailSent')),
    path: '/forgot-sent',
    title: 'Forgot Email Sent',
    type: 'read',
  },
  {
    component: lazy(() => import('pages/JoinTo')),
    path: '/join-to',
    title: 'Join',
    type: 'read',
  },
];

export default routes;
