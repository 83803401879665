import ClearSelect from 'components/Form/ClearSelect';
import Label from 'components/Form/Label';
import StyledErrorMessage from 'components/Form/StyledErrorMessage';
import Wrapper from 'components/Form/Wrapper';
import { ErrorMessage, FieldProps, getIn } from 'formik';
import isPlainObject from 'lodash.isplainobject';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import React from 'react';
import { Props } from 'react-select/lib/Select';
import { ActionMeta, ValueType } from 'react-select/lib/types';

export interface IOption {
  // eslint-disable-next-line @typescript-eslint/ban-types
  value: string | number | object;
  label: string;
}

export interface ISingleSelect {
  className?: string;
  label?: string;
  placeholder?: string;
  options?: IOption[];
  onChange?: (value: ValueType<IOption>, action?: ActionMeta) => void;
  selectIfExistOne?: boolean;
  component?: FunctionComponent<Props<IOption>>;
  alphabeticallySort?: boolean;
}

const SingleSelect: FunctionComponent<ISingleSelect & FieldProps> = ({
  label,
  className,
  placeholder,
  field: { name, value },
  form,
  options,
  onChange,
  selectIfExistOne = false,
  alphabeticallySort = true,
  ...rest
}) => {
  const memoOptions = useMemo(() => {
    if (isPlainObject(value)) {
      return value;
    }
    return options ? options.find((option) => option.value === value) : undefined;
  }, [options, value]);

  const sortedOptions = useMemo(() => {
    let result = options;

    if (alphabeticallySort && result) {
      result = result.slice().sort((a, b) => {
        if (typeof a.label == 'string') {
          return a.label.toLowerCase() < b.label?.toString().toLowerCase() ? -1 : 1;
        } else {
          return a.label < b.label ? -1 : 1;
        }
      });
    }

    return result;
  }, [options, alphabeticallySort]);

  const onBlur = useCallback(() => form.setFieldTouched(name, true), []);
  const onChangeHandle = useCallback(
    (option, action) => {
      form.setFieldValue(name, option ? option.value : option);
      if (onChange) {
        onChange(option, action);
      }
    },
    [onChange]
  );

  // Automatically select first option if amount of options equal 1
  useEffect(() => {
    if (selectIfExistOne && options && options.length === 1) {
      form.setFieldValue(name, getIn(options, ['0', 'id']));
    }
  }, [selectIfExistOne, options]);

  return (
    <Wrapper className={className}>
      {label && <Label>{label}</Label>}
      <ClearSelect
        error={Boolean(getIn(form.errors, name) && getIn(form.touched, name))}
        options={sortedOptions}
        onChange={onChangeHandle}
        onBlur={onBlur}
        value={memoOptions || null}
        name={name}
        id={name}
        placeholder={placeholder}
        {...rest}
      />
      <ErrorMessage name={name} component={StyledErrorMessage} />
    </Wrapper>
  );
};

export default SingleSelect;
