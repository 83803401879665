import NewsBody from 'components/Website/Items/ItemBody';
import NewsContainer from 'components/Website/Items/ItemContainer';
import NewsSmallTitle from 'components/Website/Items/ItemSmallTitle';
import PublishDate from 'components/Website/Items/PublishDate';
import ReadMore from 'components/Website/Items/ReadMore';
import truncate from 'lodash.truncate';
import React, { FunctionComponent, useMemo } from 'react';

export interface INewsItem {
  title: string;
  body: string;
  publishDate: Date;
  link: string;
}

const NewsPreviewItem: FunctionComponent<INewsItem> = ({ title, body, publishDate, link }) => {
  const bodyLength = 250;
  const truncatedBody = useMemo(
    () =>
      truncate(body, {
        length: bodyLength,
        separator: /,? +/,
      }),
    [body]
  );

  return (
    <NewsContainer>
      <PublishDate date={publishDate} />
      <NewsSmallTitle>{title}</NewsSmallTitle>
      <NewsBody dangerouslySetInnerHTML={{ __html: truncatedBody }} />
      <ReadMore to={link}>Read more</ReadMore>
    </NewsContainer>
  );
};

export default NewsPreviewItem;
