import Button from 'components/Button';
import RemoveButtonRow from 'components/FieldArray/RemoveButtonRow';
import Flex from 'components/Flex';
import CreatableSelect from 'components/Form/Select/CreatableSelect';
import ConfirmModal from 'components/Modal/Confirm';
import Row from 'components/Row';
import Text from 'components/Text';
import { Field, FieldArrayRenderProps, getIn } from 'formik';
import useFilterOptions from 'hooks/useFilterOptions';
import usePrevious from 'hooks/usePrevious';
import { selectSerializer } from 'libs/serializers';
import React, { Fragment, FunctionComponent, useCallback, useEffect } from 'react';
import useConfirmModal from './hooks';
import useGetUnits from 'hooks/common/useGetUnits';

interface IUnitsFieldArray {
  units: string[];
  buildingId: string | number | boolean;
  isDisabled?: boolean;
}

export interface IUnitItem {
  name: string;
}

interface IUnit {
  id: string;
  unit_number: string;
}

interface IUnitOption extends IUnit {
  label: string;
  value: string;
}

export const UnitsFieldArray: FunctionComponent<FieldArrayRenderProps & IUnitsFieldArray> = ({
  handlePush,
  buildingId = false,
  units,
  form,
  push,
  name,
  remove,
  isDisabled,
}) => {
  const {
    units: options,
    setUnits: addOption,
    fetchUnits: fetchOptions,
  } = useGetUnits<IUnitOption>({
    serializer: (data) =>
      selectSerializer(data.resources, { labelKey: 'unit_number', valueKey: 'unit_number' }),
  });

  const prevBuildingId = usePrevious(buildingId);

  useEffect(() => {
    if (buildingId) {
      fetchOptions({ buildingId });
      if (prevBuildingId) {
        units.forEach(() => remove(0));
        push('');
      }
    }
  }, [buildingId]);

  const onCreate = useCallback((option) => {
    addOption((state) => {
      return [...state, option];
    });
  }, []);

  const onBlur = useCallback(
    (name: string) => (e: any) => {
      const value = e.target.value;
      if (value && !options.find((o) => o.value === e.target.value)) {
        onCreate({ value: value, label: value });
        form.setFieldValue(name, value);
      }
    },
    [options]
  );

  const filterOptions = useFilterOptions(options, form.values, name);
  const { confirm, close, modalIsOpen, handleToggle } = useConfirmModal(remove, form.values);

  return (
    <div>
      <ConfirmModal
        isOpen={modalIsOpen}
        close={close}
        question="Do you really want to delete this unit?"
        confirm={confirm}
      />
      {units.map((unit: string, unitIndex: number) => (
        <Fragment key={unitIndex}>
          <Row>
            <Field
              name={`${name}.${unitIndex}`}
              component={CreatableSelect}
              label="Unit"
              placeholder="Enter unit"
              className="mt8 mb4"
              onCreate={onCreate}
              onBlur={onBlur(`${name}.${unitIndex}`)}
              options={filterOptions(unitIndex)}
              alphabeticallySort={false}
              isDisabled={isDisabled}
            />
          </Row>
          <Text size="xs">Type in your unit number if it is not available in the list.</Text>
          {!isDisabled && (
            <Flex justifyContent="space-between" direction="row-reverse">
              <RemoveButtonRow>
                {getIn(form.values, name).length > 1 && (
                  <Button styleType="link" onClick={handleToggle(unitIndex, name)}>
                    - remove this unit
                  </Button>
                )}
              </RemoveButtonRow>
              <div hidden={getIn(form.values, name).length - 1 !== unitIndex}>
                <Button
                  className="mt16"
                  padding="0 23.5px"
                  styleType="link"
                  onClick={handlePush('')}
                >
                  + add another unit
                </Button>
              </div>
            </Flex>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default UnitsFieldArray;
