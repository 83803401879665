import { FILTER_CHECKED_STRING_VALUE } from 'components/ListFilters/Checkbox';
import { thunkLogOut } from 'domain/env/effects';
import useAuth from 'hooks/useAuth';
import useCommon from 'hooks/useCommon';
import { TAllPermissions } from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import { ReactComponent as AmenityBookings } from 'icons/user-menu/amenity-bookings.svg';
import { ReactComponent as Classifieds } from 'icons/user-menu/classifieds.svg';
import { ReactComponent as ForumMessages } from 'icons/user-menu/forum-messages.svg';
import { ReactComponent as Logout } from 'icons/user-menu/log-out.svg';
import { ReactComponent as Profile } from 'icons/user-menu/profile.svg';
import { ReactComponent as Requests } from 'icons/user-menu/requests.svg';
import { ReactComponent as UnitMessages } from 'icons/user-menu/unit-messages.svg';
import { AUTH_RENDER_ROUTES_URLS } from 'libs/constants';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';

export interface IMenuItem {
  dataTest?: string;
  onClick: () => void;
  visible?: boolean;
  icon: ReactElement;
  title: string;
  border?: string;
  permission?: TAllPermissions;
}

const useUserMenu = () => {
  const dispatch = useDispatch();
  const { isAuthorized } = useAuth();
  const { profile, hasAccess, isNoProfile } = useProfile();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    dispatch(thunkLogOut(navigate));
  }, [navigate]);

  const { isCommonTypeAccount, isCommonTypeUndefined } = useCommon();

  const isShowMenuItem = useMemo(
    () => !AUTH_RENDER_ROUTES_URLS.includes(window.location.pathname),
    [window.location.pathname]
  );

  const authorizedMenuItems: IMenuItem[] = useMemo(() => {
    return [
      {
        border: 'bottom',
        dataTest: 'my-profile',
        icon: <Profile />,
        onClick: (): void => navigate('/profile'),
        title: 'My profile',
        visible: isShowMenuItem && !isCommonTypeUndefined && !isNoProfile,
      },
      {
        dataTest: 'my-requests',
        icon: <Requests />,
        onClick: (): void => navigate(`/requests?with_own=${FILTER_CHECKED_STRING_VALUE}`),
        permission: 'requests' as TAllPermissions,
        title: 'My requests',
        visible: isShowMenuItem && isCommonTypeAccount,
      },
      {
        dataTest: 'my-forum-messages',
        icon: <ForumMessages />,
        onClick: (): void =>
          navigate(`/forum/messages?archive=active&with_own=${FILTER_CHECKED_STRING_VALUE}`),
        permission: 'message_forum' as TAllPermissions,
        title: 'My forum messages',
        visible: isShowMenuItem && isCommonTypeAccount,
      },
      {
        dataTest: 'my-classifieds',
        icon: <Classifieds />,
        onClick: (): void => navigate(`/classifieds?with_own=${FILTER_CHECKED_STRING_VALUE}`),
        permission: 'classifieds' as TAllPermissions,
        title: 'My classifieds',
        visible: isShowMenuItem && isCommonTypeAccount,
      },
      {
        dataTest: 'my-amenity-booking',
        icon: <AmenityBookings />,
        onClick: (): void =>
          navigate(`/amenities/bookings?with_own=${FILTER_CHECKED_STRING_VALUE}`),
        permission: 'amenities' as TAllPermissions,
        title: 'My amenity bookings',
        visible: isShowMenuItem && isCommonTypeAccount,
      },
      {
        dataTest: 'my-unit-messages',
        icon: <UnitMessages />,
        onClick: (): void => navigate(`/unit/messages?with_own=${FILTER_CHECKED_STRING_VALUE}`),
        permission: 'unit_messages' as TAllPermissions,
        title: 'My unit messages',
        visible: isShowMenuItem && isCommonTypeAccount,
      },
      {
        border: 'top',
        dataTest: 'log-out',
        icon: <Logout />,
        onClick: logout,
        title: 'Log out',
        visible: true,
      },
    ];
  }, [
    isAuthorized,
    isShowMenuItem,
    isCommonTypeAccount,
    isCommonTypeUndefined,
    isNoProfile,
    navigate,
  ]);

  const unauthorizedMenuItems: IMenuItem[] = useMemo(
    () => [
      {
        dataTest: 'sign-in',
        icon: <Profile />,
        onClick: (): void => navigate('/sign-in'),
        title: 'Sign In',
        visible: true,
      },
    ],
    [navigate]
  );

  const menuItems = useMemo(() => {
    const items = isAuthorized ? authorizedMenuItems : unauthorizedMenuItems;
    return items.filter((route) => (route.permission ? hasAccess(route.permission) : true));
  }, [profile.permissions, authorizedMenuItems, unauthorizedMenuItems]);

  return { menuItems };
};

export default useUserMenu;
