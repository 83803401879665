import { useEffect } from 'react';
import { getIn } from 'formik';
import useCommon from 'hooks/useCommon';
import { useNavigate } from 'react-router-dom';

export default function usePayments() {
  const { common, isCommonTypeAccount } = useCommon();
  const navigate = useNavigate();

  useEffect(() => {
    if (isCommonTypeAccount && getIn(common, ['account', 'is_annual_subscription'])) {
      navigate('/404');
    }
  }, [common, isCommonTypeAccount]);
}
