import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import { NavMenuItem } from 'pages/App/Header/Menu/WebsiteMenu/styled';
import { ICustomPage } from 'pages/Website/CustomPage';
import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import styled from 'styled-components';
import useProfile from 'hooks/useProfile';
import { getIn } from 'formik';

interface IUseWebsiteMenu {
  toggle?: () => void;
}

interface ICustomSlugPath {
  [key: string]: string;
}

export const generatePathFromSlug = (
  slug: string,
  isAuthorized?: boolean,
  canRestoreAmenity?: boolean
) => {
  const customSlugPath: ICustomSlugPath = {
    amenities: isAuthorized ? `/amenities${canRestoreAmenity ? '?status=active' : ''}` : '/sign-in',
    home: '/',
  };

  return customSlugPath[slug] || `/public/${slug}`;
};

const useWebsiteMenu = ({ toggle = () => null }: IUseWebsiteMenu = {}): {
  renderItems: () => JSX.Element;
} => {
  const { isAuthorized } = useAuth();
  const { profile } = useProfile();
  const canRestoreAmenity = useMemo(() => {
    return getIn(profile, ['additional_permissions', 'can_restore_amenity']);
  }, [profile]);

  const { resource: customPages } = useFetchResource<ICustomPage[]>({
    api: api.website.public.getAll,
    initialValues: [],
    serializer: (data) => data.resources,
  });

  const menuItems = useMemo(
    () =>
      customPages
        .filter(({ policy: { can_read } }) => can_read)
        .map(({ slug, title: label }) => ({
          exact: generatePathFromSlug(slug) === '/',
          label,
          path: generatePathFromSlug(slug, isAuthorized, canRestoreAmenity),
        })),
    [customPages, isAuthorized, canRestoreAmenity]
  );

  const renderItems = useCallback(
    () => (
      <MenuItems>
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            exact={item.exact}
            onClick={toggle}
            to={item.path}
            as={NavLink}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuItems>
    ),
    [menuItems, toggle]
  );

  return { renderItems };
};

export default useWebsiteMenu;

const MenuItems = styled.div`
  padding: 15px 45px;
`;

const MenuItem = styled(NavMenuItem)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
