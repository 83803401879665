import { validateYupSchema, yupToFormErrors } from 'formik';
import { MixedSchema } from 'yup';

export default function runValidationSchema<T>(schema: MixedSchema, values: T) {
  return new Promise((resolve) => {
    validateYupSchema(values, schema).then(
      () => {
        resolve({});
      },
      (err: any) => {
        // Fixed: https://github.com/jaredpalmer/formik/blob/master/docs/migrating-v2.md#validate
        resolve(yupToFormErrors(err));
      }
    );
  });
}
