import siteBackgroundImage from 'images/site-background.jpg';
import { mobileMenuWidth } from 'pages/App/Header/constants';
import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { useTheme } from 'theme/ThemeContext';

interface IContainerProps {
  children?: ReactNode;
}

const Container: FunctionComponent<IContainerProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <MainContainer
      siteBackground={
        theme.backgroundImage
          ? `${process.env.REACT_APP_IMAGE_PREFIX}${theme.backgroundImage}`
          : siteBackgroundImage
      }
    >
      {children}
    </MainContainer>
  );
};

const MainContainer = styled.div<{ siteBackground: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: url(${(props) => props.siteBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: calc(100% - ${mobileMenuWidth});
  ${(props) => props.theme.media.md`
    width: 100%;
  `}
`;

export default Container;
