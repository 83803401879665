import Checkbox from 'components/Form/Checkbox';
import Field from 'components/Form/Field';
import Text from 'components/Text';
import React from 'react';

export const YourContact = () => (
  <>
    <Field name="user_name" label="Your name" placeholder="Your name" />
    <Field name="user_email" label="Your email" placeholder="Your email" />
    <Field name="user_position" label="Your position" placeholder="Your position" />
    <Text size="xs">
      Are you authorized to setup and/or provide this information? Please check the box below if you
      are.
    </Text>
    <Field
      name="is_authorized_to_payments"
      component={Checkbox}
      label="Yes, I am authorized to setup payments"
      className="mt8"
    />
  </>
);
