import styled from 'styled-components';

export default styled.h1`
  color: ${(props) => props.theme.colors.snow};
  font-family: Gotham Pro Bold;
  font-size: 64px;
  line-height: 70px;
  padding: 40px 65px;
  box-sizing: border-box;
  ${(props) => props.theme.media.sm`
    font-size: 32px;
    line-height: 35px;
  `}
`;
