import UAParser, { IResult } from 'ua-parser-js';
import useOnSave from './useOnSave';
import api from 'libs/api';

interface IDevice {
  subscription: PushSubscription;
  title: string;
}

export default function usePushNotifications(pushServerPublicKey: string) {
  function isPushNotificationSupported(): boolean {
    return 'serviceWorker' in navigator && 'PushManager' in window;
  }

  function canAskUserPermission(): boolean {
    return Notification.permission === 'default';
  }

  async function askUserPermission(): Promise<NotificationPermission> {
    return await Notification.requestPermission();
  }

  function isAllowPushNotification(): boolean {
    return Notification.permission === 'granted';
  }

  async function createNotificationSubscription(): Promise<PushSubscription> {
    const serviceWorker: ServiceWorkerRegistration = await navigator.serviceWorker.ready;
    return await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: pushServerPublicKey,
    });
  }

  const createDevice = useOnSave({
    api: api.push_notifications.devices.create,
    successMessage: 'Subscription has been created successfully',
  });

  async function askPushNotifications(onSuccess?: (device: IDevice) => void) {
    if (isPushNotificationSupported() && !isAllowPushNotification()) {
      await askUserPermission();
      if (isAllowPushNotification()) {
        const subscription: PushSubscription = await createNotificationSubscription();
        const agent: IResult = new UAParser().getResult();
        const device: IDevice = {
          title: `${agent.browser.name} ${agent.browser.version}; ${agent.os.name}`,
          subscription,
        };

        await createDevice({ data: { resource: device } });
        onSuccess && onSuccess(device);
      }
    }
  }

  return {
    isPushNotificationSupported,
    isAllowPushNotification,
    canAskUserPermission,
    askUserPermission,
    createNotificationSubscription,
    askPushNotifications,
  };
}
