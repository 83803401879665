import styled from 'styled-components/macro';

export default styled.h1`
  color: ${(props) => props.theme.colors.dark};
  font-family: Gotham Pro Bold;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: -0.04em;
  padding: 20px 0;
`;
