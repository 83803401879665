import { useCallback, useState } from 'react';

function useToggleMenu() {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    document.body.style.overflow = !open ? 'hidden' : 'auto';
    setOpen(!open);
  }, [open]);

  return { open, toggleOpen };
}

export default useToggleMenu;
