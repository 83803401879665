import { AxiosResponse } from 'axios';
import { TApi } from 'libs/api';

export interface TypesEnum {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
}

interface IFetchDataNew {
  api: TApi;
  dispatch: (action: any) => void;
  params?: any;
  data?: any;
  serializer?: (response: AxiosResponse) => any;
  action: any;
}

export async function fetchData({
  api,
  dispatch,
  params,
  data,
  action,
  serializer = (response: AxiosResponse) => response.data,
}: IFetchDataNew) {
  try {
    dispatch(action.request());
    const response = await api({ ...params, data });
    dispatch(action.success(serializer(response)));
    return serializer(response);
  } catch (error) {
    dispatch(action.failure(error));
    return error;
  }
}
