import Button from 'components/Button';
import Flex from 'components/Flex';
import Modal from 'components/Modal';
import React, { FunctionComponent, MutableRefObject, useCallback, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components/macro';

interface ICropImageModal {
  modalIsOpen: boolean;
  toggleModal: () => void;
  preview: string | File;
  onCrop: (image: Blob | null) => void;
}

const CropImageModal: FunctionComponent<ICropImageModal> = ({
  modalIsOpen,
  toggleModal,
  preview,
  onCrop,
}) => {
  const editorRef: MutableRefObject<AvatarEditor | null> = useRef(null);
  const handleCrop = useCallback(() => {
    const img = editorRef.current!.getImageScaledToCanvas();
    img.toBlob((blob) => {
      onCrop(blob);
      toggleModal();
    }, 'image/jpeg');
  }, [editorRef]);

  return (
    <Modal isOpen={modalIsOpen} close={toggleModal}>
      <Modal.Basic title="Upload profile picture" close={toggleModal}>
        <ModalBody direction="column" justifyContent="center">
          <AvatarEditor
            ref={editorRef}
            image={preview}
            width={200}
            height={200}
            borderRadius={100}
            border={[75, 25]}
            color={[255, 255, 255, 0.6]}
            scale={1.25}
            crossOrigin={'anonymous'}
          />
          <Flex justifyContent="space-between" className="mt48">
            <ActionButton styleType="secondary" className="mr8" onClick={toggleModal}>
              Cancel
            </ActionButton>
            <ActionButton className="ml8" type="button" onClick={handleCrop}>
              Crop & Upload
            </ActionButton>
          </Flex>
        </ModalBody>
      </Modal.Basic>
    </Modal>
  );
};

export default CropImageModal;

const ModalBody = styled(Flex)`
  padding: 30px 100px;
`;

const ActionButton = styled(Button)`
  flex: 1;
`;
