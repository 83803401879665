import CommonNotice from 'components/Notices';
import { TNoticeType } from 'domain/notice/types';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

interface ILayout {
  className?: string;
  notice?: TNoticeType;
}

const Layout: FunctionComponent<ILayout> = ({ className, notice, children }) => {
  return (
    <Wrapper>
      {notice && <CommonNotice path={notice} />}
      <Background className={className}>{children}</Background>
    </Wrapper>
  );
};

export default Layout;

const Wrapper = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.pageBackground};
`;

const Background = styled.div`
  padding: 0 32px 32px 32px;
  box-sizing: border-box;
  min-height: calc(100vh - 90px);
`;

export const ReportLayout = styled(Layout)`
  @media print {
    * {
      color: #000 !important;
    }
    svg path {
      fill: #000 !important;
    }
  }
`;
