import { combineReducers } from 'redux';

/**
 * Combine all reducers in this file and export the combined reducers.
 */
import * as env from './env';
import * as notice from './notice';
import * as videoTutorials from './videoTutorials';

export const rootReducer: any = combineReducers({
  env: env.reducer,
  notices: notice.reducer,
  videoTutorials: videoTutorials.reducer,
});

export type AppState = ReturnType<typeof rootReducer>;
