import React, { FunctionComponent } from 'react';
import Flex from 'components/Flex';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'icons/info.svg';

export interface IDisableWebsiteInfo {
  account: string;
}

const DisableWebsiteInfo: FunctionComponent<IDisableWebsiteInfo> = ({ account }) => (
  <DisableWebsite>
    <InfoIconStyled />
    <Content>
      <Info>
        This private portal is available to members of <AccountTitle>{account}</AccountTitle> only.
      </Info>
      <Info>
        If you are a member or are part of <AccountTitle>{account}</AccountTitle> please click the
        Join account button to register for your personal account.
      </Info>
    </Content>
  </DisableWebsite>
);

const DisableWebsite = styled(Flex)`
  background: #f0f4fb;
  border-radius: 12px;
  color: #333333;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
`;

const Content = styled(Flex)`
  padding: 25px 45px 45px 45px;
  flex-direction: column;
  opacity: 0.8;
`;

const Info = styled.p`
  text-align: center;
  line-height: 24px;
  padding-bottom: 16px;
`;

const AccountTitle = styled.span`
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const InfoIconStyled = styled(InfoIcon)`
  background: #f0f4fb;
  width: 50px;
  height: 50px;
  padding: 15px;
  margin-top: -40px;
  border-radius: 50%;
`;

export default DisableWebsiteInfo;
