import Button from 'components/Button';
import Card, { Body } from 'components/Card';
import Flex from 'components/Flex';
import Modal from 'components/Modal';
import WysiwygContent from 'components/WysiwygContent';
import useNotice from 'hooks/useNotice';
import useToggle from 'hooks/useToggle';
import { MAX_SIDEBAR_NOTICE_LENGTH } from 'libs/constants';
import truncate from 'lodash.truncate';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const Sidebar = () => {
  const [modalIsOpen, toggleModal] = useToggle(false);
  const [showMore, setShowMore] = useState(false);

  const { getNotice } = useNotice();
  const notice = getNotice('sidebar');

  useEffect(() => {
    setShowMore(notice.length > MAX_SIDEBAR_NOTICE_LENGTH);
  }, [notice]);

  const showedNotice = useMemo(
    () =>
      showMore
        ? truncate(notice, {
            length: MAX_SIDEBAR_NOTICE_LENGTH,
            separator: /,? +/,
          })
        : notice,
    [showMore, notice]
  );

  return notice ? (
    <Wrapper>
      <Flex className="mb32">
        <Label>
          <WysiwygContent>
            <FlexNotice className="mt12" dangerouslySetInnerHTML={{ __html: showedNotice }} />
          </WysiwygContent>
          {showMore && (
            <Button size="xs" onClick={toggleModal}>
              read more
            </Button>
          )}
        </Label>
      </Flex>
      <Modal isOpen={modalIsOpen} close={toggleModal}>
        <Card>
          <Body>
            <WysiwygContent>
              <FlexNotice className="mt12" dangerouslySetInnerHTML={{ __html: notice }} />
            </WysiwygContent>
            <Flex className="mt24" justifyContent="center">
              <Button onClick={toggleModal}>Close</Button>
            </Flex>
          </Body>
        </Card>
      </Modal>
    </Wrapper>
  ) : null;
};

export default Sidebar;

const Wrapper = styled.div`
  border-top: 2px solid ${(props) => props.theme.colors.noticeGrey};
  padding-left: 30px;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.snow};
  white-space: pre-line;
  a {
    color: ${(props) => props.theme.colors.snow};
  }
`;

const FlexNotice = styled.div`
  width: 100%;
`;
