import React, { FunctionComponent } from 'react';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { MixedSchema } from 'yup';
import Flex from 'components/Flex';
import Button from 'components/Button';
import PhoneField from 'components/Form/PhoneInput/FormikField';
import SingleSelect from 'components/Form/Select/SingleSelect';
import Field from 'components/Form/Field';
import { timezones } from 'libs/constants';
import LocationField from 'components/Form/Location/FormikField';

export interface IFormValues {
  is_location_confirmed: boolean;
  time_zone: string;
  phone_number: string;
  name: string;
  location: {
    postcode: string;
    address: string;
    city: {
      title: string;
      id?: string;
    };
    region: {
      title: string;
      short_code: string;
    };
    country: {
      title: string;
      short_code: string;
    };
  };
}

export interface IProps {
  initialValues: IFormValues;
  validationSchema?: MixedSchema;
  onSubmit: (values: IFormValues, actions: FormikActions<IFormValues>) => void;
}

const Form: FunctionComponent<IProps> = ({ initialValues, validationSchema, onSubmit }: IProps) => (
  <Formik<IFormValues>
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    initialValues={initialValues}
  >
    {({ handleSubmit, values }) => (
      <form onSubmit={handleSubmit}>
        <Field
          name="location"
          component={LocationField}
          initValue={values.location?.address}
          className="mt8"
        />
        <Field
          name="time_zone"
          component={SingleSelect}
          label="Time zone"
          placeholder="Select time zone"
          className="mt8"
          options={timezones}
        />
        <PhoneField name="phone_number" label="Phone number" className="mt8" data-lpignore="true" />
        <Flex justifyContent="center" className="mt48">
          <Button type="submit">Confirm</Button>
        </Flex>
      </form>
    )}
  </Formik>
);

export default Form;
