import useApi from 'hooks/useApi';
import { requiredCheckbox, stringRequired } from 'libs/validations';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { YourContact } from 'pages/Payments/PaymentMethods/Cards';
import PaymentMethodLayout from 'pages/Payments/PaymentMethods/Layout';
import { IInvoice } from './ChoosePaymentType';
import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import useOnSubmit from 'hooks/useOnSubmit';
import { FormikHelpers, FormikProps } from 'formik';
import { CreditCard } from 'pages/Payments/PaymentMethods/Cards/CreditCard';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import OverlaySpinner from 'components/OverlaySpinner';
import { useParams } from 'react-router-dom';

export interface ICreditCardFormValues {
  is_authorized_to_payments: boolean;
  user_email: string;
  user_name: string;
  user_position: string;
  credit_card_number: string;
  credit_card_expiry: string;
  stripe_card_id?: string;
  stripe_source_id?: string;
}

const values: ICreditCardFormValues = {
  is_authorized_to_payments: false,
  user_email: '',
  user_name: '',
  user_position: '',
  credit_card_number: '',
  credit_card_expiry: '',
};

const validationSchema = Yup.object().shape({
  is_authorized_to_payments: requiredCheckbox,
  user_email: stringRequired,
  user_name: stringRequired,
  user_position: stringRequired,
});

const formCards = [
  {
    component: (props: FormikProps<ICreditCardFormValues>) => <CreditCard {...props} />,
    title: 'Credit Card Information',
  },
  {
    component: () => <YourContact />,
    title: 'Your contact information',
  },
];

const initialValues = {
  id: 0,
  guid: '',
  plan_name: '',
  total_price: 0,
  is_paid: false,
  is_processing: false,
  payment_types: {
    credit_card: false,
    debit_card: false,
  },
};

const CreditCardPage: FunctionComponent = () => {
  const { guid } = useParams();
  const { getApiPath } = useApi();
  const { resource: invoice, isLoading } = useFetchResource<IInvoice>({
    api: api.account.billing.invoices.getOne,
    initialParams: {
      guid,
    },
    initialValues,
  });
  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = useOnSubmit({
    api: getApiPath('billing.invoices.payment.credit', 'create'),
    deps: [guid],
    onSuccess: () => (window.location.href = '/dashboard'),
    params: { guid },
    serializer: (data: ICreditCardFormValues) => ({ resource: data }),
    successMessage: 'Payment successfully saved',
  });

  const submitHandler = useCallback(
    async (values: ICreditCardFormValues, form: FormikHelpers<ICreditCardFormValues>) => {
      form.setSubmitting(true);
      if (!stripe || !elements) {
        form.setSubmitting(false);
        return;
      }
      const cardNumber = elements.getElement('cardNumber');
      if (cardNumber !== null) {
        const result = await stripe.createToken(cardNumber);
        if (result.token) {
          onSubmit(
            { stripe_card_id: result.token.card?.id, stripe_source_id: result.token.id, ...values },
            form
          );
        } else {
          if (result.error.message) {
            if (result.error.code === 'incorrect_number') {
              form.setFieldError('credit_card_number', result.error.message);
            } else if (result.error.code === 'incorrect_expiry') {
              form.setFieldError('credit_card_expiry', result.error.message);
            }
          }
          form.setSubmitting(false);
        }
      }
    },
    [elements, stripe, onSubmit]
  );

  useEffect(() => {
    if (invoice?.is_paid || invoice?.is_processing) {
      window.location.href = `/invoices/${invoice.guid}/choose-payment-type`;
    }
  }, [invoice]);

  return (
    <>
      {isLoading ? (
        <OverlaySpinner visible={isLoading} />
      ) : (
        <PaymentMethodLayout<ICreditCardFormValues>
          plan={invoice.plan_name}
          price={invoice.total_price.toString()}
          invoiceID={invoice.id}
          initialValues={values}
          validationSchema={validationSchema}
          title="Credit Card"
          formCards={formCards}
          onSubmit={submitHandler}
        />
      )}
    </>
  );
};

export default CreditCardPage;
