import Button from 'components/Button';
import RemoveButtonRow from 'components/FieldArray/RemoveButtonRow';
import SingeSelect from 'components/Form/Select/SingleSelect';
import ConfirmModal from 'components/Modal/Confirm';
import Row from 'components/Row';
import { Field, FieldArray, FieldArrayRenderProps, getIn } from 'formik';
import { TBuildingOption } from 'hooks/common/useGetBuildings';
import useFilterOptions from 'hooks/useFilterOptions';
import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import useConfirmModal from './hooks';
import UnitsFieldArray from './UnitsFieldArray';

export interface IBuildingFieldArray {
  building_id: number | string;
  unit_numbers: string[];
}

interface IBuildings {
  buildings: TBuildingOption[];
  canRemoveFirst?: boolean;
  isDisabled?: boolean;
}

export function buildingsFieldArraySerializer<T>(
  values: T & { details_buildings: IBuildingFieldArray[] }
): { resource: T } {
  return {
    resource: {
      ...values,
      details_buildings: values.details_buildings.filter((item) => item.building_id),
    },
  };
}

export const BuildingsFieldArray: FunctionComponent<FieldArrayRenderProps & IBuildings> = ({
  buildings,
  handlePush,
  remove,
  form,
  name,
  isDisabled,
  canRemoveFirst = false,
}) => {
  const filterOptions = useFilterOptions(buildings, form.values, name, 'building_id');
  const getBuildingId = useCallback(
    (nameValue: number | string) => {
      const bld = buildings.find((item) => item.value === nameValue);
      return bld ? bld.id : false;
    },
    [buildings]
  );

  const { confirm, close, modalIsOpen, handleToggle } = useConfirmModal(remove, form.values);

  const canRemove = useCallback(
    (dataLength) => (canRemoveFirst ? true : dataLength > 1),
    [canRemoveFirst]
  );

  const handleChangeBuilding = useCallback(
    (index, unitsHelpers) => {
      return () => {
        form.values.details_buildings[index].unit_numbers.forEach((unit: string, i: number) => {
          unitsHelpers.remove(i);
        });
        unitsHelpers.push('');
      };
    },
    [form.values.details_buildings]
  );

  return (
    <>
      <ConfirmModal
        isOpen={modalIsOpen}
        close={close}
        question="Do you really want to delete this building?"
        confirm={confirm}
      />
      {getIn(form.values, name).map((building: IBuildingFieldArray, index: number) => (
        <div key={index}>
          <FieldArray name={`${name}.${index}.unit_numbers`}>
            {(unitsHelpers) => (
              <>
                <BuildingsRow>
                  <Field
                    name={`${name}.${index}.building_id`}
                    component={SingeSelect}
                    label="Building"
                    placeholder="Enter building"
                    className="mt8 mb4"
                    options={filterOptions(index)}
                    onChange={handleChangeBuilding(index, unitsHelpers)}
                    isDisabled={isDisabled}
                  />
                </BuildingsRow>
                {!isDisabled && (
                  <RemoveButtonRow>
                    {canRemove(getIn(form.values, [name, 'length'], 0)) && (
                      <Button
                        styleType="link"
                        onClick={handleToggle(index, `${name}.${index}.building_id`)}
                      >
                        - remove this building
                      </Button>
                    )}
                  </RemoveButtonRow>
                )}
                {building.building_id && (
                  <UnitsFieldArray
                    units={building.unit_numbers}
                    buildingId={getBuildingId(building.building_id)}
                    isDisabled={isDisabled}
                    {...unitsHelpers}
                  />
                )}
              </>
            )}
          </FieldArray>
        </div>
      ))}
      {!isDisabled && buildings.length > getIn(form.values, [name, 'length'], 0) && (
        <Button
          styleType="link"
          className="mt16"
          onClick={handlePush({ building_id: '', unit_numbers: [''] })}
        >
          + add another building
        </Button>
      )}
    </>
  );
};

export default BuildingsFieldArray;

const BuildingsRow = styled(Row)`
  &:first-child {
    margin-top: 32px;
  }
`;
