import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import Modal from 'components/Modal';
import Flex from 'components/Flex';
import { FieldArray, Formik, FormikProps } from 'formik';
import api from 'libs/api';
import useOnSubmit from 'hooks/useOnSubmit';
import { buildingFieldArrayRequired } from 'libs/validations';
import * as Yup from 'yup';
import BuildingsFieldArray, { IBuildingFieldArray } from 'templates/BuildingsFieldArray';
import { useGetBuildings } from 'hooks/common/useGetBuildings';
import Button from 'components/Button';
import useProfile from 'hooks/useProfile';

export interface IProps {
  isOpen: boolean;
}

interface IValues {
  details_buildings: IBuildingFieldArray[];
}

const UnitsModal: FunctionComponent<IProps> = ({ isOpen }: IProps) => {
  const { profile, setProfile } = useProfile();
  const onSubmit = useOnSubmit<IValues>({
    api: api.account.auth.profile.update,
    onSuccess: () => setProfile({ ...profile, has_units: true }),
    successMessage: 'Units updated successfully',
  });

  const Schema = Yup.object().shape({
    details_buildings: buildingFieldArrayRequired,
  });

  const initValues = {
    details_buildings: [{ building_id: '', unit_numbers: [] }],
  };

  const { buildings } = useGetBuildings();

  return (
    <Modal isOpen={isOpen}>
      <Modal.Basic title="Please, select or create your unit">
        <ModalBody>
          <Formik<IValues>
            initialValues={initValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={Schema}
          >
            {({ handleSubmit, isSubmitting }: FormikProps<IValues>) => (
              <form onSubmit={handleSubmit}>
                <FieldArray name="details_buildings">
                  {(helpers) => <BuildingsFieldArray buildings={buildings} {...helpers} />}
                </FieldArray>
                <Flex justifyContent="center" className="mt48">
                  <Button type="submit" disabled={isSubmitting}>
                    Confirm
                  </Button>
                </Flex>
              </form>
            )}
          </Formik>
        </ModalBody>
      </Modal.Basic>
    </Modal>
  );
};

const ModalBody = styled(Flex)`
  border-top: 2px solid ${(props) => props.theme.colors.lightGrey};
  padding: 30px 60px;
  flex-direction: column;
  ${(props) => props.theme.media.sm`
    padding: 10px 20px;
  `}
`;

export default UnitsModal;
