import Button from 'components/Button';
import CropImageModal from 'components/CropImageModal';
import Flex from 'components/Flex';
import Text from 'components/Text';
import { FieldProps, getIn } from 'formik';
import useToggle from 'hooks/useToggle';
import AvatarPlaceholder from 'images/avatar-placeholder.png';
import { FunctionComponent, MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import React from 'react';
import styled from 'styled-components';

interface IAvatar {
  buttonName?: string;
  showOptional?: boolean;
  inline?: boolean;
  deleteName?: string;
}

const Avatar: FunctionComponent<FieldProps & IAvatar> = ({
  field,
  form,
  buttonName = 'Upload',
  deleteName = 'Delete profile photo',
  showOptional = true,
  inline = false,
}) => {
  const [modalIsOpen, toggleModal] = useToggle(false);
  const fileInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const handleUploadImage = useCallback(() => fileInputRef.current!.click(), [fileInputRef]);
  const [image, setImage] = useState(AvatarPlaceholder);
  const handleNewImage = useCallback(({ target }) => {
    const { files } = target;
    if (files.length) {
      setImage(files[0]);
      toggleModal();
    }
  }, []);
  const handleRemoveAvatar = useCallback(() => {
    form.setFieldValue(`remove_${field.name}`, true);
    form.setFieldValue(field.name, null);
  }, []);

  const onCropAvatar = useCallback((blob) => {
    form.setFieldValue(field.name, blob, false);
    form.setFieldValue(`remove_${field.name}`, false);
  }, []);

  const AvatarImage = useMemo(() => {
    const imageFromServer = getIn(field, 'value.medium.url');
    if (imageFromServer) {
      return `${process.env.REACT_APP_IMAGE_PREFIX}${field.value.medium.url}`;
    }
    if (imageFromServer === null) {
      return AvatarPlaceholder;
    }
    return field.value ? URL.createObjectURL(field.value) : AvatarPlaceholder;
  }, [field.value]);

  const hasAvatar = useMemo(() => {
    const imageFromServer = getIn(field, 'value.medium.url');
    if (imageFromServer === null) {
      return false;
    }
    return field.value;
  }, [field]);

  return (
    <ImageFlex alignItems="center" direction="column" inline={inline}>
      <CropImageModal
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        preview={image}
        onCrop={onCropAvatar}
      />
      <ImageFlex alignItems="center" direction="column" inline={inline}>
        <Image src={AvatarImage} alt="user avatar" />
        <input
          ref={fileInputRef}
          name={field.name}
          type="file"
          onChange={handleNewImage}
          hidden={true}
          accept="image/jpeg,image/png"
        />
        <Button type="button" styleType="primary" className="mt16" onClick={handleUploadImage}>
          {buttonName}
        </Button>
        {showOptional && (
          <Text size="xs" className="mt8">
            * optional
          </Text>
        )}
        {hasAvatar && (
          <RemoveLink href="#" onClick={handleRemoveAvatar}>
            {deleteName}
          </RemoveLink>
        )}
      </ImageFlex>
    </ImageFlex>
  );
};

export default Avatar;

const ImageFlex = styled(Flex)<{ inline: boolean }>`
  margin-right: ${(props) => (props.inline ? 'initial' : '24px')};
  ${(props) => props.theme.media.xs`
    margin-right: 0;
  `}
`;

const Image = styled.img`
  width: 125px;
  height: 125px;
  border-radius: 50%;
`;

const RemoveLink = styled.a`
  color: ${(props) => props.theme.colors.warning};
`;
