import Flex from 'components/Flex';
import React, { FunctionComponent, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import Basic, { IBasic } from './Basic';

const modalRoot = document.getElementById('modal-root');

export interface IModal {
  className?: string;
  isOpen: boolean;
  close?: () => void;
  contentMaxWidth?: number;
}

const Modal: FunctionComponent<IModal> & { Basic: FunctionComponent<IBasic> } = ({
  className,
  children,
  isOpen,
  close,
  contentMaxWidth,
}) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return modalRoot && isOpen
    ? createPortal(
        <Container className={className}>
          <Overlay onClick={close} />
          <Content maxWidth={contentMaxWidth}>{children}</Content>
        </Container>,
        modalRoot
      )
    : null;
};

Modal.Basic = Basic;

export default Modal;

const Container = styled(Flex)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999999;
  overflow-y: auto;
  background: rgba(51, 51, 51, 0.2);
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Overlay = styled.div`
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

const Content = styled.div<{ maxWidth?: number }>`
  z-index: 1000000;
  max-width: ${(props) => props.maxWidth || 670}px;
  width: 100%;
  margin: auto;
`;
