import Button from 'components/Button';
import Flex from 'components/Flex';
import OverlaySpinner from 'components/OverlaySpinner';
import { FormikProps } from 'formik';
import { FormContainer } from 'pages/Profile/styled';
import React, { FunctionComponent } from 'react';
import Fields from './Fields';

export interface IEmailNotificationSettingsValues {
  daily_summary: boolean;
  unsubscribe_all_emails: boolean;
  email_notification_amenity: boolean;
  email_notification_documents: boolean;
  email_notification_classifieds: boolean;
  email_notification_message_forum: boolean;
  email_notification_news: boolean;
  email_notification_tasks: boolean;
  email_notification_completed_task: boolean;
  email_notification_events: boolean;
  email_notification_recurring_events: boolean;
  email_notification_unit_message: boolean;
}

const Form: FunctionComponent<FormikProps<IEmailNotificationSettingsValues>> = ({
  handleSubmit,
  isSubmitting,
  setFieldValue,
  values,
}) => {
  return (
    <FormContainer onSubmit={handleSubmit}>
      <OverlaySpinner visible={isSubmitting} />
      <Fields values={values} setFieldValue={setFieldValue} />
      <Flex justifyContent="center" className="mt48">
        <Button disabled={isSubmitting} type="submit">
          Save
        </Button>
      </Flex>
    </FormContainer>
  );
};

export default Form;
