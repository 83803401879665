import Flex from 'components/Flex';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import AdministrationCard, { IAdministrationPageCard } from './PageCard';

export interface IAdministrationCardsComponent {
  className?: string;
  pages: IAdministrationPageCard[];
}

const AdministrationCards: FunctionComponent<IAdministrationCardsComponent> = ({
  className,
  pages,
}) => {
  return (
    <Wrapper className={className}>
      {pages.map((item, index: number, dataTestLink) => (
        <AdministrationCard pageInfo={item} key={index} />
      ))}
    </Wrapper>
  );
};

export default AdministrationCards;

export const Wrapper = styled(Flex)`
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
  justify-content: flex-start;
  ${(props) => props.theme.media.sm`
    justify-content: center;  
  `}
`;
