import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface IScrollToButton {
  href?: string;
}

const ScrollToButton: FunctionComponent<IScrollToButton> = ({ href }) => {
  const [opacity, setOpacity] = useState(1);

  const onScroll = useCallback(() => {
    setOpacity(1 - document.documentElement.scrollTop / 100);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Button href={href} style={{ opacity }}>
      <ButtonTitle>Scroll down</ButtonTitle>
      <ArrowDown />
    </Button>
  );
};

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 90px;
  background: transparent;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #212121;
  outline: none;
  border: 0;
`;

const ButtonTitle = styled.span`
  margin-top: 10px;
`;

export default ScrollToButton;
