import Card from 'components/Card/index';
import Small from 'components/PageTitle/Small';
import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface IIndentView {
  title: string | ReactNode;
  className?: string;
  children: ReactNode;
}

const IndentView: FunctionComponent<IIndentView> = ({ title, children, className = '' }) => {
  return (
    <IndentCard className={className}>
      <Small>{title}</Small>
      {children}
    </IndentCard>
  );
};

export default IndentView;

export const IndentCard = styled(Card)`
  text-align: center;
  padding: 68px 64px;
  margin: 50px 0 30px;
  ${(props) => props.theme.media.xs`
    padding: 20px 15px;
  `}
`;

export const HighlightedSpan = styled.span`
  color: ${(props) => props.theme.colors.accentNormal};
`;
