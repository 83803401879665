import Input from 'components/Form/Input';
import { Field as FormikField, FieldAttributes } from 'formik';
import { capitalize } from 'libs/utils';
import React, { useMemo } from 'react';

function Field<T>({ name, component = Input, ...rest }: FieldAttributes<T> & { label?: string }) {
  const formattedName = useMemo(() => capitalize(name.split('_').join(' ')), [name]);

  return (
    <FormikField
      name={name}
      component={component}
      label={formattedName}
      placeholder={formattedName}
      data-test={name}
      {...rest}
    />
  );
}

export default Field;
