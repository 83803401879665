import ErrorView from 'components/ErrorView';
import useCommon from 'hooks/useCommon';
import { capitalize } from 'libs/utils';
import React, { FunctionComponent } from 'react';
import useNavigator from 'hooks/useNavigator';

const NotFound: FunctionComponent = () => {
  const { title } = useCommon();
  const { navigateCallback } = useNavigator();

  return (
    <ErrorView
      title={
        'The page you were looking for doesn’t exist.\nYou may have mistyped the address or the page may have moved.'
      }
      message="You can return to website!"
      actionTitle={capitalize(title)}
      onClick={navigateCallback('/')}
    />
  );
};

export default NotFound;
