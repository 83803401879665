import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { email as emailValidator } from 'libs/validations';
import get from 'lodash.get';

export interface IEmailInvoice {
  id?: number;
  email: string;
  _destroy?: boolean;
}

export default function useEmailInvoices<T>(
  fieldName: string,
  values: T,
  setFieldValue: FormikHelpers<T>['setFieldValue']
) {
  const invoiceEmails = useMemo(
    () => get(values, fieldName, []).filter((tag: IEmailInvoice) => !tag._destroy),
    [values, fieldName]
  );

  const handleChange = useCallback(
    (_tags: IEmailInvoice[], changedTags: IEmailInvoice[]) => {
      let newTags: IEmailInvoice[] = [...get(values, fieldName, [])];
      changedTags.forEach((tag: IEmailInvoice) => {
        const oldTag = newTags.find((t) => tag.email === t.email);
        if (oldTag) {
          if (oldTag.id) {
            oldTag._destroy ? delete oldTag._destroy : (oldTag._destroy = true);
          } else {
            newTags = newTags.filter((t) => t.email !== oldTag.email);
          }
        } else {
          newTags.push(tag);
        }
      });
      setFieldValue(fieldName, newTags);
    },
    [values, setFieldValue]
  );

  const validateEmail = useCallback(
    (tag: IEmailInvoice) => emailValidator.isValidSync(tag.email),
    []
  );

  return { handleChange, validateEmail, invoiceEmails };
}
