import Flex from 'components/Flex';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface INewsMeta {
  icon: ReactElement;
  text: string;
}

const NewsMeta = ({ icon, text }: INewsMeta) => (
  <NewsMetaContainer alignItems="center">
    {icon}
    <Text>{text}</Text>
  </NewsMetaContainer>
);

export default NewsMeta;

const Text = styled.span`
  color: ${(props) => props.theme.colors.lightenGrey};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 7px;
`;

const NewsMetaContainer = styled(Flex)`
  padding-bottom: 8px;
  padding-right: 18px;
  ${({ theme }) => theme.fillSvg(theme.colors.lightenGrey)};
`;
