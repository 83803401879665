import Flex from 'components/Flex';
import { MobileMenuOverlay } from 'pages/App/Header/Menu/styled';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import MobileMenuHandler from '../components/MobileMenuHandler';
import useToggleMenu from '../useToggleMenu';
import Footer from './Footer';
import { MobileMenuBody } from './styled';
import useWebsiteMenu from './useWebsiteMenu';

interface IAuthorizedMenu {
  className?: string;
}

const WebsiteMenuMobile: FunctionComponent<IAuthorizedMenu> = ({ className }) => {
  const { open, toggleOpen } = useToggleMenu();
  const { renderItems } = useWebsiteMenu({ toggle: toggleOpen });

  return (
    <div className={className}>
      <MobileMenuHandler onClick={toggleOpen} open={open} boundary="md" />
      <MobileMenuOverlayStyled close={!open} onClick={toggleOpen} boundary="md" />
      <MobileMenuBodyStyled boundary="md" close={!open}>
        <Flex justifyContent="space-between" direction="column">
          <div>{renderItems()}</div>
          <Footer />
        </Flex>
      </MobileMenuBodyStyled>
    </div>
  );
};

export default WebsiteMenuMobile;

const MobileMenuBodyStyled = styled(MobileMenuBody)`
  top: 100px;
  ${(props) => props.theme.media.sm`
    top: 94px;
    height: calc(100% - 94px);
  `};
  ${(props) => props.theme.media.xs`
    top: 90px;
    height: calc(100% - 90px);
  `};
`;

const MobileMenuOverlayStyled = styled(MobileMenuOverlay)`
  top: 100px;
  ${(props) => props.theme.media.sm`
    top: 94px;
  `};
  ${(props) => props.theme.media.xs`
    top: 90px;
  `};
`;
