import React, { FunctionComponent, useCallback, useState } from 'react';
import Input, { IInput } from '../Input';
import { FieldProps } from 'formik';
import { ReactComponent as OffIcon } from 'icons/eye-off.svg';
import { ReactComponent as OnIcon } from 'icons/eye-on.svg';
import styled from 'styled-components';

const Password: FunctionComponent<IInput & FieldProps> = (props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const visibleHandler = useCallback(() => {
    setIsVisible((state) => !state);
  }, []);

  const Icon = isVisible ? StyledOffIcon : StyledOnIcon;

  return (
    <Input
      {...props}
      type={isVisible ? 'text' : 'password'}
      rightIcon={<Icon onClick={visibleHandler} />}
    />
  );
};

export default Password;

const StyledOnIcon = styled(OnIcon)`
  cursor: pointer;
`;

const StyledOffIcon = styled(OffIcon)`
  cursor: pointer;
`;
