import { AxiosResponse } from 'axios';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Input from 'components/Form/Input';
import { FullWidthFormContainer } from 'components/Form/styled';
import { StyledTransparentErrorMessage } from 'components/Form/StyledErrorMessage';
import OverlaySpinner from 'components/OverlaySpinner';
import { Field, Formik, FormikHelpers as FormikActions } from 'formik';
import useAuth from 'hooks/useAuth';
import api from 'libs/api';
import { email } from 'libs/validations';
import SearchAssociationHeaderLink from 'pages/App/Header/ButtonLayouts/SearchAssociation';
import { Title } from 'pages/AppDomain/Pages/AccountsSearch/styled';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContent } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';

interface ICheckEmail {
  className?: string;
}

export interface ICheckEmailValues {
  email: string;
}

const ICheckEmailSchema = Yup.object().shape({
  email,
});

const CheckEmail: FunctionComponent<ICheckEmail> = () => {
  const { isAuthorized } = useAuth();
  const [redirectToUrl, setRedirectToUrl] = useState<string | null>(null);
  const [emailUrl, setEmailUrl] = useState<string | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const URLParams = new URLSearchParams(window.location.search);
    setRedirectToUrl(URLParams.get('redirect_to'));
    setEmailUrl(URLParams.get('email'));
  }, []);
  useEffect(() => {
    if (isAuthorized && redirectToUrl) {
      window.location.href = redirectToUrl;
    }
  }, [isAuthorized, redirectToUrl]);

  const onSubmit = useCallback(
    async (values: ICheckEmailValues, actions: FormikActions<ICheckEmailValues>) => {
      try {
        const response: AxiosResponse<{ user_exist: boolean }> = await api.auth.checkEmail({
          params: values,
        });
        const redirectPath = response.data.user_exist ? '/sign-in' : '/user-sign-up';
        navigate(`${redirectPath}?email=${values.email}&${window.location.search.slice(1)}`);
      } catch (error) {
        toast.error(error as ToastContent);
      } finally {
        actions.setSubmitting(false);
      }
    },
    []
  );

  return (
    <>
      <SearchAssociationHeaderLink />
      <Container direction="column" justifyContent="space-around">
        <Flex direction="column" alignItems="center">
          <Title>Please enter your email</Title>
          <Formik
            initialValues={{ email: emailUrl || '' }}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={ICheckEmailSchema}
          >
            {({ handleSubmit, isSubmitting }) => (
              <FullWidthFormContainer onSubmit={handleSubmit}>
                <OverlaySpinner visible={isSubmitting} />
                <Relative>
                  <Field
                    name="email"
                    component={StyledInput}
                    label=""
                    placeholder="Email"
                    errorMessageComponent={StyledTransparentErrorMessage}
                  />
                  <StyledButton type="submit">Continue</StyledButton>
                </Relative>
              </FullWidthFormContainer>
            )}
          </Formik>
        </Flex>
        <Flex>
          <Root>
            If you don't remember what email you used please contact our{' '}
            <Link to="/support">Support team</Link>
          </Root>
        </Flex>
      </Container>
    </>
  );
};

export default CheckEmail;

const Container = styled(Flex)`
  flex: 1;
  height: 100%;
  margin: auto;
`;

const Relative = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0;
  & input {
    color: ${(props) => props.theme.colors.dark};
    font-weight: 500;
    font-size: 16px;
    padding: 16px 150px 16px 32px;
    border: 'none';
    border-radius: ${(props) => props.theme.borderRadius};
    width: 100%;
    box-sizing: border-box;
    ${(props) => props.theme.media.xs`
      padding-right: 100px;
    `};
    outline: none;
  }
`;

export const StyledButton = styled(Button)`
  padding: 15px 36.5px;
  position: absolute;
  right: -2px;
  top: 0;
  ${(props) => props.theme.media.xs`
    padding: 15px 20px;
  `};
  outline: none;
`;

const Root = styled.p`
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  text-decoration: none;
  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.accentNormal};
  }
  ${(props) => props.theme.media.sm`
    font-size: 16px;
    line-height: 24px;
  `}
`;
