import NewsMeta from 'components/Website/Items/ItemMeta';
import { ReactComponent as UserIcon } from 'icons/small-user.svg';
import React from 'react';

interface INewsAuthor {
  text: string;
}

const NewsAuthor = ({ text }: INewsAuthor) => <NewsMeta icon={<UserIcon />} text={`by ${text}`} />;

export default NewsAuthor;
