import { getIn } from 'formik';
import useToggle from 'hooks/useToggle';
import { useCallback, useState } from 'react';

function useConfirmModal<T>(remove: (index: number) => void, values: T) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [modalIsOpen, toggleModal] = useToggle(false);

  const handleToggle = useCallback(
    (index, path) => () => {
      const value = getIn(values, path);
      const isFilled = Array.isArray(value) ? value[index] : value;
      if (isFilled) {
        setActiveIndex(index);
        toggleModal();
      } else {
        remove(index);
      }
    },
    [values]
  );

  const confirm = useCallback(() => {
    toggleModal();
    if (activeIndex !== null) {
      remove(activeIndex);
    }
  }, [activeIndex]);

  const close = useCallback(() => {
    toggleModal();
  }, []);

  return { modalIsOpen, handleToggle, confirm, close };
}

export default useConfirmModal;
