import { useCallback, useState } from 'react';

export interface IPreloadLink {
  href: string;
  as:
    | 'audio'
    | 'document'
    | 'embed'
    | 'fetch'
    | 'font'
    | 'image'
    | 'object'
    | 'script'
    | 'style'
    | 'track'
    | 'video'
    | 'worker';
  type?: string;
  media?: string;
}

export interface IPaginationMeta {
  preload_links?: IPreloadLink[];
  count_profiles?: number;
  views_count?: number;
  email_views_count?: number;
  sent_mails_count?: number;
  page: number;
  per_page: number;
  total_pages: number;
}

export interface IResponseMeta {
  total_pages: number;
  page: string;
  per_page: string;
}

type onSuccessType = (params: IResponseMeta) => void;

export interface LoadResourcesParams {
  onSuccess: onSuccessType;
  meta: IPaginationMeta;
  // eslint-disable-next-line @typescript-eslint/ban-types
  params?: object;
}

export type loadResourcesType = (params: LoadResourcesParams) => Promise<any>;

export interface IPagination {
  loadResources: loadResourcesType;
  onLoadedMore?: (meta: IPaginationMeta) => void;
  initialMetaValues?: IPaginationMeta;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type loadMoreType = (params?: object) => void;

export const initialMeta = { page: 1, per_page: 20, total_pages: 0 };

export default function usePagination({
  loadResources,
  onLoadedMore,
  initialMetaValues = initialMeta,
}: IPagination): {
  meta: IPaginationMeta;
  loadMore: loadMoreType;
  hasMore: boolean;
  onSuccess: onSuccessType;
} {
  const [meta, setMeta] = useState(initialMetaValues);
  const onSuccess = useCallback(
    ({
      page,
      per_page,
      total_pages,
      count_profiles,
      preload_links,
      views_count,
      email_views_count,
      sent_mails_count,
    }) =>
      setMeta({
        page: Number(page),
        per_page: Number(per_page),
        total_pages,
        count_profiles,
        preload_links,
        views_count,
        email_views_count,
        sent_mails_count,
      }),
    []
  );

  const hasMore = meta.total_pages > meta.page;

  const loadMore = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (params?: object) => {
      if (hasMore) {
        const metaParams = {
          ...meta,
          page: (meta.page * meta.per_page) / initialMeta.per_page + 1,
          per_page: initialMeta.per_page,
        };
        onLoadedMore && onLoadedMore(metaParams);
        loadResources({ onSuccess, meta: metaParams, params });
      }
    },
    [meta]
  );

  return { meta, loadMore, hasMore, onSuccess };
}
