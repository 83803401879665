import styled from 'styled-components/macro';

const Label = styled.p`
  padding-left: 22px;
  margin: 0 0 8px;
  line-height: 24px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.dark};
  text-align: left;
`;

export default Label;
