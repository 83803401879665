import { NavigateOptions, useNavigate } from 'react-router-dom';

export default function useNavigator() {
  const navigate = useNavigate();

  const navigateCallback = (path: string, options?: NavigateOptions) => () => navigate(path);

  const backCallback = () => navigate(-1);

  return {
    navigateCallback,
    backCallback,
  };
}
