import Flex from 'components/Flex';
import styled from 'styled-components';

const Actions = styled(Flex)<{ justifyContent?: string; full?: boolean }>`
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  ${(props) => props.theme.media.sm`
    justify-content: center;
  `}
  ${(props) =>
    props.full &&
    `
    margin-left: -8px;
    margin-right: -8px;
    & > button {
      flex: 1;
      margin-right: 8px;
      margin-left: 8px;
    }
  `}
`;

export default Actions;
