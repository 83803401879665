import Flex from 'components/Flex';
import React, { FunctionComponent, MutableRefObject } from 'react';
import fadeIn from 'react-animations/lib/fade-in';
import styled, { keyframes, StyledComponent } from 'styled-components';
import { IMenuItem } from '../UserMenu/useUserMenu';

interface IHeaderNavMenuTemplate {
  className?: string;
  nodeRef: MutableRefObject<HTMLDivElement | null>;
  items: IMenuItem[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  layout?: StyledComponent<'div', {}>;
}

const NavMenuTemplate: FunctionComponent<IHeaderNavMenuTemplate> = ({
  className,
  nodeRef,
  items,
  layout: Layout = NavMenuLayout,
}) => {
  return (
    <Layout className={className} ref={nodeRef}>
      {items
        .filter((item) => item.visible)
        .map((item, index) => (
          <MenuItem
            key={index}
            alignItems="center"
            onClick={item.onClick}
            border={item.border}
            data-test={`user-menu-${item.dataTest}`}
          >
            <ItemIcon justifyContent="center">{item.icon}</ItemIcon>
            <ItemTitle>{item.title}</ItemTitle>
          </MenuItem>
        ))}
    </Layout>
  );
};

export default NavMenuTemplate;

const fadeInAnimation = keyframes`${fadeIn}`;

/**
 * HeaderNavMenu open block layout
 */
export const NavMenuLayout = styled.div`
  z-index: 200;
  border-radius: 4px;
  position: absolute;
  background: ${(props) => props.theme.colors.snow};
  animation: 0.3s ${fadeInAnimation};
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.06);
  top: 100%;
  width: 250px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-sizing: border-box;
  margin-top: 16px;
  ${(props) => props.theme.media.xs`
    right: -25px;
  `};
`;

const MenuItem = styled(Flex)<{ onClick: () => void; border?: string }>`
  cursor: pointer;
  padding: 15px 20px;
  color: ${(props) => props.theme.colors.darkGrey};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.colors.optionGrey};
  }
  ${(props) =>
    props.border ? `border-${props.border} 2px solid ${props.theme.colors.lightGrey}` : ''}
`;

const ItemIcon = styled(Flex)`
  width: 20px;
  ${({ theme }) => theme.fillSvg(theme.colors.accentNormal)};
`;

const ItemTitle = styled.span`
  margin-left: 16px;
`;
