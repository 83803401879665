import React, { FunctionComponent, useState, useCallback, useMemo } from 'react';
import api from 'libs/api';
import debounce from 'lodash.debounce';
import Autocomplete from 'components/Autocomplete';
import { IOption } from '../Select/SingleSelect';

export interface IPlace {
  address: string;
  place: string;
  postcode: string;
  city: { id: string; title: string };
  country: { id: string; title: string };
  region: { id: string; title: string };
}

export interface IProps {
  onSelect: (item?: IPlace) => void;
  onChange?: (value?: string) => void;
  initValue?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
}

const Location: FunctionComponent<IProps> = ({
  initValue,
  onSelect,
  onChange,
  disabled,
  placeholder = 'Search your address...',
  error = false,
}) => {
  const [places, setPlaces] = useState<IPlace[]>([]);
  const [currentPlace, setCurrentPlace] = useState<IPlace | undefined>(undefined);

  const searchPlaces = useCallback(
    async (query: string) => {
      const {
        data: { resources },
      } = await api.places.search({ params: { q: query } });
      setPlaces(resources);
    },
    [setPlaces]
  );

  const debouncedOnChange = useCallback(
    debounce(async (value?: string) => {
      value && searchPlaces(value);
      onChange && onChange(value);
    }, 300),
    [searchPlaces, onChange]
  );

  const suggestions: IOption[] = useMemo(
    () => places.map((place: IPlace) => ({ label: place.place, value: place.place })),
    [places]
  );

  const onSelectHandler = useCallback(
    async (item?: IOption) => {
      let place: IPlace | undefined = undefined;
      if (item) {
        let places: IPlace[] = [];
        await setPlaces((prevPlaces) => {
          places = prevPlaces;
          return prevPlaces;
        });
        place = places.find((pl: IPlace) => pl.place === item.value);
      }
      setCurrentPlace(place);
      onSelect(place);
    },
    [onSelect]
  );

  return (
    <Autocomplete
      error={error}
      initialValue={currentPlace?.address || initValue}
      isNeedButton={false}
      suggestions={suggestions}
      inputProps={{
        placeholder,
        disabled,
      }}
      onChange={debouncedOnChange}
      onSelect={onSelectHandler}
    />
  );
};

export default Location;
