import { ReactNode } from 'react';
import styled from 'styled-components';

interface ITransparentButton {
  className?: string;
  children: ReactNode;
  onClick: () => void;
}

const TransparentButton = styled('button')<ITransparentButton>`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  a.button {
    text-decoration: none;
  }
  align-items: center;
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
  transition: all 0.3s;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
`;

export default TransparentButton;
