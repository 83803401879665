import Flex from 'components/Flex';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { ReactComponent as BackIcon } from 'icons/faq.svg';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'libs/constants';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import HeaderLinkTemplate, { IconContainer } from '../HeaderLinkTemplate';

interface IQuestionHeaderLink {
  className?: string;
}

const QuestionHeaderLink: FunctionComponent<IQuestionHeaderLink> = ({ className = '' }) => {
  const theme = useTheme();

  return (
    <HeaderLinkTemplate
      boundary="md"
      className={className}
      desktopView={
        <Flex direction="column">
          <Text color={theme.colors.dark} align="right">
            Questions? Give us a call at <a href={`tel:+${SUPPORT_PHONE}`}>{SUPPORT_PHONE}</a>
          </Text>
          <Text color={theme.colors.dark} align="right">
            or e-mail <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </Text>
        </Flex>
      }
      mobileView={
        <Link to="/support">
          <IconContainer>
            <BackIcon />
          </IconContainer>
        </Link>
      }
    />
  );
};

export default QuestionHeaderLink;
