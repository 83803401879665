import { IRenderRoute } from 'hooks/useRoutes';
import { lazy } from 'react';

const companyRoutes: IRenderRoute[] = [
  {
    component: lazy(() => import('pages/Company/Auth/CompanySignUp')),
    path: '/company-sign-up',
    title: 'User Details',
    type: 'read',
  },
  {
    component: lazy(() => import('pages/Company/Auth/WaitingUserApprove')),
    path: '/company-sign-up-finish',
    title: 'User Details',
    type: 'read',
  },
];

export default companyRoutes;
