import { ICommentTimeSpent } from 'components/Comments';
import { addHours, addMinutes, format, isBefore, isEqual, parseISO, startOfDay } from 'date-fns';
import { convertToTimeZone, formatToTimeZone } from 'date-fns-timezone';
import { getIn } from 'formik';
import { ITotalTimeSpent } from 'hooks/common/useAssignment';
import {
  COMMON_DATE_FORMAT,
  DEFAULT_TIMEZONE,
  PRIMARY_TIME_FORMAT,
  timezones,
  UTC_TIMEZONE,
} from 'libs/constants';
import cookies from 'libs/cookie';
import isEmpty from 'lodash.isempty';

export type TDate = Date | string;

export const zeroMinute = (minute: number) => (minute < 10 ? '0' : '');

export const formatDate = (date: TDate, dateFormat: string = COMMON_DATE_FORMAT) => {
  if (typeof date === 'string') {
    return date === '' ? '' : format(parseISO(date), dateFormat);
  }
  return format(new Date(date), dateFormat);
};

export const formatTime = (date: TDate) => formatDate(date, PRIMARY_TIME_FORMAT);

export const formatTimeSpent = (time: ICommentTimeSpent): string => {
  const roundMinutes: number[] = [0, 60];
  if (roundMinutes.includes(time.minutes)) {
    const result = time.minutes === 0 ? time.hours : time.hours + 1;
    return result.toString();
  }
  return `${time.hours}:${zeroMinute(time.minutes)}${time.minutes}`;
};

export const formatTotalTimeSpent = (time: ITotalTimeSpent | string | number): string => {
  if (isEmpty(time)) {
    return '0:00';
  } else if (typeof time !== 'string' && typeof time !== 'number') {
    return `${time.total_hours}${
      Number(time.total_minutes)
        ? `:${time.total_minutes && zeroMinute(time.total_minutes)}${time.total_minutes}`
        : ':00'
    }`;
  }
  return time.toString();
};

export const addTimeToDate = (date: TDate, timeString: string): Date => {
  const [timeHours, timeMinutes] = timeString.split(':');
  if (timeHours && timeMinutes) {
    return addMinutes(addHours(startOfDay(new Date(date)), Number(timeHours)), Number(timeMinutes));
  }
  return new Date(date);
};

export const getAccountTZ = () => {
  const timezone = cookies.get('timezone');
  const timezonesValues = timezones.map((item) => item.value);

  if (timezone && timezonesValues.includes(timezone)) {
    return timezone;
  }

  return DEFAULT_TIMEZONE;
};

/**
 * Format date with adding needle timezone (ex: (from Berlin -> New York) before - 15:00 GMT+1, after - 15:00 GMT-5)
 */
export const addTZtoDate = (date: TDate, timeZone: string) =>
  format(new Date(date), 'EEE LLL dd yyyy HH:mm:ss') +
  ' ' +
  formatToTimeZone(new Date(date), '[GMT]ZZ', { timeZone });
// export const addUTCtoDate = (date: TDate) => addTZtoDate(date, UTC_TIMEZONE);
// export const addAccountTZtoDate = (date: TDate) => addTZtoDate(date, getAccountTZ());

/**
 * Convert date to needle timezone (ex: (from Berlin -> New York) before - 15:00 in Berlin, after - 09:00 in New York)
 */
export const formatToTZ = (date: TDate, timeZone: string) => convertToTimeZone(date, { timeZone });
export const formatToUTC = (date: TDate) => convertToTimeZone(date, { timeZone: UTC_TIMEZONE });
export const formatToAccountTZ = (date: TDate) => formatToTZ(date, getAccountTZ());

// Use it for display date
export const dateViewFormatter = (date: TDate, dateFormat: string = COMMON_DATE_FORMAT) =>
  formatDate(formatToAccountTZ(date), dateFormat);

/**
 * Convert browser datetime to neeedle timeZone(default: UTC)
 */
export const concatTZDateTimeFromObject = <T>(
  values: T,
  datePath: Array<keyof T>,
  timePath: Array<keyof T>,
  timeZone: string = UTC_TIMEZONE
): Date =>
  new Date(
    addTZtoDate(
      addTimeToDate(getIn(values, datePath as string[]), getIn(values, timePath as string[])),
      timeZone
    )
  );

export const convertAmenityTime = (time: string): TDate => addTimeToDate(new Date(), time);

export const convertSecondsToMinutes = (seconds: number): string => {
  const SECONDS_IN_MINUTE = 60;
  const minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
  const rest_seconds = seconds - minutes * SECONDS_IN_MINUTE;
  return `${zeroMinute(minutes)}${minutes}:${zeroMinute(rest_seconds)}${rest_seconds}`;
};

export const getWeeklyDay = (date: Date): string =>
  new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);

export const eachHour = (startDate: Date, endDate: Date): Date[] => {
  const range: Date[] = [];

  for (
    startDate;
    isBefore(startDate, endDate) || isEqual(startDate, endDate);
    startDate = addHours(startDate, 1)
  ) {
    range.push(startDate);
  }
  return range;
};
