import { TNoticeType } from 'domain/notice/types';
import useNotice from 'hooks/useNotice';
import React, { FunctionComponent } from 'react';
import WysiwygContent from 'components/WysiwygContent';
import styled from 'styled-components';

interface INotice {
  path: TNoticeType;
}

const CommonNotice: FunctionComponent<INotice> = ({ path }) => {
  const { getNotice } = useNotice();
  const notice = getNotice(path);

  return (
    notice && (
      <WysiwygContent>
        <Notice dangerouslySetInnerHTML={{ __html: notice }} />
      </WysiwygContent>
    )
  );
};

export default CommonNotice;

export const Notice = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.noticeBackground};
  padding: 25px 50px;
  color: ${(props) => props.theme.colors.noticeText};
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
`;
