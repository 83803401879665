import { Card, SubTitle, Title } from 'components/ImageLayout';
import { Subtitle as PageSubtitle } from 'components/PageTitle/Subtitle';
import { Formik } from 'formik';
import useOnSubmit from 'hooks/useOnSubmit';
import api from 'libs/api';
import { DEFAULT_PLAN, PROJECT_TITLE } from 'libs/constants';
import { buildFullUrl } from 'libs/utils';
import { stringRequired } from 'libs/validations';
import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Form from './Form';

interface IAccountSignUp {
  className?: string;
}

export interface IAccountSignUpValues {
  name: string;
  promo: string;
  subdomain: string;
  plan: string;
}

const initialValues = {
  name: '',
  plan: '',
  promo: '',
  subdomain: '',
};

const AccountSignUpSchema = Yup.object().shape({
  name: stringRequired,
  subdomain: stringRequired,
});

const AccountSignUpStart: FunctionComponent<IAccountSignUp> = ({ className }) => {
  useEffect(() => {
    const URLParams = new URLSearchParams(window.location.search);
    const plan = URLParams.get('plan');
    initialValues.plan = plan ? plan : DEFAULT_PLAN;
  }, []);

  const onSubmit = useOnSubmit<IAccountSignUpValues>({
    api: api.account.auth.signUp.start,
    onSuccess: ({ data }) =>
      buildFullUrl(data.resource.account.subdomain, '/account-sign-up-finish')(),
    successMessage: 'Subdomain was created',
  });

  return (
    <>
      <Title>Create Your Account</Title>
      <SubTitle>
        Not a resident, owner, or member at {PROJECT_TITLE}?{' '}
        <Link to="/search">Click here to find your association</Link>
      </SubTitle>
      <Card className={className}>
        <PageSubtitle className="mt4">Property Information</PageSubtitle>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={AccountSignUpSchema}
          enableReinitialize={true}
          component={Form}
        />
      </Card>
    </>
  );
};

export default AccountSignUpStart;
