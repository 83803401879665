import ErrorView from 'components/ErrorView';
import { getIn } from 'formik';
import useCommon from 'hooks/useCommon';
import { COMMON_TYPE_ACCOUNT, COMMON_TYPE_COMPANY } from 'libs/constants';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import Header from '../../Header';

const CommonDeleted: FunctionComponent = () => {
  const { type } = useCommon();

  const info = {
    [COMMON_TYPE_ACCOUNT]: {
      message: (
        <span>
          If you didn't want to delete your Account, please contact the CondoGenie support team{' '}
          <Anchor href="mailto:support@condogenie.com">support@condogenie.com</Anchor>
        </span>
      ),
      title: 'Your account has been deleted.',
    },
    [COMMON_TYPE_COMPANY]: {
      message: (
        <span>
          If you didn't want to delete your Company, please contact the CondoGenie support team{' '}
          <Anchor href="mailto:support@condogenie.com">support@condogenie.com</Anchor>
        </span>
      ),
      title: 'Your company has been deleted.',
    },
  };

  return (
    <div>
      <Header />
      <ErrorView
        title={getIn(info, [type, 'title'], '')}
        message={getIn(info, [type, 'message'], '')}
      />
    </div>
  );
};

const Anchor = styled.a`
  color: ${(props) => props.theme.colors.snow};
`;

export default CommonDeleted;
