import { useCallback, useState } from 'react';

export default function useGallery() {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openGallery = useCallback((index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeGallery = useCallback(() => {
    setViewerIsOpen(false);
    setCurrentImage(0);
  }, []);

  return { currentImage, viewerIsOpen, openGallery, closeGallery };
}
