import { useCallback } from 'react';
import { buildFullUrl } from 'libs/utils';
import useAuth from 'hooks/useAuth';
import useCommon from 'hooks/useCommon';
import { useNavigate } from 'react-router-dom';

export default function useJoinTo() {
  const { isAuthorized } = useAuth();
  const { isCommonTypeCompany } = useCommon();
  const navigate = useNavigate();

  const onJoinHandler = useCallback(() => {
    const signUpPath = isCommonTypeCompany ? '/company-sign-up' : '/condo-sign-up';
    if (isAuthorized) {
      navigate(signUpPath);
    } else {
      buildFullUrl(
        process.env.REACT_APP_APP_DOMAIN!,
        `/check-email?redirect_to=${window.location.origin}${signUpPath}`
      )();
    }
  }, [isAuthorized, isCommonTypeCompany]);

  return { onJoinHandler };
}
