import useOutsidePopupToggle from 'hooks/useOutsidePopupToggle';
import useProfile from 'hooks/useProfile';
import { ReactComponent as AddClassified } from 'icons/add-menu/classified.svg';
import { ReactComponent as AddDocument } from 'icons/add-menu/document.svg';
import { ReactComponent as AddForumMessage } from 'icons/add-menu/forum_message.svg';
import { ReactComponent as InviteUsers } from 'icons/add-menu/invite_users.svg';
import { ReactComponent as AddNewsAnnouncement } from 'icons/add-menu/news_announcement.svg';
import { ReactComponent as AddProject } from 'icons/add-menu/project.svg';
import { ReactComponent as AddTask } from 'icons/add-menu/task.svg';
import { IMenuItem } from 'pages/App/Header/Menu/UserMenu/useUserMenu';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const useAddButtonMenu = () => {
  const { hasAccess } = useProfile();
  const { toggle, isMenuOpen, nodeRef } = useOutsidePopupToggle<HTMLDivElement>();
  const navigate = useNavigate();

  const handle = useCallback(
    (link: string) => () => {
      toggle();
      navigate(link);
    },
    [toggle]
  );

  const items = useMemo(
    (): IMenuItem[] => [
      {
        border: 'bottom',
        icon: <InviteUsers />,
        onClick: handle('/administration/users/invite'),
        permission: 'users_accounts',
        title: 'Invite users',
        visible: true,
      },
      {
        icon: <AddClassified />,
        onClick: handle('/classifieds/create'),
        permission: 'classifieds',
        title: 'Add a classified',
        visible: true,
      },
      {
        icon: <AddForumMessage />,
        onClick: handle('/forum/messages/create'),
        permission: 'message_forum',
        title: 'Add a forum message',
        visible: true,
      },
      {
        icon: <AddNewsAnnouncement />,
        onClick: handle('/news/create'),
        permission: 'news_announcements',
        title: 'Add a news announcement',
        visible: true,
      },
      {
        icon: <AddDocument />,
        onClick: handle('/documents/create'),
        permission: 'documents',
        title: 'Add a document',
        visible: true,
      },
      {
        icon: <AddTask />,
        onClick: handle('/tasks/create'),
        permission: 'tasks',
        title: 'Add a task',
        visible: true,
      },
      {
        icon: <AddProject />,
        onClick: handle('/administration/projects/create'),
        permission: 'projects',
        title: 'Add a project',
        visible: true,
      },
    ],
    [handle, isMenuOpen]
  );

  const menuItems = useMemo(
    () =>
      items.filter((route) => (route.permission ? hasAccess(route.permission, 'create') : true)),
    [items, hasAccess]
  );

  return { toggle, isMenuOpen, nodeRef, menuItems };
};

export default useAddButtonMenu;
