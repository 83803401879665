import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import { useMemo } from 'react';

interface ICommonPickerItem {
  id: number;
  subdomain: string;
  name: string;
}

interface IResource {
  accounts: ICommonPickerItem[];
  companies: ICommonPickerItem[];
}

interface IGroupItems {
  name: string;
  items: ICommonPickerItem[];
}

interface IUseCommonPicker {
  items: IGroupItems[];
}

const useCommonPicker = (): IUseCommonPicker => {
  const { resource: rawItems } = useFetchResource<IResource>({
    api: api.account.users.statistic.get,
    initialValues: {
      accounts: [],
      companies: [],
    },
    serializer: (data) => data.resources,
  });

  const items: IGroupItems[] = useMemo(
    () => [
      {
        items: rawItems.accounts,
        name: 'CONDOMINIUMS',
      },
      {
        items: rawItems.companies,
        name: 'COMPANIES',
      },
    ],
    [rawItems]
  );

  return { items };
};

export default useCommonPicker;
