import useApi from 'hooks/useApi';
import useFetchResource from 'hooks/useFetchResource';
import { TResponceItemSerializer } from 'libs/http';
import { useEffect } from 'react';

interface IUseProfileInformation<T> {
  id?: string;
  initialValues: T;
  serializer?: TResponceItemSerializer<T>;
}

interface IReturnedValues<T> {
  profileInformation: T;
  fetchProfileInformation: () => void;
}

export function useProfileInformation<T>({
  id,
  initialValues,
  serializer,
}: IUseProfileInformation<T>): IReturnedValues<T> {
  const { getApiPath } = useApi();

  const { resource: profileInformation, fetchResource } = useFetchResource<T>({
    api: getApiPath('profile.tabs.info', 'getOne'),
    initialLoad: false,
    initialParams: { id },
    initialValues,
    serializer,
  });

  const fetchProfileInformation = () => {
    if (id) {
      fetchResource({ id });
    }
  };

  useEffect(() => {
    fetchProfileInformation();
  }, [id]);

  return { profileInformation, fetchProfileInformation };
}
