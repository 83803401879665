import Attachments from 'components/Attachments';
import BackButton from 'components/BackButton/ThemedBackButton';
import Flex from 'components/Flex';
import Text from 'components/Text';
import ContentItem from 'components/Website/ContentItem';
import HorizontalLine from 'components/Website/HorizontalLine';
import NewsAuthor from 'components/Website/Items/ItemAuthor';
import NewsBody from 'components/Website/Items/ItemBody';
import PublishDate from 'components/Website/Items/PublishDate';
import PageTitle from 'components/Website/PageTitle';
import SiteContent from 'components/Website/SiteContent';
import WysiwygContent from 'components/WysiwygContent';
import useFetchResource from 'hooks/useFetchResource';
import useTheme from 'hooks/useTheme';
import api from 'libs/api';
import firstLetter from 'libs/firstLetter';
import { IClassified } from 'pages/Classifieds/List';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const ClassifiedSpecific: FunctionComponent = () => {
  const { id } = useParams();
  const theme = useTheme();
  const { resource: classified } = useFetchResource<IClassified | undefined>({
    api: api.website.classifieds.getOne,
    initialParams: {
      id,
    },
    initialValues: undefined,
  });

  return (
    <SiteContent>
      <SpecificBackButtonContainer>
        <BackButton direction="left" />
      </SpecificBackButtonContainer>
      {classified && (
        <>
          <SpecificContentItem>
            <SpecificPageTitle>{classified.title}</SpecificPageTitle>
          </SpecificContentItem>
          <SpecificContentItem>
            <Flex>
              <PublishDate date={classified.created_at} />
              <NewsAuthor
                text={`${classified.author.first_name} ${firstLetter(classified.author.last_name)}`}
              />
            </Flex>
          </SpecificContentItem>
          <ContentItem>
            <HorizontalLine />
          </ContentItem>
          <ContentItem>
            <WysiwygContent>
              <NewsBody dangerouslySetInnerHTML={{ __html: classified.description ?? '' }} />
            </WysiwygContent>
          </ContentItem>
          {(classified.files?.length ?? 0) > 0 && (
            <ContentItem>
              <LabelText size="xs" weight="bold" color={theme.colors.dark}>
                Attachments
              </LabelText>
              <Attachments files={classified.files ?? []} />
            </ContentItem>
          )}
        </>
      )}
    </SiteContent>
  );
};

const SpecificPageTitle = styled(PageTitle)`
  padding-top: 10px;
`;

const SpecificContentItem = styled(ContentItem)`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const LabelText = styled(Text)`
  margin-bottom: 12px;
  ${(props) => props.theme.media.sm`
    margin-bottom: 0;
  `}
`;

const SpecificBackButtonContainer = styled(ContentItem)`
  padding: 60px 50px 0 50px;
`;

export default ClassifiedSpecific;
