import Flex from 'components/Flex';
import IconWrapper from 'components/IconWrapper';
import Text from 'components/Text';
import { TAllPermissions, TPermissionType } from 'hooks/usePermission';
import useTheme from 'hooks/useTheme';
import { ADMINISTRATION_ICON_SIZE } from 'libs/constants';
import React, { FunctionComponent, SVGProps } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TIconFillingRule } from 'theme';

export interface IAdministrationPageCard {
  description: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  link: string;
  permission?: TAllPermissions;
  permissionTypes?: TPermissionType[];
  title: string;
  iconFillingRule?: TIconFillingRule;
  dataTestLink?: string;
}

export interface IAdministrationCardComponent {
  className?: string;
  pageInfo: IAdministrationPageCard;
}

const AdministrationCard: FunctionComponent<IAdministrationCardComponent> = ({
  className,
  pageInfo,
}) => {
  const theme = useTheme();
  const { icon: Icon, title, description, link, iconFillingRule, dataTestLink } = pageInfo;

  return (
    <Link to={link} data-test={dataTestLink}>
      <PageCard className={className} direction="column" alignItems="center">
        <IconWrapper iconFillingRule={iconFillingRule}>
          <Icon height={ADMINISTRATION_ICON_SIZE} />
        </IconWrapper>
        <Title className="mt24">{title}</Title>
        <TextBlock className="mt8">
          <Text color={theme.colors.inputText} align="center">
            {description}
          </Text>
        </TextBlock>
      </PageCard>
    </Link>
  );
};

export default AdministrationCard;

export const PageCard = styled(Flex)`
  min-width: 350px;
  flex: 0 0 30%;
  max-height: 320px;
  height: 320px;
  background: white;
  padding-top: 69px;
  padding-bottom: 37px;
  box-sizing: border-box;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 32px;
  ${(props) => props.theme.media.xs`
    min-width: 300px;
  `}
`;

const Title = styled.p`
  font-family: Gotham Pro Bold;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #333333;
`;

export const TextBlock = styled.div`
  width: 280px;
`;
