import TransparentButton from 'components/Button/TransparentButton';
import Flex from 'components/Flex';
import useAuth from 'hooks/useAuth';
import useCommon from 'hooks/useCommon';
import { ReactComponent as Logo } from 'icons/logo.svg';
import { PROJECT_TITLE } from 'libs/constants';
import { buildFullUrl } from 'libs/utils';
import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import { Tunnel, TunnelPlaceholder } from 'react-tunnels';
import useWebsite from 'hooks/useWebsite';
import styled from 'styled-components/macro';
import AccountPicker from './Menu/CommonPicker';
import UserMenu from './Menu/UserMenu';
import { Link } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from 'icons/user-menu/profile.svg';

interface HeaderType {
  className?: string;
}

interface IReactTunnelItems {
  children: ReactElement[];
}

interface IReactTunnel {
  items: IReactTunnelItems[];
}

const headerRightTunnelId = 'header-right-component';
const headerLeftTunnelId = 'header-left-component';

const Header: FunctionComponent<HeaderType> = ({ className = '' }) => {
  const { isAuthorized } = useAuth();
  const { title, type } = useCommon();
  const { isWebsite } = useWebsite();
  const isHideUserMenu =
    !isAuthorized && (window.location.pathname === '/search' || window.location.pathname === '/');

  const renderTitle = useMemo(
    () => (
      <>
        <SiteName>{title || PROJECT_TITLE}</SiteName>
        {!isWebsite && (
          <SiteDescription>{type ? 'CondoGenie - ' : ''}Condo Success System</SiteDescription>
        )}
      </>
    ),
    [title, isWebsite, type, PROJECT_TITLE]
  );

  return (
    <Flex className={`${className} header-component`} direction="column" justifyContent="center">
      <MobileTitleWrapper className="visible-md">{renderTitle}</MobileTitleWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <SiteNameWrapper alignItems="center">
          <LogoButton
            data-test="logo"
            onClick={buildFullUrl(process.env.REACT_APP_APP_DOMAIN!, '/')}
          >
            {!isWebsite && <Logo />}
          </LogoButton>
          <TitleWrapper className="hidden-xs">
            <TitleWrapper justifyContent="center" direction="column" className="hidden-md">
              {renderTitle}
            </TitleWrapper>
            {isAuthorized && <AccountPicker />}
          </TitleWrapper>
          <TunnelPlaceholder id={headerLeftTunnelId} />
        </SiteNameWrapper>
        <Flex alignItems="center" data-test="header-right-container">
          <TunnelPlaceholder id={headerRightTunnelId} multiple={true}>
            {({ items }: IReactTunnel) => (items ? items.map(({ children }) => children) : null)}
          </TunnelPlaceholder>
          {!isHideUserMenu && <UserMenu />}
          {!isAuthorized && isHideUserMenu && (
            <Link to="/sign-in" className="ml16">
              <ProfileIcon />
              <SignInText className="ml8">Sign in</SignInText>
            </Link>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const HeaderRightComponent: FunctionComponent = (props) => (
  <Tunnel id={headerRightTunnelId} {...props} />
);

export const HeaderLeftComponent: FunctionComponent = (props) => (
  <Tunnel id={headerLeftTunnelId} {...props} />
);

export default styled(Header)`
  padding: 7px 30px 7px 15px;
  background-color: ${(props) => props.theme.colors.snow};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  position: relative;
  ${(props) => props.theme.media.sm`
    padding: 7px 35px;
  `};
  ${(props) => props.theme.media.xs`
    padding: 7px 10px;
  `};
  @media print {
    display: none;
  }
`;

const SiteNameWrapper = styled(Flex)`
  position: relative;
  min-width: 0;
`;

const SiteName = styled.h6`
  color: ${(props) => props.theme.colors.accentNormal};
  text-transform: uppercase;
  line-height: 36px;
  font-family: Gotham Pro Black;
  font-size: 26px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LogoButton = styled(TransparentButton)`
  overflow: initial;
`;

const TitleWrapper = styled(Flex)`
  min-width: 0;
`;

const SiteDescription = styled.p`
  font-family: Gotham Pro;
  font-size: 10px;
  color: ${(props) => props.theme.colors.dark};
  margin: 0;
`;

const SignInText = styled.span`
  font-weight: bold;
`;

const MobileTitleWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;
