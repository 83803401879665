import Flex from 'components/Flex';
import styled from 'styled-components';

export const Container = styled(Flex)`
  height: 100%;
  width: 340px;
  ${(props) => props.theme.media.xs`
    width: 100%;
  `}
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.dark};
  font-family: Gotham Pro Bold;
  line-height: 64px;
  font-size: 46px;
  text-align: center;
  letter-spacing: -0.04em;
  padding-bottom: 32px;
  ${(props) => props.theme.media.md`
     padding-bottom: 0;
  `}
`;
