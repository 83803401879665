import useAuth from 'hooks/useAuth';
import { DEFAULT_PLAN } from 'libs/constants';
import { FunctionComponent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SignUpRedirectPage: FunctionComponent = () => {
  const { isAuthorized } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const URLParams = new URLSearchParams(location.search);
    const plan = URLParams.get('plan') ? URLParams.get('plan') : DEFAULT_PLAN;
    const path = isAuthorized
      ? `/account-sign-up?plan=${plan}`
      : `/check-email?redirect_to=/account-sign-up?plan=${plan}`;
    navigate(path, { replace: true });
  }, [location, isAuthorized, navigate]);
  return null;
};

export default SignUpRedirectPage;
