import SingleSelect, { IOption, ISingleSelect } from 'components/Form/Select/SingleSelect';
import { FieldProps } from 'formik';
import { FunctionComponent, useCallback } from 'react';
import React from 'react';
import Creatable from 'react-select/lib/Creatable';

interface ICreatableSelect extends ISingleSelect {
  onCreate: (option: IOption) => void;
}

const SingleSelectWithCreate: FunctionComponent<ICreatableSelect & FieldProps> = ({
  form,
  field,
  options,
  onCreate,
  ...rest
}) => {
  const onSelect = useCallback((option) => form.setFieldValue(field.name, option.value), []);
  const onChange = useCallback(
    (option, { action }) => {
      switch (action) {
        case 'select-option':
          onSelect(option);
          break;
        case 'create-option':
          onCreate(option);
          onSelect(option);
          break;
      }
    },
    [options]
  );

  return (
    <SingleSelect
      component={Creatable as any}
      options={options}
      field={field}
      form={form}
      onChange={onChange}
      {...rest}
    />
  );
};

export default SingleSelectWithCreate;
