import { FocusEvent, useRef, useState } from 'react';

const useFocus = (): [
  boolean,
  {
    onFocus: (e?: FocusEvent) => void;
    onBlur: (e?: FocusEvent) => void;
    setIgnoreBlur: (value: boolean) => void;
  }
] => {
  const [isFocused, setFocused] = useState(false);
  const ignoreBlur = useRef(false);

  const bind = {
    onBlur: () => {
      if (!ignoreBlur.current) {
        setFocused(false);
      }
    },
    onFocus: () => setFocused(true),
    setIgnoreBlur: (value: boolean) => (ignoreBlur.current = value),
  };

  return [isFocused, bind];
};

export default useFocus;
