import Button from 'components/Button';
import IndentView, { HighlightedSpan } from 'components/Card/IndentView';
import Flex from 'components/Flex';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import React, { FunctionComponent, useEffect } from 'react';
import useNavigator from 'hooks/useNavigator';

const CondoSignUpFinish: FunctionComponent = () => {
  const { navigateCallback } = useNavigator();
  const theme = useTheme();
  const params = new URLSearchParams(window.location.search);
  const title = params.get('title') || '';

  useEffect(() => {
    if (!title) {
      navigateCallback('/search', { replace: true });
    }
  }, [title]);

  return (
    <>
      <IndentView title="Your account has been deleted">
        <Text color={theme.colors.dark} className="mt32">
          We have deleted your profile. You will be no longer receiving notifications from{' '}
          <HighlightedSpan>{title}</HighlightedSpan>.
        </Text>
        <Text color={theme.colors.dark} className="mt24" align="center">
          Here you can find your new account here:
        </Text>
        <Flex justifyContent="center">
          <Button onClick={navigateCallback('/search', { replace: true })} styleType="link">
            <Text color={theme.colors.accentNormal} weight="700">
              Click here to find your association
            </Text>
          </Button>
        </Flex>
      </IndentView>
    </>
  );
};

export default CondoSignUpFinish;
