import Flex from 'components/Flex';
import Field from 'components/Form/Field';
import Text from 'components/Text';
import debitImage from 'images/debit.png';
import React from 'react';
import styled from 'styled-components';

export const BankAccount = () => (
  <>
    <Text size="xs">
      The bank account information such as the Transit Number, Bank ID, and Account Number can be
      found on a cheque. Below is a sample of how to read the bank account numbers. Bank account
      information has numbers only.
    </Text>
    <Flex justifyContent="center">
      <StyledImage alt="bank account information example" src={debitImage} />
    </Flex>
    <Field name="transit_number" label="Transit number" placeholder="Transit number" />
    <Field name="institution_number" label="Institution number" placeholder="Institution number" />
    <Field name="account_number" label="Account number" placeholder="Account number" />
    <Field name="bank_name" label="Bank name" placeholder="Bank name" />
  </>
);

const StyledImage = styled.img`
  max-width: 100%;
`;
