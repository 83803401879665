import { IUser } from 'domain/env/types';
import { AppState } from 'domain/reducers';
import { getIn } from 'formik';
import { TAllPermissions } from 'hooks/usePermission';
import api from 'libs/api';
import {
  ACCOUNT_DASHBOARD_PERMISSIONS,
  ACCOUNT_GLOBAL_SETTINGS_PERMISSIONS,
  COMMON_TYPE_ACCOUNT,
  COOKIES_LIFETIME,
  DEFAULT_TIMEZONE,
  RESET_PASSWORD_KEYS,
  RESET_PASSWORD_REDIRECT_PATH,
  STRONG_ACCOUNT_ADMIN_PAGES_PERMISSIONS,
  STRONG_COMPANY_ADMIN_PAGES_PERMISSIONS,
  TOKEN_KEYS,
} from 'libs/constants';
import cookies from 'libs/cookie';
import getSubdomain from 'libs/subdomain';
import { createPermission, createStrongPermission, fullUrlPath, getTokens } from 'libs/utils';
import { IResetFormValues } from 'pages/Auth/UserSignIn/Forgot';
import { IResetPasswordFormValues } from 'pages/Auth/UserSignIn/Reset';
import { ISignInFormValues } from 'pages/Auth/UserSignIn/SignIn';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  commonActions,
  doneRedirectAfterSignInAction,
  logOutAction,
  needRedirectAfterSignInAction,
  resetPasswordActions,
  setUserAction,
  signInActions,
} from './actions';
import {
  envIsAuthorizedSelector,
  envIsLoadingSelector,
  envNeedRedirectAfterSignInSelector,
} from './selectors';
import { NavigateFunction } from 'react-router-dom';
import { AxiosResponse, Cancel } from 'axios';

interface IHeaders {
  [key: string]: string;
}

const getHeaderValue =
  (headers: IHeaders) =>
  (key: string): string =>
    headers[key];
const getSearchParamValue =
  (headers: URLSearchParams) =>
  (key: string): string | null =>
    headers.get(key);

export const thunkSignIn =
  (
    data: ISignInFormValues,
    navigate: NavigateFunction
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(signInActions.request());
      const URLParams = new URLSearchParams(window.location.search);
      const redirectTo = URLParams.get('redirect_to');
      const response = await api.auth.signIn({ params: data });
      const tokens = getTokens(TOKEN_KEYS, getHeaderValue(response.headers));
      const user = response.data.resource;
      const auth = { user, tokens };

      cookies.set('auth', JSON.stringify(auth), {
        domain: process.env.REACT_APP_FRONTEND_DOMAIN,
        expires: new Date(Date.now() + COOKIES_LIFETIME),
        path: '/',
      });

      if (!redirectTo) {
        // Must dispatch before success sign in action
        dispatch(needRedirectAfterSignInAction());
      }
      dispatch(signInActions.success(auth));

      if (redirectTo) {
        const regexp = new RegExp('https?://');
        if (regexp.test(redirectTo)) {
          window.location.replace(redirectTo);
        } else {
          navigate(redirectTo);
        }
      } else {
        navigate('/');
      }
    } catch (e) {
      //@ts-ignore
      dispatch(signInActions.failure(e));
    }
  };

export const thunkForgotPassword =
  (
    data: IResetFormValues,
    navigate: NavigateFunction,
    handleHttpError: (error: { response: AxiosResponse } | Cancel | unknown) => void
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch) => {
    try {
      const { subdomain } = getSubdomain();
      dispatch(resetPasswordActions.request());
      const response = await api.auth.forgotPassword({
        params: {
          ...data,
          redirect_url: subdomain
            ? fullUrlPath(subdomain, '/' + RESET_PASSWORD_REDIRECT_PATH)
            : process.env.REACT_APP_URL + RESET_PASSWORD_REDIRECT_PATH,
        },
      });
      dispatch(resetPasswordActions.success({ user: response.data.resource }));
      navigate('/forgot-sent');
    } catch (e) {
      //@ts-ignore
      dispatch(resetPasswordActions.failure(e));
      handleHttpError(e);
    }
  };

export const thunkResetPassword =
  (
    data: IResetPasswordFormValues,
    searchParams: URLSearchParams,
    navigate: NavigateFunction,
    handleHttpError: (error: { response: AxiosResponse } | Cancel | unknown) => void
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch) => {
    try {
      const headers = getTokens(RESET_PASSWORD_KEYS, getSearchParamValue(searchParams));
      dispatch(resetPasswordActions.request());
      const response = await api.auth.resetPassword({
        params: { ...data, reset_password_token: searchParams.get('reset_password_token') },
        headers,
      });
      dispatch(resetPasswordActions.success({ user: response.data.resource }));
      const URLParams = new URLSearchParams(window.location.search);
      const redirectTo = URLParams.get('redirect_to');
      if (redirectTo) {
        navigate(`/sign-in?redirect_to=${redirectTo}`);
      } else {
        navigate('/sign-in');
      }
    } catch (e) {
      //@ts-ignore
      dispatch(resetPasswordActions.failure(e));
      handleHttpError(e);
    }
  };

export const updateUser =
  (newUserInfo: IUser): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch) => {
    const auth = cookies.get('auth') || { tokens: {}, user: {} };
    const user: IUser = {
      ...auth.user,
      ...newUserInfo,
    };
    cookies.set(
      'auth',
      JSON.stringify({
        ...auth,
        user,
      }),
      {
        domain: process.env.REACT_APP_FRONTEND_DOMAIN,
        expires: new Date(Date.now() + COOKIES_LIFETIME),
        path: '/',
      }
    );
    dispatch(
      setUserAction({
        user,
      })
    );
  };

export const thunkLogOut =
  (navigate?: NavigateFunction): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const isAuthorized = envIsAuthorizedSelector(state);
    if (isAuthorized) {
      dispatch(logOutAction());
      await cookies.remove('auth', {
        domain: process.env.REACT_APP_FRONTEND_DOMAIN,
        path: '/',
      });
      navigate ? navigate('/sign-in') : (window.location.href = '/sign-in');
    }
  };

export const thunkCommon =
  (
    navigate: NavigateFunction,
    handleHttpError: (
      error: { response: AxiosResponse } | Cancel | unknown,
      field: string | undefined,
      useNotFound: boolean
    ) => void
  ): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const isProfileLoading = envIsLoadingSelector(state);
    const needRedirectAfterSignIn = envNeedRedirectAfterSignInSelector(state);
    if (isProfileLoading) {
      return;
    }
    try {
      dispatch(commonActions.request());
      const response = await api.common();
      const common = response.data.resource;

      if (common.type) {
        const { subdomain } = getSubdomain();

        cookies.set('timezone', getIn(common, ['account', 'time_zone'], DEFAULT_TIMEZONE), {
          domain: `${subdomain}${process.env.REACT_APP_FRONTEND_DOMAIN}`,
          expires: new Date(Date.now() + COOKIES_LIFETIME),
          path: '/',
        });

        if (common.profile && common.profile.discarded_at === null) {
          let permissions;

          if (common.type === COMMON_TYPE_ACCOUNT) {
            permissions = {
              dashboard: createPermission(
                common.profile.permissions,
                ACCOUNT_DASHBOARD_PERMISSIONS as TAllPermissions[]
              ),
              global_settings: createPermission(
                common.profile.permissions,
                ACCOUNT_GLOBAL_SETTINGS_PERMISSIONS as TAllPermissions[]
              ),
            };
          } else {
            permissions = {
              dashboard: ['read'],
            };
          }

          common.profile.permissions = {
            ...common.profile.permissions,
            ...permissions,
          };

          // Permission "administration" must be added last
          common.profile.permissions.administration =
            common.type === COMMON_TYPE_ACCOUNT
              ? createStrongPermission(
                  common.profile.permissions,
                  STRONG_ACCOUNT_ADMIN_PAGES_PERMISSIONS
                )
              : createStrongPermission(
                  common.profile.permissions,
                  STRONG_COMPANY_ADMIN_PAGES_PERMISSIONS
                );
        } else {
          common.profile = {};
        }
      }
      dispatch(commonActions.success({ common }));

      if (needRedirectAfterSignIn) {
        dispatch(doneRedirectAfterSignInAction());
        navigate(common.profile?.id ? '/dashboard' : '/');
      }
    } catch (e) {
      //@ts-ignore
      dispatch(commonActions.failure(e));
      handleHttpError(e, undefined, true);
    }
  };
