import Flex from 'components/Flex';
import Text from 'components/Text';
import {
  PROJECT_TITLE,
  PROJECT_YEAR,
  WEBSITE_PRIVACY_POLICY,
  WEBSITE_TERMS_CONDITIONS,
} from 'libs/constants';
import React from 'react';
import styled from 'styled-components';

const MenuFooter = () => (
  <Wrapper>
    <Flex direction="column" className="mb24">
      <a href={WEBSITE_PRIVACY_POLICY} className="mb16">
        <Text size="xs" color="rgba(255, 255, 255, 0.4)">
          Privacy Policy
        </Text>
      </a>
      <a href={WEBSITE_TERMS_CONDITIONS}>
        <Text size="xs" color="rgba(255, 255, 255, 0.4)">
          Terms & Conditions
        </Text>
      </a>
    </Flex>
    <Text size="xs" color="rgba(255, 255, 255, 0.7)">
      © {PROJECT_YEAR} {PROJECT_TITLE}
    </Text>
    <Text size="xs" color="rgba(255, 255, 255, 0.7)" className="mt4">
      All rights reserved
    </Text>
  </Wrapper>
);

export default MenuFooter;

const Wrapper = styled.div`
  padding: 25px 45px;
`;
