import Flex from 'components/Flex';
import { SubTitle, Title } from 'components/ImageLayout';
import Text from 'components/Text';
import { Formik, FormikHelpers } from 'formik';
import useCommon from 'hooks/useCommon';
import useTheme from 'hooks/useTheme';
import QuestionHeaderLink from 'pages/App/Header/ButtonLayouts/Question';
import React from 'react';
import styled from 'styled-components';
import { MixedSchema } from 'yup';
import Form, { PaymentMethodCard } from './Form';

interface IPaymentMethodForm<T> {
  className?: string;
  title: string;
  initialValues: T;
  validationSchema: MixedSchema;
  formCards: PaymentMethodCard[];
  plan?: string;
  price?: string;
  invoiceID?: number;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
}

function PaymentMethodLayout<T>({
  initialValues,
  validationSchema,
  title,
  formCards,
  price,
  plan,
  invoiceID,
  onSubmit,
}: IPaymentMethodForm<T>) {
  const theme = useTheme();
  const { title: commonTitle, isLoaded } = useCommon();

  return (
    <>
      {isLoaded && (
        <>
          <QuestionHeaderLink />
          <Flex direction="column" alignItems="center">
            <PaymentCommonTitle>Set up</PaymentCommonTitle>
            <PaymentGreenTitle>{title}</PaymentGreenTitle>
            <PaymentCommonTitle>Payments For</PaymentCommonTitle>
            <PaymentGreenTitle>{commonTitle}</PaymentGreenTitle>
            {(plan || price || invoiceID) && (
              <PlanPricingBlock justifyContent="space-evenly" className="mt16">
                {plan && (
                  <Flex>
                    <SubTitle>Your selected plan: {plan}</SubTitle>
                  </Flex>
                )}
                {invoiceID && (
                  <Flex>
                    <SubTitle>Invoice: INV-{invoiceID}</SubTitle>
                  </Flex>
                )}
                {price && (
                  <Flex>
                    <SubTitle>Price: ${price}</SubTitle>
                  </Flex>
                )}
              </PlanPricingBlock>
            )}
            <Text weight="bold" className="mt4" color={theme.colors.snow}>
              * ALL FIELDS BELOW ARE REQUIRED *
            </Text>
          </Flex>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {(helpers) => <Form<T> formCards={formCards} {...helpers} />}
          </Formik>
          <SubTitle className="mt24">
            * After clicking the Save Payment Information button please wait as processing may take
            a few seconds.
          </SubTitle>
        </>
      )}
    </>
  );
}

export default PaymentMethodLayout;

const PlanPricingBlock = styled(Flex)`
  width: 100%;
  ${(props) => props.theme.media.xs`
    flex-direction: column;
    align-items: center;
  `}
`;

const PaymentCommonTitle = styled(Title)`
  margin: 4px 0;
  &:first-child {
    margin-top: 25px;
  }
`;

const PaymentGreenTitle = styled(PaymentCommonTitle)`
  color: ${(props) => props.theme.colors.accentHover};
`;
