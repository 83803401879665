import { AppState } from 'domain/reducers';
import { peek } from 'libs/selectors';
import { createSelector } from 'reselect';

const baseSelector = (state: AppState) => state.env;

export const envUserSelector = createSelector(baseSelector, peek('user'));

export const envUserRoleSelector = createSelector(baseSelector, peek('common', 'profile', 'role'));

export const envUserIdSelector = createSelector(baseSelector, peek('user', 'id'));

export const envIsAuthorizedSelector = createSelector(baseSelector, peek('isAuthorized'));

export const envTokensSelector = createSelector(baseSelector, peek('tokens'));

export const envUserEmailSelector = createSelector(baseSelector, peek('user', 'email'));

export const envProfileIdSelector = createSelector(baseSelector, peek('common', 'profile', 'id'));

export const envProfileSelector = createSelector(baseSelector, peek('common', 'profile'));

export const envProfilePermissionsSelector = createSelector(
  baseSelector,
  peek('common', 'profile', 'permissions')
);

export const envAccountPlanSelector = createSelector(
  baseSelector,
  peek('common', 'account', 'plan')
);

export const envIsLoadingSelector = createSelector(baseSelector, peek('loading'));

export const envNeedRedirectAfterSignInSelector = createSelector(
  baseSelector,
  peek('needRedirectAfterSignIn')
);

export const envIsErrorSelector = createSelector(baseSelector, peek('error'));

export const envErrorMessagesSelector = createSelector(baseSelector, peek('errorMessage'));

export const envAccountSelector = createSelector(baseSelector, peek('common', 'account'));

export const envCommonSelector = createSelector(baseSelector, peek('common'));

export const envCommonTypeSelector = createSelector(baseSelector, peek('common', 'type'));

export const envCommonCompanySelector = createSelector(baseSelector, peek('common', 'company'));

export const envCommonIsLoadedSelector = createSelector(baseSelector, peek('common', 'is_loaded'));

export const envAccountNameSelector = createSelector(
  baseSelector,
  peek('common', 'account', 'name')
);

export const envCompanyNameSelector = createSelector(
  baseSelector,
  peek('common', 'company', 'name')
);
