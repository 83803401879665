import { envUserIdSelector, envUserRoleSelector, envUserSelector } from 'domain/env/selectors';
import { getFullName } from 'libs/utils';
import { useMemo } from 'react';
import { useMappedState } from 'redux-react-hook';
import { createStructuredSelector } from 'reselect';

const selector = createStructuredSelector({
  currentUserId: envUserIdSelector,
  role: envUserRoleSelector,
  user: envUserSelector,
});

export default function useUser() {
  const { user, currentUserId, role } = useMappedState(selector);

  const fullName = useMemo(() => getFullName(user), [user.first_name, user.last_name]);

  return { user, currentUserId, role, fullName };
}
