import useFetchResource from 'hooks/useFetchResource';
import { selectSerializer } from 'libs/serializers';
import api, { TApi } from 'libs/api';

export interface IUseGetUnits<T> {
  buildingId?: number;
  apiUnits?: TApi;
  initialLoad?: boolean;
  prepareUnit?: T;
  serializer?: (data: { resources: any }) => T[];
  forProfile?: boolean;
}

function useGetUnits<T>({
  buildingId,
  prepareUnit,
  apiUnits = api.account.options.buildings.getUnits,
  initialLoad = false,
  forProfile = false,
  serializer = (data): T[] =>
    selectSerializer<T>(data.resources, { labelKey: 'unit_number', valueKey: 'id' }),
}: IUseGetUnits<T>) {
  const {
    resource: units,
    fetchResource: fetchUnits,
    setResource: setUnits,
  } = useFetchResource<T[]>({
    api: apiUnits,
    initialLoad,
    initialParams: { buildingId, ...(forProfile ? { current_profile: true } : {}) },
    initialValues: [],
    serializer: (data): T[] => {
      const units: T[] = serializer(data);
      return prepareUnit ? [prepareUnit, ...units] : units;
    },
  });

  return { units, fetchUnits, setUnits };
}

export default useGetUnits;
