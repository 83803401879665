import Button from 'components/Button';
import Flex from 'components/Flex';
import Avatar from 'components/Form/Avatar';
import { Subtitle } from 'components/PageTitle/Subtitle';
import Text from 'components/Text';
import { StepType } from 'components/WizardForm';
import { Field } from 'formik';
import useTheme from 'hooks/useTheme';
import * as validations from 'libs/validations';
import React from 'react';
import * as Yup from 'yup';
import { IValues } from '../index';
import LocationField from 'components/Form/Location/FormikField';

const Step2: StepType<IValues> = ({ goToPrevStep, values }) => {
  const theme = useTheme();

  return (
    <>
      <Subtitle>Enter your mailing address</Subtitle>
      <Text size="xs" color={theme.colors.noticeGrey} className="mb32" align="center">
        Provide your mailing address so the board and manager have the most recent address on file.
      </Text>
      <Flex className="mt24" direction="column">
        <Flex justifyContent="center">
          <Field name="avatar" component={Avatar} placeholder="Your Avatar" />
        </Flex>
        <Flex direction="column">
          <Field
            name="location"
            component={LocationField}
            className="mt8"
            initValue={values.location.address}
            needApartment
          />
        </Flex>
      </Flex>
      <Flex direction="column" alignItems="center" className="mt32">
        <Button type="submit">Create account</Button>
        <Button styleType="link" onClick={goToPrevStep}>
          Back
        </Button>
      </Flex>
    </>
  );
};

Step2.Schema = Yup.object().shape({
  location: validations.location,
});

export default Step2;
