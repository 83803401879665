import { Wrapper } from 'components/AdministrationCards';
import Flex from 'components/Flex';
import Layout from 'components/Layout';
import PageHeader from 'components/PageHeader';
import Text from 'components/Text';
import useFetchResource from 'hooks/useFetchResource';
import useTheme from 'hooks/useTheme';
import api from 'libs/api';
import React, { FunctionComponent, useMemo } from 'react';
import useCommon from 'hooks/useCommon';
import styled from 'styled-components';
import PaymentTypeCard from './PaymentTypeCard';
import usePayments from './usePayments';
import { useParams } from 'react-router-dom';

interface IPaymentTypes {
  check: boolean;
  credit_card: boolean;
  debit_card: boolean;
}

interface IUpdatePaymentItem {
  description: string;
  link: string;
  title: string;
  type: keyof IPaymentTypes;
}

const initialValues: IPaymentTypes = {
  check: false,
  credit_card: false,
  debit_card: false,
};

const UpdatePayment: FunctionComponent = () => {
  usePayments();
  const { guid } = useParams();
  const theme = useTheme();
  const { isLoaded } = useCommon();

  const { resource: paymentTypes } = useFetchResource<IPaymentTypes>({
    api: api.billing.payment.types.getOne,
    initialParams: {
      guid,
    },
    initialValues,
  });

  const pages: IUpdatePaymentItem[] = useMemo(
    () => [
      {
        description:
          'Payments are made monthly and you will receive an invoice for your records. Payment will be withdrawn from a bank account.',
        link: `/payment/${guid}/debit_card`,
        title: 'Pre-Authorized Debit / EFT',
        type: 'debit_card',
      } as IUpdatePaymentItem,
      {
        description:
          'Payments are made monthly and you will receive an invoice for your records. Payment will be withdrawn from credit card.',
        link: `/payment/${guid}/credit_card`,
        title: 'Credit Card',
        type: 'credit_card',
      } as IUpdatePaymentItem,
    ],
    [guid]
  );

  const filteredPages = useMemo(
    () => pages.filter((item) => paymentTypes[item.type]),
    [paymentTypes]
  );

  const breadcrumbs = useMemo(
    () => [{ title: 'Administration', to: '/administration' }, { title: 'Select payment option' }],
    []
  );

  return (
    <Layout>
      {isLoaded && (
        <>
          <PageHeader title="Select A Payment Option" breadcrumbs={breadcrumbs} />
          <Wrapper>
            {filteredPages.length > 0 ? (
              filteredPages.map((page) => <PaymentTypeCard key={page.type} {...page} />)
            ) : (
              <CardInfo justifyContent="center">
                <Text color={theme.colors.inputText} align="center">
                  Payment types not available
                </Text>
              </CardInfo>
            )}
          </Wrapper>
          <p>
            You're currently on a monthly subscription. If you'd like to switch to a yearly
            subscription, please, contact support at{' '}
            <a href="mailto:support@condogenie.com">support@condogenie.com</a>
          </p>
        </>
      )}
    </Layout>
  );
};

export default UpdatePayment;

const CardInfo = styled(Flex)`
  width: 100%;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 32px;
  box-sizing: border-box;
  padding: 30px;
  background: white;
`;
