import Button from 'components/Button';
import Flex from 'components/Flex';
import React, { FunctionComponent } from 'react';

interface ILoadMore {
  className?: string;
  loadMore: () => void;
  hasMore: boolean;
  title?: string;
}

const LoadMore: FunctionComponent<ILoadMore> = ({
  loadMore,
  hasMore,
  title = 'Load more',
  className = 'mt24',
}) =>
  hasMore ? (
    <Flex justifyContent="center">
      <Button
        padding="8px 55px"
        styleType="primary"
        size="sm"
        className={className}
        onClick={loadMore}
        disabled={!hasMore}
      >
        {title}
      </Button>
    </Flex>
  ) : (
    <></>
  );
export default LoadMore;
