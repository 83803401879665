import useAuth from 'hooks/useAuth';
import useCommon from 'hooks/useCommon';
import useProfile from 'hooks/useProfile';
import { AUTH_RENDER_ROUTES_URLS, WEBSITE_ROUTES } from 'libs/constants';
import isEmpty from 'lodash.isempty';
import { useCallback, useEffect } from 'react';
import { checkIsUnauthorizedUrl } from 'hooks/useWebsite';
import { useLocation, useNavigate } from 'react-router-dom';

interface ICheckPageLocation {
  key: string;
  location: string;
  invert?: boolean;
}

interface ICheckPagesData<T> {
  [index: string]: T;
}

/**
 * For all subdomain pages and all profiles
 */
// const ACCOUNT_PAGES: ICheckPageLocation[] = [{ key: 'is_suspended', location: '/condo-sign-up-finish', invert: true }];

/**
 * Exclude website routes, for all profiles
 */
const ACCOUNT_PROFILE_PAGES: ICheckPageLocation[] = [
  { key: 'approved', location: '/condo-sign-up-finish' },
  { key: 'is_self_confirmed', location: '/self-approve' },
  // { key: 'discarded_at', location: '/deleted', invert: true },
];

/**
 * Checking account signUp finish only for admin
 */
const ACCOUNT_ADMIN_PAGES: ICheckPageLocation[] = [
  { key: 'auth_filled', location: '/account-sign-up-finish' },
];

/**
 * Checking user profiles in account
 */
const ACCOUNT_USER_PAGES: ICheckPageLocation[] = [
  { key: 'auth_filled', location: '/condo-sign-up' },
  { key: 'approved', location: '/condo-sign-up-finish' },
  { key: 'details_filled', location: '/user-details' },
];

/**
 * For all company subdomain pages and all profiles
 */
// const COMPANY_PAGES = [];

/**
 * Exclude website routes, for all company profiles
 */
const COMPANY_PROFILE_PAGES: ICheckPageLocation[] = [
  // { key: 'auth_filled', location: '/company-sign-up' },
  { key: 'approved', location: '/company-sign-up-finish' },
  // { key: 'discarded_at', location: '/deleted', invert: true },
];

export default function useSubdomainPage() {
  const { isAuthorized } = useAuth();
  const { isCommonTypeAccount, isLoaded, type } = useCommon();
  const { profile } = useProfile();
  const navigate = useNavigate();
  const location = useLocation();

  const checkPages = <T>(data: ICheckPagesData<T>, locations: ICheckPageLocation[]) => {
    if (WEBSITE_ROUTES.includes(window.location.pathname)) {
      return;
    }
    for (const item of locations) {
      if (Object.keys(data).includes(item.key)) {
        if ((item.invert && !!data[item.key]) || (!item.invert && !data[item.key])) {
          if (item.location === window.location.pathname) {
            return;
          }
          return navigate(item.location, { replace: true });
        }
      }
    }
    AUTH_RENDER_ROUTES_URLS.forEach((path) => {
      if (new RegExp(`${path}.*`).test(window.location.pathname)) {
        return navigate('/dashboard', { replace: true });
      }
    });
  };

  const makeRedirecting = useCallback(() => {
    if (isCommonTypeAccount) {
      if (!checkIsUnauthorizedUrl()) {
        if (profile.is_admin) {
          checkPages(profile, [...ACCOUNT_PROFILE_PAGES, ...ACCOUNT_ADMIN_PAGES]);
        } else {
          checkPages(profile, [...ACCOUNT_PROFILE_PAGES, ...ACCOUNT_USER_PAGES]);
        }
      }
    } else if (!checkIsUnauthorizedUrl()) {
      checkPages(profile, COMPANY_PROFILE_PAGES);
    }
  }, [profile]);

  useEffect(() => {
    if (isLoaded) {
      if (isAuthorized && isEmpty(profile) && !checkIsUnauthorizedUrl() && !type) {
        const params = location.search;
        navigate(isCommonTypeAccount ? `/condo-sign-up${params}` : `/company-sign-up${params}`);
      } else if (profile.id) {
        makeRedirecting();
      }
    }
  }, [isLoaded, profile.id, isCommonTypeAccount, location, type, navigate]);
}
