import { IAccount, ICommon, IProfile, ITokens, IUser, TCompany } from 'domain/env/types';
import { createAction, createAsyncAction, createStandardAction } from 'typesafe-actions';

export const signInActions = createAsyncAction(
  'ENV_SIGN_IN_REQUEST',
  'ENV_SIGN_IN_SUCCESS',
  'ENV_SIGN_IN_FAILURE'
)<void, { user: IUser; tokens: ITokens }, Error>();

export const commonActions = createAsyncAction(
  'ENV_COMMON_REQUEST',
  'ENV_COMMON_SUCCESS',
  'ENV_COMMON_FAILURE'
)<void, { common: ICommon }, Error>();

export const resetPasswordActions = createAsyncAction(
  'ENV_RESET_REQUEST',
  'ENV_RESET_SUCCESS',
  'ENV_RESET_FAILURE'
)<void, { user: IUser }, Error>();

export const logOutAction = createAction('ENV_LOG_OUT');
export const needRedirectAfterSignInAction = createAction('ENV_NEED_REDIRECT_AFTER_SIGN_IN');
export const doneRedirectAfterSignInAction = createAction('ENV_DONE_REDIRECT_AFTER_SIGN_IN');

export const setAccountAction = createStandardAction('SET_ACCOUNT_ACTION')<{
  account: IAccount;
}>();

export const setCompanyAction = createStandardAction('SET_COMPANY_ACTION')<{ company: TCompany }>();

export const setProfileAction = createStandardAction('SET_PROFILE_ACTION')<{
  profile: IProfile;
}>();

export const setUserAction = createStandardAction('SET_USER_ACTION')<{ user: IUser }>();
