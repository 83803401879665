import { getIn } from 'formik';
import useCommon from 'hooks/useCommon';
import useWebsite from 'hooks/useWebsite';
import { COOKIES_LIFETIME, THEME_NO_SITE } from 'libs/constants';
import cookies from 'libs/cookie';
import getSubdomain from 'libs/subdomain';
import React, {
  createContext,
  FunctionComponent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThemeProvider } from 'styled-components';
import * as themes from '../theme';

interface IThemeToggleContext {
  backgroundImage: string | null;
  isSync: boolean;
  name: themes.IThemes;
  setBackgroundImage: (backgroundImage: string | null) => void;
  setTheme: (theme: themes.IThemes) => void;
}

const ThemeToggleContext = createContext<IThemeToggleContext>({
  backgroundImage: null,
  isSync: false,
  name: THEME_NO_SITE,
  setBackgroundImage: (/*backgroundImage: IThemeToggleContext['backgroundImage']*/) => undefined,
  setTheme: (/*theme: themes.IThemes*/) => undefined,
});

export const useTheme = () => useContext(ThemeToggleContext);

interface ISiteThemeProvider {
  children?: ReactElement;
}

export const SiteThemeProvider: FunctionComponent<ISiteThemeProvider> = ({ children }) => {
  const accountDefaultTheme: themes.IThemes = THEME_NO_SITE;
  const companyDefaultTheme: themes.IThemes = THEME_NO_SITE;
  const [defaultTheme, setDefaultTheme] = useState<themes.IThemes>(accountDefaultTheme);
  const [name, setName] = useState<themes.IThemes>(defaultTheme);
  const [backgroundImagePath, setBackgroundImagePath] =
    useState<IThemeToggleContext['backgroundImage']>(null);
  const { subdomain } = getSubdomain();
  const { common, type, isCommonTypeCompany, isLoaded } = useCommon();
  const storeKeyTheme = 'theme';
  const storeKeyBackgroundImage = 'background-image';
  const [isSync, setIsSync] = useState(false);
  const { isWebsite } = useWebsite();

  useEffect(() => {
    setDefaultTheme(isCommonTypeCompany ? companyDefaultTheme : accountDefaultTheme);
  }, [isCommonTypeCompany]);

  useEffect(() => {
    if (getIn(common, [type, 'website', 'theme'])) {
      setTheme(getIn(common, [type, 'website', 'theme']));
      setIsSync(true);
    } else if (isLoaded) {
      setIsSync(true);
    }

    setBackgroundImage(getIn(common, [type, 'website', 'image', 'url'], null));
  }, [common, type, isLoaded]);

  useEffect(() => {
    setName(cookies.get(storeKeyTheme) || defaultTheme);
    setBackgroundImagePath(cookies.get(storeKeyBackgroundImage) || null);
  }, [defaultTheme]);

  function setValueToStateAndStore<T>(
    setterState: (value: T) => void,
    storeKey: string,
    defaultValue: T
  ) {
    return (value: T = defaultValue) => {
      // 10 year
      // If lifeTime is negative then cookies will remove.
      // If value is null then make number negative.
      const lifeTime = (value ? 1 : -1) * COOKIES_LIFETIME;
      setterState(value);
      cookies.set(storeKey, value, {
        domain: `${subdomain}${process.env.REACT_APP_FRONTEND_DOMAIN}`,
        expires: new Date(Date.now() + lifeTime),
        path: '/',
      });
    };
  }

  const setTheme = setValueToStateAndStore<themes.IThemes>(setName, storeKeyTheme, defaultTheme);
  const setBackgroundImage = setValueToStateAndStore<IThemeToggleContext['backgroundImage']>(
    setBackgroundImagePath,
    storeKeyBackgroundImage,
    null
  );

  return (
    <ThemeToggleContext.Provider
      value={{ setTheme, setBackgroundImage, name, backgroundImage: backgroundImagePath, isSync }}
    >
      <ThemeProvider theme={themes?.[isWebsite ? name : defaultTheme]}>{children}</ThemeProvider>
    </ThemeToggleContext.Provider>
  );
};

export default ThemeProvider;
