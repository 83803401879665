import Flex from 'components/Flex';
import { HeaderRightComponent } from 'pages/App/Header/index';
import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { TDisplaySize } from 'theme';

interface IView {
  boundary: TDisplaySize;
}

interface IHeaderLinkTemplate extends Partial<IView> {
  className?: string;
  desktopView: ReactNode;
  mobileView: ReactNode;
  children?: ReactNode;
}

const HeaderLinkTemplate: FunctionComponent<IHeaderLinkTemplate> = ({
  className = '',
  boundary = 'sm',
  desktopView,
  mobileView,
  children,
}) => (
  <HeaderRightComponent>
    <ViewWrapper>
      {children}
      <DesktopView boundary={boundary} className={className}>
        {desktopView}
      </DesktopView>
      <MobileView boundary={boundary} className={className}>
        {mobileView}
      </MobileView>
    </ViewWrapper>
  </HeaderRightComponent>
);

export default HeaderLinkTemplate;

const DesktopView = styled.div<IView>`
  ${(props) => props.theme.media[props.boundary]`
    display: none;
  `}
`;

const MobileView = styled.div<IView>`
  display: none;
  ${(props) => props.theme.media[props.boundary]`
    display: flex;
  `};
`;

const ViewWrapper = styled.div`
  position: relative;
  ${(props) => props.theme.media.xs`
    position: static;
  `};
`;

export const IconContainer = styled(Flex)<{ backgroundColor?: string }>`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  padding: 0;
  border-color: ${(props) => props.theme.colors.snow};
  background-color: ${(props) => props.backgroundColor || props.theme.colors.inputText};
  ${(props) => props.theme.fillSvg(props.theme.colors.snow)};
  &:hover {
    opacity: 0.8;
  }
`;
