import 'intl-tel-input/build/css/intlTelInput.css';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import intlTelInput, { Options } from 'intl-tel-input';
import styled from 'styled-components';
import { IInputComponent, StyledInput } from 'components/Form/Input';

export interface IProps extends IInputComponent {
  telOptions?: Options;
}

const PhoneInput: FunctionComponent<IProps> = ({
  telOptions = {
    preferredCountries: ['us', 'ca'],
    separateDialCode: true,
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/js/utils.min.js',
  },
  onChange,
  onBlur,
  value,
  ...rest
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [iti, setIti] = useState<any>(null); //TODO remove any
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const changeHandler = useCallback(() => {
    setIsChanged(true);
    onChange(iti.getNumber());
  }, [iti, onChange]);

  const keyPressHandler = useCallback((event) => {
    if (!/[0-9\-]/.test(event.key)) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (inputEl?.current && !isChanged) {
      if (!iti) {
        const intlTel = intlTelInput(inputEl.current, telOptions);
        setIti(intlTel);
        intlTel.setNumber(value || '');
        inputEl.current.addEventListener('countrychange', () => {
          onChange(intlTel.getNumber());
        });
      } else {
        if (value) {
          iti.setNumber(value);
        }
      }
    }
    //TODO remove iti
  }, [inputEl, value, isChanged, onChange]);

  return (
    <PhoneWrapper>
      <StyledInput
        ref={inputEl}
        type="tel"
        onChange={changeHandler}
        onKeyPress={keyPressHandler}
        {...rest}
      />
    </PhoneWrapper>
  );
};

export default PhoneInput;

const PhoneWrapper = styled.div`
  .iti {
    display: block;
  }
  .iti__selected-flag {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  .iti__flag-container {
    color: #000;
  }
`;
