import Button from 'components/Button';
import Flex from 'components/Flex';
import Checkbox from 'components/Form/Checkbox';
import Input from 'components/Form/Input';
import { Subtitle } from 'components/PageTitle/Subtitle';
import Row from 'components/Row';
import Text from 'components/Text';
import { StepType } from 'components/WizardForm';
import { Field } from 'formik';
import useUrlSearchParams from 'hooks/useUrlSearchParam';
import { WEBSITE_PRIVACY_POLICY, WEBSITE_TERMS_CONDITIONS } from 'libs/constants';
import * as validations from 'libs/validations';
import React from 'react';
import * as Yup from 'yup';
import { IValues } from '../index';
import PhoneField from 'components/Form/PhoneInput/FormikField';
import Password from 'components/Form/Password';

const Step1: StepType<IValues> = ({ setFieldValue }) => {
  useUrlSearchParams('email', (email) => setFieldValue('email', email, false));
  return (
    <>
      <Subtitle className="mt32">Enter your information</Subtitle>
      <Text size="xs" align="center">
        All fields below must be filled out
      </Text>
      <Row className="mt32">
        <Field
          name="first_name"
          component={Input}
          label="First Name"
          placeholder="Your Name"
          className="mt8"
        />
        <Field
          name="last_name"
          component={Input}
          label="Last Name"
          placeholder="Your Last Name"
          className="mt8"
        />
      </Row>
      <Row>
        <Field
          name="email"
          component={Input}
          label="Email"
          placeholder="Enter your email"
          className="mt8"
        />
        <Field
          name="confirm_email"
          component={Input}
          label="Confirm email"
          placeholder="youremail@gmail.com"
          className="mt8"
        />
      </Row>
      <Row>
        <PhoneField name="cell_num" label="Mobile phone number" className="mt8" />
        <PhoneField name="phone_number" label="Phone number" className="mt8" />
      </Row>
      <Subtitle className="mt16">Set your password</Subtitle>
      <Row>
        <Field
          name="password"
          type="password"
          component={Password}
          label="Password"
          placeholder="Your Password"
          className="mt16"
        />
        <Field
          name="confirm_password"
          type="password"
          component={Password}
          label="Confirm password"
          placeholder="Confirm your password"
          className="mt16"
        />
      </Row>
      <Flex justifyContent="center">
        <Field
          name="accept_rights"
          component={Checkbox}
          label={
            <>
              I accept the{' '}
              <a href={WEBSITE_TERMS_CONDITIONS} target="_blank" rel="noopener noreferrer">
                <b>Terms & Conditions</b>
              </a>{' '}
              and the{' '}
              <a href={WEBSITE_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                <b>Privacy Policy</b>
              </a>
            </>
          }
          type="checkbox"
          className="mt32"
        />
      </Flex>
      <Flex direction="column" alignItems="center" className="mt32">
        <Button type="submit">Next Step</Button>
      </Flex>
    </>
  );
};

Step1.Schema = Yup.object().shape({
  accept_rights: validations.acceptRights,
  cell_num: validations.atLeastOnePhone,
  confirm_email: validations.confirmEmail,
  confirm_password: validations.confirmPassword,
  email: validations.email,
  first_name: validations.firstName,
  last_name: validations.lastName,
  password: validations.password,
  phone_number: validations.atLeastOnePhone,
});

export default Step1;
