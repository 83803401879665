import { noticeActions } from 'domain/notice/actions';
import { thunkNotice } from 'domain/notice/effects';
import {
  noticeIsErrorSelector,
  noticeIsLoadedSelector,
  noticeIsLoadingSelector,
  noticeSelector,
} from 'domain/notice/selectors';
import { TNotice } from 'domain/notice/types';
import useCommon from 'hooks/useCommon';
import useProfile from 'hooks/useProfile';
import { useCallback, useEffect } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { createStructuredSelector } from 'reselect';

const selector = createStructuredSelector({
  isError: noticeIsErrorSelector,
  isLoaded: noticeIsLoadedSelector,
  isLoading: noticeIsLoadingSelector,
  resource: noticeSelector,
});

export default function useNotice() {
  const dispatch = useDispatch();
  const { isCommonTypeAccount } = useCommon();
  const { profileId } = useProfile();

  const { resource, isLoaded, isLoading, isError } = useMappedState(selector);

  useEffect(() => {
    if (!isLoading && !isLoaded && profileId && isCommonTypeAccount) {
      dispatch(thunkNotice());
    }
  }, [profileId, isCommonTypeAccount, isLoading, isLoaded]);

  const setNotice = (notices: TNotice) => {
    dispatch(noticeActions.success({ notices }));
  };

  const getNotice = useCallback((key: string) => resource[key] || '', [resource]);

  return { notices: resource, setNotice, getNotice, isLoading, isError };
}
