import React, { FunctionComponent, useMemo } from 'react';
import * as Yup from 'yup';
import Form, { IFormValues } from './Form';
import useOnSubmit from 'hooks/useOnSubmit';
import api from 'libs/api';
import * as validations from 'libs/validations';
import useFetchResource from 'hooks/useFetchResource';
import { getIn } from 'formik';
import { IAccount } from 'domain/env/types';
import useCompany from 'hooks/useCompany';
import { validationErrorSerializer } from 'components/Form/Location/FormikField';

const initialValues: IFormValues = {
  name: '',
  phone_number: '',
  time_zone: '',
  is_location_confirmed: true,
  location: {
    postcode: '',
    address: '',
    city: { title: '' },
    region: { title: '', short_code: '' },
    country: { title: '', short_code: '' },
  },
};

const Schema = Yup.object().shape({
  location: validations.location,
  time_zone: validations.timeZone,
});

export interface IProps {
  onSuccess?: (account: IAccount) => void;
}

const ConfirmLocation: FunctionComponent<IProps> = ({ onSuccess }) => {
  const { setCompany, company } = useCompany();

  const { resource } = useFetchResource({
    api: api.company.settings.getOne,
    initialValues,
  });

  const formInitialValues: IFormValues = useMemo(
    () => ({
      location: resource.location,
      time_zone: resource.time_zone,
      phone_number: resource.phone_number || '',
      name: resource.name,
      is_location_confirmed: true,
    }),
    [resource]
  );

  const onSubmit = useOnSubmit<IFormValues>({
    api: api.company.settings.update,
    onSuccess: (response) => {
      const result = getIn(response, ['data', 'resource']);
      setCompany({
        ...company,
        time_zone: result.time_zone,
        is_location_confirmed: true,
      });
      if (onSuccess) {
        onSuccess(result);
      }
    },
    successMessage: 'Company settings updated successfully',
    validationErrorSerializer: validationErrorSerializer('location'),
  });

  return (
    <>
      {formInitialValues.name && (
        <Form initialValues={formInitialValues} validationSchema={Schema} onSubmit={onSubmit} />
      )}
    </>
  );
};

export default ConfirmLocation;
