import { IRenderRoute } from 'hooks/useRoutes';
import { lazy } from 'react';

const accountRoutes: IRenderRoute[] = [
  {
    component: lazy(() => import('pages/Auth/AuthorizedSignUp/UserDetails')),
    path: '/user-details',
    title: 'User Details',
    type: 'read',
  },
  {
    component: lazy(() => import('pages/Auth/AuthorizedSignUp/UserDisabled')),
    path: '/disabled',
    type: 'read',
  },
  {
    component: lazy(() => import('pages/Auth/AuthorizedSignUp/CondoSignUp')),
    path: '/condo-sign-up',
    title: 'Sign Up Into Your Condo',
    type: 'read',
  },
  {
    component: lazy(() => import('pages/Auth/AuthorizedSignUp/CondoSignUp/Finish')),
    path: '/condo-sign-up-finish',
    title: 'Registration Complete',
    type: 'read',
  },
  {
    component: lazy(() => import('pages/Auth/AuthorizedSignUp/AccountSignUp/Finish')),
    path: '/account-sign-up-finish',
    title: 'Create Your Account',
    type: 'read',
  },
  {
    component: lazy(() => import('pages/Auth/AuthorizedSignUp/UserSelfApprove')),
    path: '/self-approve',
    title: 'Approve your info',
    type: 'read',
  },
];

export default accountRoutes;
