import useCommon from 'hooks/useCommon';
import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const InvoiceRedirectPage: FunctionComponent = () => {
  const { guid } = useParams();
  const { isCommonTypeCompany } = useCommon();

  useEffect(() => {
    const redirect = `${process.env.REACT_APP_API_URL}/api/billing${
      isCommonTypeCompany ? '/companies' : ''
    }/invoices/${guid}.pdf`;

    window.location.href = redirect;
    return;
  }, []);
  return null;
};

export default InvoiceRedirectPage;
