import NewsMeta from 'components/Website/Items/ItemMeta';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { PRIMARY_TIME_FORMAT } from 'libs/constants';
import { dateViewFormatter, TDate } from 'libs/dateTimeHelpers';
import React from 'react';

interface IPublishDate {
  date: TDate;
}

const PublishDate = ({ date }: IPublishDate) => (
  <NewsMeta
    icon={<CalendarIcon />}
    text={dateViewFormatter(date, `MMMM dd yyyy, ${PRIMARY_TIME_FORMAT}`)}
  />
);

export default PublishDate;
