import IndentView from 'components/Card/IndentView';
import Flex from 'components/Flex';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import useCommon from 'hooks/useCommon';
import {
  PROJECT_TITLE,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  WEBSITE_FULL_URL,
  WEBSITE_URL,
} from 'libs/constants';
import QuestionHeaderLink from 'pages/App/Header/ButtonLayouts/Question';
import Header from 'pages/App/Header';
import React, { FunctionComponent, useEffect } from 'react';
import ImageLayout, { Container } from 'components/ImageLayout';
import { getIn } from 'formik';
import { useNavigate } from 'react-router-dom';

const AccountSuspended: FunctionComponent = () => {
  const theme = useTheme();
  const { common, type, title } = useCommon();
  const navigate = useNavigate();

  useEffect(() => {
    if (!getIn(common, [type, 'is_suspended'])) {
      navigate('/', { replace: true });
    }
  }, [common, type]);

  return (
    <div>
      <Header />
      <ImageLayout direction="column">
        <Container>
          <QuestionHeaderLink />
          <IndentView title={`The ${title} account has been suspended`}>
            <Text color={theme.colors.dark} className="mt32">
              Please contact your condominium board or management company for details. For all other
              inquiries please contact {PROJECT_TITLE} support at{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> or{' '}
              <a href={`tel:+${SUPPORT_PHONE}`}>{SUPPORT_PHONE}</a> for more details.
            </Text>
            <Text color={theme.colors.dark} className="mt32">
              View all your accounts list <a href={process.env.REACT_APP_URL}>here</a>
            </Text>
            <Flex direction="column" className="mt32">
              <Text color={theme.colors.dark}>Sincerely,</Text>
              <Text className="mt4" color={theme.colors.dark}>
                The {PROJECT_TITLE} Team
              </Text>
              <Text color={theme.colors.dark}>
                {' '}
                <a href={`tel:+${SUPPORT_PHONE}`}>{SUPPORT_PHONE}</a>
              </Text>
              <Text>
                <a href={WEBSITE_FULL_URL}>{WEBSITE_URL}</a>
              </Text>
            </Flex>
          </IndentView>
        </Container>
      </ImageLayout>
    </div>
  );
};

export default AccountSuspended;
