import useCommon from 'hooks/useCommon';
import useProfile from 'hooks/useProfile';
import { THEME_NO_SITE } from 'libs/constants';
import RegisterNotice from 'pages/Auth/UserSignIn/RegisterNotice';
import { Footer, LargeButton } from 'pages/Auth/UserSignIn/styled';
import { Container, Title } from 'pages/JoinTo/styled';
import DisableWebsiteInfo from './DisableWebsiteInfo';
import React, { FunctionComponent, useMemo } from 'react';
import useJoinTo from './useJoinTo';

interface IAuthLayout {
  className?: string;
}

const JoinTo: FunctionComponent<IAuthLayout> = () => {
  const { onJoinHandler } = useJoinTo();
  const {
    isCommonTypeCompany,
    isCommonTypeAccount,
    title,
    common: { account },
  } = useCommon();
  useProfile();
  const label = useMemo(() => (isCommonTypeCompany ? 'company' : 'account'), [isCommonTypeCompany]);

  return (
    <Container justifyContent="center" alignItems="center" direction="column">
      <Title>Join {label}</Title>

      {isCommonTypeAccount && account?.website?.theme === THEME_NO_SITE && (
        <DisableWebsiteInfo account={title} />
      )}

      <LargeButton className="mt32" onClick={onJoinHandler}>
        Join {label}
      </LargeButton>

      <Footer>
        <RegisterNotice />
      </Footer>
    </Container>
  );
};

export default JoinTo;
