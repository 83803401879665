import React, { ReactElement, RefObject } from 'react';
import { AxiosResponse } from 'axios';
import { IOption } from 'components/Form/Select/SingleSelect';
import { IUserMainInfo } from 'components/User/Info';
import { differenceInYears } from 'date-fns';
import configureStore from 'domain/configureStore';
import { getIn } from 'formik';
import { TAllPermissions, TPermissions, TPermissionType } from 'hooks/usePermission';
import { RECURRINGS } from 'libs/constants';
import { zeroMinute } from 'libs/dateTimeHelpers';
import firstLetter from 'libs/firstLetter';
import http from 'libs/http';
import objectToFormdata from 'libs/objectToFormdata';
import isEmpty from 'lodash.isempty';
import isPlainObject from 'lodash.isplainobject';
import { StoreContext } from 'redux-react-hook';
import { SiteThemeProvider } from 'theme/ThemeContext';

export interface IErrors {
  [index: string]: string | IErrors;
}

interface IResponseErrors {
  [index: string]: IResponseErrors;
}

interface IDownloadFile {
  url: string;
  filename?: string;
  viewMode?: boolean;
}

export const checkEmpty = (object: any, defaultValue: any) =>
  typeof object !== 'boolean' && isEmpty(object) ? defaultValue : object;

export function fillEmpty<T>(data: T, values: T): T {
  //@ts-ignore
  (Object.keys(values) as Array<keyof T>).forEach((key) => {
    data[key] = checkEmpty(data[key], values[key]);
  });
  return data;
}

export const capitalize = (value = ''): string => value.charAt(0).toUpperCase() + value.slice(1);

function makeErrors(errors: IResponseErrors): IErrors {
  return Object.keys(errors).reduce((acc: IErrors, item) => {
    if (isPlainObject(errors[item])) {
      acc[item] = makeErrors(errors[item]);
    } else {
      acc[item] = errors[item][0];
    }
    return acc;
  }, {});
}

export function handleApiErrors(error: { response: AxiosResponse } | unknown): IErrors | false {
  if (error && getIn(error, 'response.status') === 422) {
    //@ts-ignore
    const { errors } = error.response.data;
    return makeErrors(errors);
  }
  return false;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function convertToFormData(data: object, prefix: string, images?: string[]) {
  const result = objectToFormdata(data, prefix);
  if (images) {
    images.forEach((imageFieldName) => {
      const currentImage = getIn(data, imageFieldName);
      if (currentImage instanceof Blob) {
        const imageFieldNameArray = imageFieldName.split('.');
        result.set(
          `${prefix}[${imageFieldNameArray.join('][')}]`,
          currentImage,
          `${imageFieldNameArray.pop()}.jpg`
        );
      }
    });
  }

  return result;
}

export const getTokens = (keys: string[], getValue: (key: string) => string | null) =>
  keys.reduce((A, key: string) => ({ ...A, [key]: getValue(key) }), {});

export function makeSearchParamsObject(search: URLSearchParams): {
  [index: string]: string;
} {
  const values: { [index: string]: string } = {};
  search.forEach((value, key) => {
    values[key] = value;
  });
  return values;
}

export function range(from: number, to: number, step = 1): number[] {
  const result = [];
  for (let i = from; i <= to; i += step) {
    result.push(i);
  }
  return result;
}

//@ts-ignore
export const hours: IOption[] = range(0, 23).map((item) => ({
  label: item.toString(),
  value: item,
}));

//@ts-ignore
export const minutes: IOption[] = range(0, 55, 5).map((item) => ({
  label: `${zeroMinute(item)}${item}`,
  value: item,
}));

/**
 * Return hour options with "AM"/"PM" labels
 * 12AM (00:00) - must be the first, 11PH (23:00) - last. 12PM is a 12:00
 */
//@ts-ignore
export const hourOptions = [12].concat(range(1, 11), [24], range(13, 23)).map((item) => ({
  label: item < 13 ? `${item} AM` : `${item - 12} PM`,
  value: item === 12 ? 0 : item === 24 ? 12 : item,
}));

export const downloadFile = ({ url, filename = 'Download.jpg', viewMode }: IDownloadFile) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  if (!viewMode) {
    link.download = filename;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadAuthFile = (url: string, filename: string) => {
  http({
    method: 'GET',
    responseType: 'blob',
    url,
  }).then(({ data }) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    downloadFile({ url: downloadUrl, filename });
  });
};

export const arrayAllUnion = (array: any) =>
  Array.from(new Set(array.reduce((acc: [], item: []) => acc.concat(item))));

export const createPermission = (
  permissions: Partial<TPermissions>,
  dependencies: Array<Partial<TAllPermissions>>
) =>
  //@ts-ignore
  arrayAllUnion(dependencies.map((dependency) => getIn(permissions, [dependency], [])));

export const createStrongPermission = (
  permissions: Partial<TPermissions>,
  dependencies: Partial<TPermissions>
): TPermissionType[] => {
  const isValid = (Object.keys(dependencies) as TPermissionType[]).some((key) =>
    // Check strong permission for each item
    getIn(dependencies, [key], []).every((type: TPermissionType) =>
      getIn(permissions, [key], []).includes(type)
    )
  );

  if (isValid) {
    return arrayAllUnion(
      //@ts-ignore
      (Object.keys(dependencies) as TPermissionType[]).map((key) => getIn(permissions, [key], []))
    ) as TPermissionType[];
  }

  return [];
};

export const getFullName = (user: IUserMainInfo, isShortLastName = true): string => {
  const lastName = getIn(user, 'last_name', '');
  return `${getIn(user, 'first_name', '')} ${isShortLastName ? firstLetter(lastName) : lastName}`;
};

const store = configureStore();
export const storybookWrapper = (children: ReactElement) => (
  <StoreContext.Provider value={store}>
    <SiteThemeProvider>{children}</SiteThemeProvider>
  </StoreContext.Provider>
);

//@ts-ignore
export const recurringScheduleOptions = RECURRINGS.map((item) => ({
  label: capitalize(item),
  value: item,
}));

export const scrollToBottom = (ref: RefObject<HTMLDivElement>) => {
  if (ref && ref.current) {
    const refBlock = ref.current;
    refBlock.scrollTop = refBlock.scrollHeight;
  }
};

export const scrollToBlock = (ref: RefObject<HTMLDivElement>) => {
  ref && ref.current && window.scrollTo(0, ref.current.offsetTop);
};

export const validateRecurring = (
  start: Date,
  end: Date,
  schedule: (typeof RECURRINGS)[number]
): boolean => {
  switch (schedule) {
    case 'daily':
    case 'weekly':
    case 'monthly':
      return differenceInYears(end, start) < 1;
    case 'yearly':
      return differenceInYears(end, start) < 5;
    default:
      return false;
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const changeNullToString = <T extends {}>(object: T, fields: string[]): T => {
  const newObject: { [key: string]: string | number | Date } = {};
  fields.forEach((key) => {
    if (getIn(object, key) === null) {
      newObject[key] = '';
    }
  });
  return { ...object, ...newObject };
};

export const fullUrlPath = (subdomain: string, path = '') =>
  `${window.location.protocol}//${subdomain}${process.env.REACT_APP_FRONTEND_DOMAIN}${
    window.location.port ? `:${window.location.port}` : ''
  }${path}`;

export const buildFullUrl =
  (subdomain: string, path = '') =>
  () => {
    window.location.href = fullUrlPath(subdomain, path);
  };
