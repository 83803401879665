import Flex from 'components/Flex/index';
import { getIn } from 'formik';
import { ReactComponent as Close } from 'icons/close.svg';
import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components/macro';

const Select = {
  IndicatorSeparator: () => null,
  MultiValueContainer: (props: any) => (
    <components.MultiValueContainer {...props}>
      <MultiValueContainerWrapper required={getIn(props, ['data', 'is_required'], false)}>
        {props.data.image && <Image src={props.data.image} alt="avatar" />}
        {props.children}
      </MultiValueContainerWrapper>
    </components.MultiValueContainer>
  ),
  MultiValueRemove: (props: any) => (
    <>
      {props.data.is_required ? (
        <StyledMultiValueRemove />
      ) : (
        <components.MultiValueRemove {...props}>
          <CloseIcon />
        </components.MultiValueRemove>
      )}
    </>
  ),
  Option: (props: any) => (
    <components.Option {...props}>
      <FullFlex justifyContent="space-between" alignItems="center">
        <OptionBody alignItems="center">
          {props.data.image && <OptionImage src={props.data.image} alt="avatar" />}
          {props.children}
        </OptionBody>
        {props.data.right && <Flat>{props.data.right}</Flat>}
      </FullFlex>
    </components.Option>
  ),
};

export default Select;

const Image = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
`;

const CloseIcon = styled(Close)`
  height: 100%;
  g {
    rect {
      transition: 0.3s all;
      fill: ${(props) => props.theme.colors.closeGrey};
    }
  }
`;

const FullFlex = styled(Flex)`
  width: 100%;
`;

const OptionBody = styled(Flex)`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.darkGrey};
  white-space: nowrap;
  margin-right: 10px;
`;

const OptionImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 8px;
`;

const Flat = styled.p`
  font-weight: normal;
  text-align: right;
`;

const MultiValueContainerWrapper = styled(Flex)<{ required: boolean }>`
  border-radius: 30px;
  border: 1px solid ${(props) => props.theme.colors.darkenGrey};
  box-sizing: border-box;
  background-color: ${(props) =>
    props.required ? props.theme.colors.accentNormal : props.theme.colors.optionGrey};
  & > div {
    margin-left: 3px;
    color: ${(props) =>
      props.required ? `${props.theme.colors.snow}` : props.theme.colors.darkGrey};
  }
  max-width: 100%;
`;

const StyledMultiValueRemove = styled.div`
  padding: 15px 2px;
`;
