import fadeIn from 'react-animations/lib/fade-in';
import styled, { keyframes } from 'styled-components/macro';

const fadeInAnimation = keyframes`${fadeIn}`;

const StyledErrorMessage = styled.p`
  animation: 0.3s ${fadeInAnimation};
  margin-left: 22px;
  line-height: 18px;
  font-size: 14px;
  text-align: left;
  color: ${(props) => props.theme.colors.error};
  &:first-letter {
    text-transform: capitalize;
  }
`;

export default StyledErrorMessage;

export const StyledTransparentErrorMessage = styled(StyledErrorMessage)`
  background: transparent;
`;
