import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg';
import React, { FunctionComponent } from 'react';
import useNavigator from 'hooks/useNavigator';
import styled from 'styled-components/macro';

interface IProps {
  className?: string;
  direction: 'left' | 'right';
}

const BackButton: FunctionComponent<IProps> = ({ className, direction = 'right' }) => {
  const { backCallback } = useNavigator();
  return (
    <Component onClick={backCallback} className={className}>
      {direction === 'left' && <ArrowLeft />}
      <span className="text">Back</span>
      {direction === 'right' && <ArrowRight />}
    </Component>
  );
};

const ArrowLeft = styled(ArrowDown)`
  transform: rotate(90deg);
`;

const ArrowRight = styled(ArrowDown)`
  transform: rotate(-90deg);
`;

const Component = styled.button`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
  line-height: 24px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s;
  border: 0;
  @media print {
    display: none;
  }
`;

export default BackButton;
