import { IOption } from 'components/Form/Select/SingleSelect';
import useDocumentTitle from 'hooks/useDocumentTitle';
import api from 'libs/api';
import { fetchData } from 'libs/fetchData';
import { selectSerializer } from 'libs/serializers';
import { buildFullUrl } from 'libs/utils';
import debounce from 'lodash.debounce';
import React, { FunctionComponent, useCallback, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { initialState, reducer, ResultTypes } from './reducer';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import { createAsyncAction } from 'typesafe-actions';

interface IAccountsSearch {
  className?: string;
}

const DOCUMENT_TITLE = 'Search Your Association';

const AccountsSearch: FunctionComponent<IAccountsSearch> = () => {
  useDocumentTitle(DOCUMENT_TITLE);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const query = params.get('query') || '';
  const onSearch = useCallback((value) => navigate({ search: `?query=${value}` }), [navigate]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = {
    getSuggests: createAsyncAction(ResultTypes.REQUEST, ResultTypes.SUCCESS, ResultTypes.FAILURE)<
      void,
      any,
      any
    >(),
  };

  const fetchBuildings = useCallback(
    ({ search }) =>
      fetchData({
        action: actions.getSuggests,
        api: api.account.service.search,
        dispatch,
        params: { params: { search } },
        serializer: (response) => response.data.resources,
      }),
    []
  );
  const debouncedOnChange = useCallback(
    debounce((value?: string) => {
      if (value) {
        fetchBuildings({ search: value });
      }
    }, 300),
    []
  );

  const suggestions: IOption[] = useMemo(
    () => selectSerializer(state.suggests, { labelKey: 'name', valueKey: 'subdomain' }),
    [state.suggests]
  );

  const handleSelectAccount = useCallback((item?: IOption) => {
    if (item) {
      const handler = buildFullUrl(item.value.toString());
      handler();
    }
  }, []);

  return (
    <>
      {query ? (
        <SearchResults
          query={query}
          onSearch={onSearch}
          state={state}
          debouncedOnChange={debouncedOnChange}
          fetchBuildings={fetchBuildings}
          suggestions={suggestions}
          handleSelectAccount={handleSelectAccount}
        />
      ) : (
        <SearchForm
          onSearch={onSearch}
          query={query}
          debouncedOnChange={debouncedOnChange}
          suggestions={suggestions}
          handleSelectAccount={handleSelectAccount}
        />
      )}
    </>
  );
};

export default AccountsSearch;
