import { IAdministrationPageCard } from 'components/AdministrationCards/PageCard';
import { TPermissions } from 'hooks/usePermission';

const getPermissionFromPages = (pages: IAdministrationPageCard[]): Partial<TPermissions> =>
  pages.reduce<Partial<TPermissions>>(
    (accumulator: Partial<TPermissions>, item: IAdministrationPageCard): Partial<TPermissions> => {
      if (item.permission && item.permissionTypes) {
        return Object.assign(accumulator, {
          [item.permission]: item.permissionTypes,
        });
      }

      return accumulator;
    },
    {}
  );

export default getPermissionFromPages;
