import Flex from 'components/Flex';
import Gallery from 'components/Gallery';
import Text from 'components/Text';
import useGallery from 'hooks/useGallery';
import useTheme from 'hooks/useTheme';
import { ReactComponent as FileIcon } from 'icons/file.svg';
import { IMAGE_EXTENSIONS } from 'libs/constants';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { IUserMainInfo } from 'components/User/Info';
import styled from 'styled-components';
import FileBlock, { IFileBlockItem, IFileView } from './FileBlock';

export interface IFilePath {
  id?: number;
  identifier: string;
  url: string;
  url_live_to?: string;
  name?: string;
  profile?: IUserMainInfo;
  created_at?: string;
  is_main?: boolean;
  title?: string;
}

export type TAttachment = IFilePath | File;

export interface IAttachment {
  className?: string;
  files: TAttachment[];
  handleRemove?: (newValues: TAttachment[]) => void;
  handleDownload?: (src: string, fileName: string) => void;
  handleOpenGallary?: (index: number) => void;
  onViewChange?: (index: number) => void;
  label?: string;
  editMode?: boolean;
  fileView?: FunctionComponent<IFileView>;
  wrapperView?: FunctionComponent;
  setMainPhotoIndex?: (name: string, value: number | string) => void;
  mainPhotoIndex?: string | number;
  isShowTooltip?: boolean;
}

export const getFileNameFromPath = (file: IFilePath): string =>
  (file.url.split('/').pop() || '').split('?')[0] || '';

export const getFileCaption = (file: TAttachment): string => {
  if (file instanceof File || file.name) {
    return file.name || '';
  } else {
    return getFileNameFromPath(file);
  }
};

export const getFileName = (file: TAttachment): string => {
  if (file instanceof File) {
    return file.name;
  }
  return getFileNameFromPath(file);
};

const Attachments: FunctionComponent<IAttachment> = ({
  className,
  files,
  handleRemove = () => false,
  handleDownload,
  handleOpenGallary,
  label,
  editMode = false,
  isShowTooltip = true,
  fileView: FileView,
  wrapperView: WrapperView = Wrapper,
  setMainPhotoIndex,
  mainPhotoIndex,
  onViewChange,
}) => {
  const theme = useTheme();
  const { currentImage, viewerIsOpen, openGallery, closeGallery } = useGallery();

  const allFiles: IFileBlockItem[] = useMemo(() => {
    let imageCounter = 1;
    return files.map((file, index: number) => {
      const id = file instanceof File ? file.name : file.id;
      const caption: string = getFileCaption(file);
      const title = file instanceof File ? caption : file.title;
      const profile = file instanceof File ? undefined : file.profile;
      const createdAt = file instanceof File ? undefined : file.created_at;
      const isMain =
        mainPhotoIndex !== undefined
          ? file instanceof File
            ? mainPhotoIndex === caption
            : mainPhotoIndex === id
          : //@ts-ignore
            file?.is_main;
      const filename: string = getFileName(file);
      const ext: string = filename.split('.').pop() || '';
      let imageIndex;
      if (IMAGE_EXTENSIONS.includes(ext.toLowerCase())) {
        imageIndex = imageCounter;
        imageCounter += 1;
      }
      return {
        id,
        caption,
        download: !Boolean(file instanceof File),
        profile,
        createdAt,
        ext,
        imageIndex,
        index,
        isMain,
        title,
        src:
          file instanceof File
            ? URL.createObjectURL(file)
            : `${process.env.REACT_APP_IMAGE_PREFIX}${file.url}`,
      };
    });
  }, [files, mainPhotoIndex]);

  const removeFile = useCallback(
    (index: number) => {
      handleRemove([...files.slice(0, index), ...files.slice(index + 1)]);
    },
    [files]
  );

  const views = useMemo(() => allFiles.filter((item) => item.imageIndex), [allFiles]);

  const handleSetMainPhoto = useCallback(
    (id: any) => () => {
      if (setMainPhotoIndex) setMainPhotoIndex('main_photo_index', id);
    },
    [openGallery]
  );

  const openGalleryWithReload = useCallback((index: number) => {
    if (handleOpenGallary) {
      handleOpenGallary(index);
    }
    openGallery(index);
  }, []);

  return (
    <div hidden={!files || !files.length} className={className}>
      <Flex direction="column">
        {label && (
          <Flex alignItems="center" className="mb4">
            <FileIcon />
            <Text size="xs" weight="bold" color={theme.colors.dark} className="ml12">
              {label}
            </Text>
          </Flex>
        )}
        <WrapperView>
          {allFiles.map((item, index) => (
            <FileBlock
              key={index}
              item={item}
              editMode={editMode}
              handleDownload={handleDownload}
              removeFile={removeFile}
              openGallery={openGalleryWithReload}
              setMainPhoto={handleSetMainPhoto(item.id)}
              isShowTooltip={isShowTooltip}
            >
              {FileView}
            </FileBlock>
          ))}
        </WrapperView>
      </Flex>
      {views && viewerIsOpen && (
        <Gallery
          views={views}
          currentImage={currentImage}
          viewerIsOpen={viewerIsOpen}
          closeGallery={closeGallery}
          onViewChange={onViewChange}
        />
      )}
    </div>
  );
};

export default Attachments;

const Wrapper = styled(Flex)`
  flex-flow: wrap;
`;
