import styled from 'styled-components';

export default styled.div`
  padding: 0 65px 50px 65px;
  box-sizing: border-box;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.darkLight};
    padding-bottom: 20px;
    box-sizing: border-box;
  }
`;
