import Flex from 'components/Flex';
import { IInputComponent, StyledInput } from 'components/Form/Input';
import { WEBSITE_URL } from 'libs/constants';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

const WebAddressField: FunctionComponent<IInputComponent> = (props) => (
  <Root alignItems="center">
    <AddressPart className="mr4">https://</AddressPart>
    <StyledInput {...props} />
    <AddressPart className="ml4">.{WEBSITE_URL}</AddressPart>
  </Root>
);

export default WebAddressField;

const Root = styled(Flex)`
  max-width: 550px;
`;

const AddressPart = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.dark};
`;
