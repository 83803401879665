import styled from 'styled-components';

export default styled.div`
  flex: 1;
  width: 100%;
  max-width: 747px;
  min-height: calc(100vh - 90px);
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
`;
