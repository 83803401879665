import { ErrorMessage, Field } from 'formik';
import React, { useCallback, useState, FunctionComponent } from 'react';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { ElementProps as StripeElementProps } from '@stripe/react-stripe-js';
import StyledErrorMessage from 'components/Form/StyledErrorMessage';
import Wrapper from 'components/Form/Wrapper';
import Label from 'components/Form/Label';

interface IProps {
  name: string;
  label: string;
  error: boolean;
  component: FunctionComponent<StripeElementProps>;
  placeholder?: string;
}

export default function StripeCardField({ name, label, component, placeholder, error }: IProps) {
  const [stripeEvent, setStripeEvent] = useState<StripeElementChangeEvent>();

  const chnageElementHandler = useCallback(
    (event: StripeElementChangeEvent) => setStripeEvent(event),
    [setStripeEvent]
  );

  const validateCardElement = useCallback(() => {
    if (stripeEvent) {
      return stripeEvent.complete ? '' : stripeEvent.error?.message;
    } else {
      return 'Required';
    }
  }, [stripeEvent]);

  return (
    <Wrapper>
      <label>
        <Label>{label}</Label>
        <Field
          component={component}
          options={{ placeholder }}
          validate={validateCardElement}
          name={name}
          onChange={chnageElementHandler}
          error={error}
        />
      </label>
      <ErrorMessage name={name} component={StyledErrorMessage} />
    </Wrapper>
  );
}
