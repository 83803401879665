import useProfile from 'hooks/useProfile';
import useRoutes, { ILink } from 'hooks/useRoutes';
import { NavMenuItem } from 'pages/App/Header/Menu/styled';
import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface IUseLeftMenu {
  toggle?: () => void;
}

function useLeftMenu({ toggle = () => null }: IUseLeftMenu = {}): {
  renderItems: () => JSX.Element;
} {
  const { isAdmin, hasAccess } = useProfile();

  const { mainRoutes } = useRoutes();

  const menuItems = useMemo(
    () =>
      mainRoutes
        .filter((route) => (route.role ? isAdmin(route.role) : true))
        .filter((route) => (route.permission ? hasAccess(route.permission, 'read', false) : true))
        .map((route) => route.link)
        .filter(Boolean),
    [isAdmin, hasAccess]
  );

  const renderItems = useCallback(
    () => (
      <MenuItems>
        {(menuItems as ILink[]).map((item) => (
          <NavMenuItem
            key={item.label}
            onClick={toggle}
            to={item.path}
            as={NavLink}
            data-test={`menu-link-${item.dataTest}`}
          >
            {item.icon}
            <p className="ml24">{item.label}</p>
          </NavMenuItem>
        ))}
      </MenuItems>
    ),
    [menuItems, toggle]
  );

  return { renderItems };
}

export default useLeftMenu;

const MenuItems = styled.div`
  padding: 15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.noticeGrey};
`;
