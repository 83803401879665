import * as Sentry from '@sentry/browser';
import store from 'domain/store';
import { TOAST_LIFETIME } from 'libs/constants';
import ErrorBoundary from 'pages/App/ErrorBoundary';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TunnelProvider } from 'react-tunnels';
import { StoreContext } from 'redux-react-hook';
import './index.css';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';
import GlobalStyles from './theme/globalStyles';
import { SiteThemeProvider } from './theme/ThemeContext';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
ReactDOM.render(
  <CookiesProvider>
    <StoreContext.Provider value={store}>
      <GlobalStyles />
      <ToastContainer autoClose={TOAST_LIFETIME} />
      <TunnelProvider>
        <BrowserRouter>
          <SiteThemeProvider>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </SiteThemeProvider>
        </BrowserRouter>
      </TunnelProvider>
    </StoreContext.Provider>
  </CookiesProvider>,
  document.getElementById('root') || document.createElement('div') // for testing purposes (https://stackoverflow.com/questions/39986178/testing-react-target-container-is-not-a-dom-element)
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
