import Flex from 'components/Flex';
import { headerHeight } from 'pages/App/Header/constants';
import React from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import useLeftMenu from './useLeftMenu';
import WebsiteMenuItem, { WebsiteWrapper } from './WebsiteMenuItem';
import SidebarNotice from 'components/Notices/Sidebar';

const LeftMenuDesktop = () => {
  const { renderItems } = useLeftMenu();

  return (
    <Menu direction="column" justifyContent="space-between">
      <div>
        {renderItems()}
        <WebsiteWrapper>
          <WebsiteMenuItem />
        </WebsiteWrapper>
        <SidebarNotice />
      </div>
      <Footer />
    </Menu>
  );
};

export default LeftMenuDesktop;

const Menu = styled(Flex)`
  width: 256px;
  background-color: ${(props) => props.theme.colors.dark};
  min-height: calc(100vh - ${headerHeight});
  box-sizing: border-box;
  overflow-y: auto;
  ${(props) => props.theme.media.md`
    display: none;
  `}
  @media print {
    display: none;
  }
`;
