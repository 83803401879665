import { IFilePath } from 'components/Attachments';
import { THEME_NO_SITE, WINDOW_WIDTH_SIZES } from 'libs/constants';
import { css, CSSObject, FlattenSimpleInterpolation } from 'styled-components';
import * as Preview from 'theme/Preview';

export const sizes: { [index: string]: number } = {
  lg: WINDOW_WIDTH_SIZES.lg,
  md: WINDOW_WIDTH_SIZES.md,
  sm: WINDOW_WIDTH_SIZES.sm,
  xs: WINDOW_WIDTH_SIZES.xs,
};

export type IThemes =
  | 'ThemeNoSite'
  | 'ThemeDefault'
  | 'ThemeSecond'
  | 'ThemeThird'
  | 'ThemeFourth'
  | 'ThemeFifth'
  | 'ThemeSixth'
  | 'ThemeSeventh'
  | 'ThemeEight'
  | 'ThemeNine'
  | 'ThemeTen'
  | 'ThemeEleven'
  | 'ThemeTwelve'
  | 'ThemeThirteen'
  | 'ThemeFourteen'
  | 'ThemeFifteen'
  | 'ThemeSixteen'
  | 'ThemeSeventeen'
  | 'ThemeEighteen'
  | 'ThemeNineteen';

export type TDisplaySize = keyof typeof sizes;

interface IThemePreview {
  id: number;
  title: string;
  image: string;
  name: IThemes;
}

export interface IThemeBackground {
  image: IFilePath[];
}

export const themes: IThemePreview[] = [
  {
    id: 20,
    image: Preview.NoSite,
    name: THEME_NO_SITE,
    title: 'Theme no site',
  },
  {
    id: 1,
    image: Preview.SitePreview1,
    name: 'ThemeDefault',
    title: 'Theme 1',
  },
  {
    id: 2,
    image: Preview.SitePreview2,
    name: 'ThemeSecond',
    title: 'Theme 2',
  },
  {
    id: 3,
    image: Preview.SitePreview3,
    name: 'ThemeThird',
    title: 'Theme 3',
  },
  {
    id: 4,
    image: Preview.SitePreview4,
    name: 'ThemeFourth',
    title: 'Theme 4',
  },
  {
    id: 5,
    image: Preview.SitePreview5,
    name: 'ThemeFifth',
    title: 'Theme 5',
  },
  {
    id: 6,
    image: Preview.SitePreview6,
    name: 'ThemeSixth',
    title: 'Theme 6',
  },
  {
    id: 7,
    image: Preview.SitePreview7,
    name: 'ThemeSeventh',
    title: 'Theme 7',
  },
  {
    id: 8,
    image: Preview.SitePreview8,
    name: 'ThemeEight',
    title: 'Theme 8',
  },
  {
    id: 9,
    image: Preview.SitePreview9,
    name: 'ThemeNine',
    title: 'Theme 9',
  },
  {
    id: 10,
    image: Preview.SitePreview10,
    name: 'ThemeTen',
    title: 'Theme 10',
  },
  {
    id: 11,
    image: Preview.SitePreview11,
    name: 'ThemeEleven',
    title: 'Theme 11',
  },
  {
    id: 12,
    image: Preview.SitePreview12,
    name: 'ThemeTwelve',
    title: 'Theme 12',
  },
  {
    id: 13,
    image: Preview.SitePreview13,
    name: 'ThemeThirteen',
    title: 'Theme 13',
  },
  {
    id: 14,
    image: Preview.SitePreview14,
    name: 'ThemeFourteen',
    title: 'Theme 14',
  },
  {
    id: 15,
    image: Preview.SitePreview15,
    name: 'ThemeFifteen',
    title: 'Theme 15',
  },
  {
    id: 16,
    image: Preview.SitePreview16,
    name: 'ThemeSixteen',
    title: 'Theme 16',
  },
  {
    id: 17,
    image: Preview.SitePreview17,
    name: 'ThemeSeventeen',
    title: 'Theme 17',
  },
  {
    id: 18,
    image: Preview.SitePreview18,
    name: 'ThemeEighteen',
    title: 'Theme 18',
  },
  {
    id: 19,
    image: Preview.SitePreview19,
    name: 'ThemeNineteen',
    title: 'Theme 19',
  },
];

const display = Object.keys(sizes).reduce(
  (
    acc: {
      [index: string]: (displayType: string) => FlattenSimpleInterpolation;
    },
    label: string
  ) => {
    acc[label] = () => css`
      display: none;
      @media (max-width: ${sizes[label]}px) {
        display: block;
      }
    `;

    return acc;
  },
  {}
);

const media = Object.keys(sizes).reduce(
  (
    acc: {
      [index: string]: (...args: [TemplateStringsArray | CSSObject]) => FlattenSimpleInterpolation;
    },
    label: string
  ) => {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(...args)}
      }
    `;

    return acc;
  },
  {}
);

export type TIconFillingRule = number[];

export interface ITheme {
  colors: {
    [index: string]: string;
  };
  media: {
    [index: string]: (...args: [TemplateStringsArray | CSSObject]) => FlattenSimpleInterpolation;
  };
  display: {
    [index: string]: (displayType: string) => FlattenSimpleInterpolation;
  };
  borderRadius: string;
  shadow: {
    common: string;
  };
  fillSvg: (color: string) => string;
  conditionallyFillSvg: (color: string, data: TIconFillingRule) => string;
}

const generalThemeFields: Pick<ITheme, Exclude<keyof ITheme, 'colors'>> = {
  borderRadius: '30px',
  /**
   * @param color
   * @param data - array of numbers (0 or 1) - fills only those paths whose indexes(nth-child) correspond to 1(true) value in this array
   */
  conditionallyFillSvg: (color: string, data: TIconFillingRule) => {
    const strings: string[] = [];
    for (let i = 0; i < data.length; i++) {
      if (Boolean(data[i])) {
        strings.push(`
          &:nth-child(${i + 2}) {
           transition: 0.3s all;
            fill: ${color};
          }
        `);
      }
    }
    return `svg { 
      path {
        ${strings.join(' ')}
      }
    }`;
  },
  display,
  fillSvg: (color: string) => `
    svg {
      path {
        transition: 0.3s all;
        fill: ${color};
      }
    }
  `,
  media,
  shadow: {
    common: 'box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);',
  },
};

export const ThemeDefault: ITheme = {
  colors: {
    accentHover: '#A4C739',
    accentNormal: '#82AA00',
    border: '#BCBCBC',
    closeGrey: '#989898',
    dark: '#333333',
    darkGrey: '#676767',
    darkBlue: '#216dc1',
    darkLight: '#585757',
    darken: '#1C1C1C',
    darkenGrey: '#626262',
    grey300: '#7d7d7d',
    disabled: '#CCCCCC',
    error: '#ED533A',
    errorHover: '#F8553A',
    focusBorder: '#B1B1B1',
    inputText: '#989898',
    menuText: '#C5C5C5',
    lightBlack: '#505050',
    lightAccent: '#E3EEBE',
    lightBlue: '#E6ECF8',
    lightGrey: '#EAEAEA',
    lightenGrey: '#969696',
    modalGrey: '#EAEAEA',
    noticeBackground: '#F7E5D2',
    noticeGrey: '#808080',
    noticeText: '#E77E23',
    optionGrey: '#F4F4F4',
    pageBackground: '#EAECEE',
    secondary: '#373E68',
    separator: '#E2E2E2',
    snow: '#FFFFFF',
    snowTransparent: 'rgba(255,255,255, .7)',
    tooltipBackground: '#555555',
    warning: '#EF8900',
    warningHover: '#FBA738',
    white: '#FFF',
  },
  ...generalThemeFields,
};

export const ThemeNoSite: ITheme = {
  colors: {
    ...ThemeDefault.colors,
  },
  ...generalThemeFields,
};

export const ThemeSecond: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#DB7500',
    accentNormal: '#EF8900',
    secondary: '#005B56',
  },
  ...generalThemeFields,
};

export const ThemeThird: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#F5C35F',
    accentNormal: '#EDBB5F',
    secondary: '#571C3C',
  },
  ...generalThemeFields,
};

export const ThemeFourth: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#F5C35F',
    accentNormal: '#EDBB5F',
    secondary: '#5D302B',
  },
  ...generalThemeFields,
};

export const ThemeFifth: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#D81E00',
    accentNormal: '#C01E00',
    secondary: '#202020',
  },
  ...generalThemeFields,
};

export const ThemeSixth: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#AF0800',
    accentNormal: '#990800',
    secondary: '#BC9B6D',
  },
  ...generalThemeFields,
};

export const ThemeSeventh: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#EEBD37',
    accentNormal: '#E1B136',
    secondary: '#306894',
  },
  ...generalThemeFields,
};

export const ThemeEight: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#D7B787',
    accentNormal: '#D1B182',
    secondary: '#156EC1',
  },
  ...generalThemeFields,
};

export const ThemeNine: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#DDBD8D',
    accentNormal: '#D1B182',
    secondary: '#007279',
  },
  ...generalThemeFields,
};

export const ThemeTen: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#F79000',
    accentNormal: '#EF8900',
    secondary: '#005403',
  },
  ...generalThemeFields,
};

export const ThemeEleven: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#F79000',
    accentNormal: '#EF8900',
    secondary: '#790600',
  },
  ...generalThemeFields,
};

export const ThemeTwelve: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#FFB61D',
    accentNormal: '#FDB41D',
    secondary: '#542217',
  },
  ...generalThemeFields,
};

export const ThemeThirteen: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#F79000',
    accentNormal: '#EF8900',
    secondary: '#766341',
  },
  ...generalThemeFields,
};

export const ThemeFourteen: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#FA9300',
    accentNormal: '#EF8900',
    secondary: '#00477C',
  },
  ...generalThemeFields,
};

export const ThemeFifteen: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#F79100',
    accentNormal: '#EF8900',
    secondary: '#507219',
  },
  ...generalThemeFields,
};

export const ThemeSixteen: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#DBB988',
    accentNormal: '#C7A87B',
    secondary: '#691600',
  },
  ...generalThemeFields,
};

export const ThemeSeventeen: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#EDCC96',
    accentNormal: '#D1B182',
    secondary: '#5E9A95',
  },
  ...generalThemeFields,
};

export const ThemeEighteen: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#F99100',
    accentNormal: '#EF8900',
    secondary: '#4A3728',
  },
  ...generalThemeFields,
};

export const ThemeNineteen: ITheme = {
  colors: {
    ...ThemeDefault.colors,
    accentHover: '#B05F00',
    accentNormal: '#954E00',
    secondary: '#E7A72A',
  },
  ...generalThemeFields,
};

export default ThemeDefault;
