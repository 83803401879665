import { AxiosPromise } from 'axios';
import objectToUrlParams, { ignoreObjectFalsyValues } from 'libs/objectToUrlParams';
import http from './http';

// eslint-disable-next-line @typescript-eslint/ban-types
export type TApi = (params?: object) => AxiosPromise<any>;

// Function helper returned function that return promise;
function api(method: string, url: ((args: any) => string) | string) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (args?: object) =>
    http({
      method,
      url: typeof url === 'function' ? url(args) : url,
      ...args,
    });
}

export type TApiMethodNames = 'create' | 'delete' | 'getAll' | 'getOne' | 'update' | 'edit';

const getRESTMethods = (
  path: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): { [K in TApiMethodNames]: (args?: object) => AxiosPromise<any> } => ({
  create: api('post', path),
  delete: api('delete', (params) => `${path}/${params.id}`),
  getAll: api('get', path),
  getOne: api('get', (params) => `${path}/${params.id}`),
  update: api('put', (params) => `${path}/${params.id}`),
  edit: api('get', (params) => `${path}/${params.id}/edit`),
});

const Api = {
  account: {
    amenities: {
      ...getRESTMethods('amenities'),
      archive: api('delete', (params) => `amenities/${params.id}/archive`),
      restore: api('put', (params) => `amenities/${params.id}/restore`),
      bookings: {
        ...getRESTMethods('amenity_bookings'),
        create: api('post', (params) => `amenities/${params.id}/amenity_bookings`),
        updateInfo: api('put', (params) => `amenity_bookings/${params.id}/update_info`),
        approve: api('put', (params) => `amenity_bookings/${params.id}/approve`),
        events: api(
          'get',
          (params) =>
            `amenities/${params.id}/amenity_bookings?start_date=${params.start_date}&end_date=${params.end_date}`
        ),
        validateRecurring: api(
          'post',
          (params) => `amenities/${params.id}/amenity_bookings/validate_recurring`
        ),
        nearest: api(
          'get',
          (params) => `amenities/${params.id}/amenity_bookings/nearest?date=${params.date}`
        ),
      },
    },
    auth: {
      signUp: {
        condo: {
          create: api('post', 'auth/profiles') /** CondoSignUp */,
        },
        details: {
          create: api('put', 'auth/profiles/details') /** Profile additional info (details) */,
        },
        finish: {
          step1: api('post', 'auth/accounts/relations'),
          step2: api('post', 'auth/admin/profiles'),
          // create: api('post', 'auth/admin/profiles'),
          // step2: api('post', 'accounts/relations'),
        },
        start: api('post', 'auth/associations/sign_up'),
      },
      profile: {
        update: api('put', 'auth/profiles'),
      },
    },
    billing: {
      invoices: {
        getAll: api('get', 'billing/invoices'),
        getOne: api('get', (params) => `billing/invoices/${params.guid}.json`),
        payment: {
          credit: {
            create: api(
              'post',
              (params) => `billing/annual_invoices/${params.guid}/credit_card_payment_type`
            ),
          },
          debit: {
            create: api(
              'post',
              (params) => `billing/annual_invoices/${params.guid}/debit_card_payment_type`
            ),
          },
        },
      },
      payment: {
        credit: {
          create: api(
            'post',
            (params) => `billing/accounts/${params.guid}/credit_card_payment_types`
          ),
        },
        debit: {
          create: api(
            'post',
            (params) => `billing/accounts/${params.guid}/debit_card_payment_types`
          ),
        },
      },
    },
    buildings: {
      ...getRESTMethods('buildings'),
    },
    classifieds: {
      ...getRESTMethods('classified/posts'),
      comments: {
        create: api('post', (params) => `classified/posts/${params.id}/comments`),
        delete: api(
          'delete',
          (params) => `classified/posts/${params.id}/comments/${params.commentId}`
        ),
        getAll: api('get', (params) => `classified/posts/${params.id}/comments`),
        update: api(
          'put',
          (params) => `classified/posts/${params.id}/comments/${params.commentId}`
        ),
        approve: api(
          'post',
          (params) => `classified/posts/${params.id}/comments/${params.commentId}/approve`
        ),
        reject: api(
          'delete',
          (params) => `classified/posts/${params.id}/comments/${params.commentId}/reject`
        ),
      },
      publish: api('post', (params) => `classified/posts/${params.id}/publish`),
      approve: api('post', (params) => `classified/posts/${params.id}/approve`),
      reject: api('delete', (params) => `classified/posts/${params.id}/reject`),
    },
    dashboard: {
      getAll: api('get', 'dashboard'),
    },
    document_categories: {
      getAll: api('get', 'document_categories'),
    },
    documents: {
      archive: api(
        'delete',
        (params) =>
          `documents/archive?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
      restore: api(
        'put',
        (params) =>
          `documents/restore?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
      categories: {
        ...getRESTMethods('document_categories'),
      },
      ...getRESTMethods('documents'),
      edit: api('get', (params) => `documents/${params.id}/edit`),
    },
    events: {
      ...getRESTMethods('events'),
      getAll: api(
        'get',
        (params) => `events?start_at=${params.start_date}&finish_at=${params.end_date}`
      ),
    },
    features: {
      get: api('get', 'accounts/settings'),
      update: api('put', 'accounts/settings'),
    },
    groups: {
      ...getRESTMethods('groups'),
      permissions: api('get', 'permissions'),
      defaultPermissions: api('get', (params) => `groups/${params.id}/default_permissions`),
      users: {
        getAll: api('get', 'groups/profiles_search'),
      },
    },
    invitation: {
      getOne: api('get', 'accounts/invitation'),
      send: api('patch', 'accounts/invitation'),
      getAll: api('get', 'accounts/invitations'),
      delete: api('delete', (params) => `accounts/invitations/${params.id}`),
    },
    log: {
      getAll: api('get', 'logs'),
      features: api('get', 'logs/features'),
      restore: api('post', (params) => `logs/${params.id}/restore`),
    },
    messageForum: {
      ...getRESTMethods('forum/posts'),
      comments: {
        create: api('post', (params) => `forum/posts/${params.id}/comments`),
        delete: api('delete', (params) => `forum/posts/${params.id}/comments/${params.commentId}`),
        getAll: api('get', (params) => `forum/posts/${params.id}/comments`),
        update: api('put', (params) => `forum/posts/${params.id}/comments/${params.commentId}`),
        approve: api(
          'post',
          (params) => `forum/posts/${params.id}/comments/${params.commentId}/approve`
        ),
        reject: api(
          'delete',
          (params) => `forum/posts/${params.id}/comments/${params.commentId}/reject`
        ),
      },
      subscription: {
        create: api('post', (params) => `forum/posts/${params.id}/subscriber`),
        delete: api('delete', (params) => `forum/posts/${params.id}/subscriber`),
      },
      publish: api('post', (params) => `forum/posts/${params.id}/publish`),
      approve: api('post', (params) => `forum/posts/${params.id}/approve`),
      reject: api('post', (params) => `forum/posts/${params.id}/reject`),
      archive: api(
        'delete',
        (params) =>
          `forum/posts/archive?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
      restore: api(
        'put',
        (params) =>
          `forum/posts/restore?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
    },
    news: {
      ...getRESTMethods('news/posts'),
      edit: api('get', (params) => `news/posts/${params.id}/edit`),
      createCalls: api('post', 'news/calls'),
      createMessage: api('post', 'news/messages'),
      getCallsCount: api(
        'get',
        (params) =>
          `news/calls/profiles/count?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
      getMessagesCount: api(
        'get',
        (params) =>
          `news/messages/profiles/count?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
      categories: {
        ...getRESTMethods('news/categories'),
      },
      archive: api(
        'delete',
        (params) =>
          `news/posts/archive?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
      restore: api(
        'put',
        (params) =>
          `news/posts/restore?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
    },
    notices: {
      get: api('get', 'accounts/notices'),
      update: api('put', 'accounts/notices'),
    },
    notifications: {
      phone: {
        create: api('post', 'accounts/notifications_phone_number'),
      },
    },
    options: {
      amenities: {
        getAll: api('get', 'accounts/amenities'),
      },
      buildings: {
        getAll: api('get', (params) => `accounts/buildings?${objectToUrlParams({ data: params })}`),
        getUnits: api(
          'get',
          (params) =>
            `accounts/buildings/${params.buildingId}/units?${objectToUrlParams({ data: params })}`
        ),
      },
      documentCategories: {
        getAll: api(
          'get',
          (params) => `accounts/document_categories?${objectToUrlParams({ data: params })}`
        ),
      },
      newsCategories: {
        getAll: api('get', 'accounts/news_categories'),
      },
      groups: {
        getAll: api('get', 'accounts/groups'),
      },
      projects: {
        getAll: api('get', 'accounts/projects'),
      },
      taskCategories: {
        getAll: api(
          'get',
          (params = {}) =>
            `accounts/task_categories?${objectToUrlParams({
              data: ignoreObjectFalsyValues(params),
            })}`
        ),
      },
      userAccounts: {
        getAll: api('get', 'users/accounts'),
      },
      userGroups: {
        getAll: api('get', 'admin/profiles/administration/groups'),
      },
      userProspectiveRoles: {
        getAll: api('get', 'users/prospective_roles'),
      },
    },
    profile: {
      tabs: {
        info: {
          getOne: api('get', (params) => `profiles/${params.id}/info`),
          update: api('put', (params) => `profiles/${params.id}/info`),
        },
      },
      comments: {
        create: api('post', (params) => `admin/profiles/${params.profileId}/comments`),
        delete: api(
          'delete',
          (params) => `admin/profiles/${params.profileId}/comments/${params.commentId}`
        ),
        getAll: api('get', (params) => `admin/profiles/${params.profileId}/comments`),
        update: api(
          'put',
          (params) => `admin/profiles/${params.profileId}/comments/${params.commentId}`
        ),
      },
    },
    profiles: {
      approving: {
        accept: {
          update: api('put', (params) => `profiles/${params.id}/approve`),
        },
        decline: {
          update: api('delete', (params) => `profiles/${params.id}/approve`),
        },
        getAll: api('get', 'profiles/approve'),
      },
    },
    projects: {
      complete: api('put', (params) => `projects/${params.id}/completed`),
      ...getRESTMethods('projects'),
    },
    reports: {
      announcements: {
        getAll: api('get', (params) => `reports/announcements?from=${params.from}&to=${params.to}`),
      },
      assignments: {
        getAll: api(
          'get',
          (params) => `reports/assignments?${objectToUrlParams({ data: params })}`
        ),
        getProfiles: api('get', '/assignments/profiles'),
      },
      users: {
        getAll: api('get', (params) => `reports/users.json?${objectToUrlParams({ data: params })}`),
      },
    },
    analytics: {
      assignments: {
        stats: api('get', (params) => `assignments/stats?${objectToUrlParams({ data: params })}`),
      },
    },
    requests: {
      admin: {
        complete: api('put', (params) => `assignments/admin_requests/${params.id}/complete`),
        archive: api(
          'delete',
          (params) =>
            `assignments/admin_requests/archive?${objectToUrlParams({
              data: ignoreObjectFalsyValues(params),
            })}`
        ),
        restore: api(
          'put',
          (params) =>
            `assignments/admin_requests/restore?${objectToUrlParams({
              data: ignoreObjectFalsyValues(params),
            })}`
        ),
        ...getRESTMethods('assignments/admin_requests'),
      },
      comments: {
        create: api('post', (params) => `assignments/requests/${params.requestId}/comments`),
        delete: api(
          'delete',
          (params) => `assignments/requests/${params.requestId}/comments/${params.commentId}`
        ),
        getAll: api('get', (params) => `assignments/requests/${params.requestId}/comments`),
        update: api(
          'put',
          (params) => `assignments/requests/${params.requestId}/comments/${params.commentId}`
        ),
      },
      ...getRESTMethods('assignments/requests'),
    },
    service: {
      checkDomain: api('get', 'check_subdomain') /** Check if subdomain is already in use */,
      search: api('get', 'accounts/search') /** Search accounts */,
    },
    settings: {
      delete: api('delete', 'accounts'),
      getOne: api('get', 'accounts'),
      update: api('put', 'accounts'),
    },
    taskCategories: {
      ...getRESTMethods('task_categories'),
      getProfiles: api('get', 'task_categories/profiles'),
    },
    tasks: {
      ...getRESTMethods('/assignments/tasks'),
      comments: {
        create: api('post', (params) => `assignments/tasks/${params.taskId}/comments`),
        delete: api(
          'delete',
          (params) => `assignments/tasks/${params.taskId}/comments/${params.commentId}`
        ),
        getAll: api('get', (params) => `assignments/tasks/${params.taskId}/comments`),
        update: api(
          'put',
          (params) => `assignments/tasks/${params.taskId}/comments/${params.commentId}`
        ),
      },
      complete: api('put', (params) => `assignments/tasks/${params.id}/complete`),
      updateInfo: api('put', (params) => `assignments/tasks/${params.id}/update_info`),
      profiles: api(
        'get',
        (params) =>
          `assignments/tasks/profiles?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
      archive: api(
        'delete',
        (params) =>
          `assignments/tasks/archive?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
      restore: api(
        'put',
        (params) =>
          `assignments/tasks/restore?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
    },
    unitMessages: {
      ...getRESTMethods('unit_messages'),
      edit: api('get', (params) => `unit_messages/${params.id}/edit`),
      admin: {
        getAll: api('get', 'unit_messages') /** Gets all account unit messages */,
      },
      comments: {
        create: api('post', (params) => `unit_messages/${params.id}/comments`),
        delete: api(
          'delete',
          (params) => `unit_messages/${params.id}/comments/${params.commentId}`
        ),
        getAll: api('get', (params) => `unit_messages/${params.id}/comments`),
        update: api('put', (params) => `unit_messages/${params.id}/comments/${params.commentId}`),
      },
      create: api('post', (params) => `units/${params.unitId}/unit_messages`),
      getAll: api('get', (params) => `units/${params.unitId}/unit_messages`),
      profiles: {
        getAll: api('get', (params) => `units/${params.unitId}/unit_messages/profiles`),
      },
    },
    units: {
      ...getRESTMethods('units'),
      profiles: {
        getAll: api('get', (params) => `units/${params.unitId}/profiles`),
      },
      requests: {
        getAll: api('get', (params) => `units/${params.unitId}/requests`),
      },
      files: {
        getAll: api('get', (params) => `units/${params.unitId}/files`),
        delete: api('delete', (params) => `units/${params.unitId}/files/${params.id}`),
        update: api('put', (params) => `units/${params.unitId}/files/${params.id}`),
        get: api('get', (params) => `units/${params.unitId}/files/${params.id}`),
        edit: api('get', (params) => `units/${params.unitId}/files/${params.id}/edit`),
      },
    },
    users: {
      profile: {
        // create: api('post', 'auth/profiles'), // replace condoSignUp
        get: api('get', 'users/profile'), // unusable!?
      },
      statistic: {
        get: api('get', 'users/accounts/statistics') /** AppDomain Dashboard */,
      },
    },
    website: {
      getOne: api('get', 'accounts/website'),
      pages: {
        ...getRESTMethods('accounts/website_pages'),
        edit: api('get', (params) => `accounts/website_pages/${params.id}/edit`),
        unedit: api('post', (params) => `accounts/website_pages/${params.id}/unedit`),
      },
      update: api('put', 'accounts/website'),
    },
    files: {
      getAll: api('get', (params) => `files/${params.entity}/${params.id}`),
    },
    statistics: {
      getAll: api('get', (params) => `statistics/${params.entity}/${params.id}`),
    },
    surveys: {
      vote: api('post', (params) => `surveys/${params.id}/vote`),
      results: api('get', (params) => `surveys/${params.id}/results`),
      getAll: api('get', 'surveys'),
      getOne: api('get', (params) => `surveys/${params.id}`),
      create: api('post', 'surveys'),
      update: api('put', (params) => `surveys/${params.id}`),
      tasks: api(
        'get',
        (params) =>
          `surveys/tasks?${new URLSearchParams(
            ignoreObjectFalsyValues({ id: params.id })
          ).toString()}`
      ),
      news: api(
        'get',
        (params) =>
          `surveys/news?${new URLSearchParams(
            ignoreObjectFalsyValues({ id: params.id })
          ).toString()}`
      ),
      archive: api(
        'delete',
        (params) =>
          `surveys/archive?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
      restore: api(
        'put',
        (params) =>
          `surveys/restore?${objectToUrlParams({
            data: ignoreObjectFalsyValues(params),
          })}`
      ),
    },
  },
  push_notifications: {
    devices: {
      create: api('post', 'push_notifications/devices'),
      getAll: api('get', 'push_notifications/devices'),
    },
    settings: {
      get: api('get', (params) => `/profiles/${params.id}/push_notifications/settings`),
      update: api('put', (params) => `/profiles/${params.id}/push_notifications/settings`),
    },
  },
  /**
   * APP_DOMAIN AUTH ROUTES
   */
  auth: {
    // associationSignUp: api('post', 'auth/association/sign_up'), unusable!?
    checkEmail: api('get', 'auth/check_email'),
    forgotPassword: api('post', 'auth/password'),
    resetPassword: api('put', 'auth/password'),
    signIn: api('post', 'auth/sign_in'),
    signUp: api('post', 'auth/sign_up'),
  },
  billing: {
    payment: {
      check: {
        create: api('post', (params) => `billing/accounts/${params.guid}/check_payment_types`),
      },
      types: {
        getOne: api('get', (params) => `payment_types/${params.guid}`),
      },
    },
    plan: {
      getAll: api('get', 'plans'),
      update: api('put', 'accounts/plans'),
    },
  },
  common: api('get', 'common_information'),
  company: {
    accounts: {
      getAll: api('get', 'companies/accounts'),
    },
    billing: {
      invoices: {
        getAll: api('get', 'billing/companies/invoices'),
      },
      payment: {
        credit: {
          create: api(
            'post',
            (params) => `billing/companies/${params.guid}/credit_card_payment_types`
          ),
        },
        debit: {
          create: api(
            'post',
            (params) => `billing/companies/${params.guid}/debit_card_payment_types`
          ),
        },
      },
    },
    dashboard: {
      getAll: api('get', 'companies/dashboard'),
    },
    groups: {
      ...getRESTMethods('companies/groups'),
      users: {
        getAll: api('get', 'companies/groups/profiles_search'),
      },
    },
    invitation: {
      getOne: api('get', 'companies/invitation'),
      send: api('patch', 'companies/invitation'),
      getAll: api('get', 'companies/invitations'),
      delete: api('delete', (params) => `companies/invitations/${params.id}`),
    },
    options: {
      groups: {
        getAll: api('get', 'companies/groups'),
      },
    },
    profile: {
      password: {
        update: api('put', (params) => `companies/profiles/${params.id}/password`),
      },
      tabs: {
        administration: {
          get: api('get', (params) => `companies/profiles/${params.id}`),
          update: api('put', (params) => `companies/profiles/${params.id}/admin_update`),
        },
        info: {
          getOne: api('get', (params) => `companies/profiles/${params.id}`),
          update: api('put', (params) => `companies/profiles/${params.id}`),
        },
        settings: {
          get: api('get', (params) => `companies/profiles/${params.id}`),
          update: api('put', (params) => `companies/profiles/${params.id}`),
        },
      },
    },
    profiles: {
      approving: {
        accept: {
          update: api('put', (params) => `companies/profiles/${params.id}/approve`),
        },
        decline: {
          update: api('delete', (params) => `companies/profiles/${params.id}/decline`),
        },
        getAll: api('get', 'companies/profiles?approved=false'),
      },
      delete: api('delete', (params) => `companies/profiles/${params.id}`),
      getAll: api('get', 'companies/profiles?approved=true'),
      signUpFinish: api('post', 'companies/profiles'),
    },
    settings: {
      getOne: api('get', `company`),
      update: api('put', `company`),
    },
    website: {
      getOne: api('get', 'companies/website'),
      pages: {
        ...getRESTMethods('companies/website_pages'),
        edit: api('get', (params) => `companies/website_pages/${params.id}/edit`),
        unedit: api('post', (params) => `companies/website_pages/${params.id}/unedit`),
      },
      update: api('put', 'companies/website'),
    },
  },
  location: {
    getCountries: api('get', 'locations/countries'),
    getRegions: api('get', (params) => `locations/countries/${params.countryId}/regions`),
  },
  profile: {
    // accounts: api('get', 'users/accounts'), exist in accounts.ts
    administration: {
      make: api('post', 'auth/admin/users') /** Create resident profile */,
    },
    delete: api('delete', (params) => `profiles/${params.id}`),
    getAll: api(
      'get',
      (params) => `profiles?${objectToUrlParams({ data: ignoreObjectFalsyValues(params) })}`
    ),
    password: {
      update: api('put', (params) => `profiles/${params.id}/password`),
    },
    selfApprove: {
      get: api('get', (params) => `profiles/${params.id}/info`), // same as tabs/info/get
      update: api('put', (params) => `profiles/${params.id}/info`),
    },
    tabs: {
      administration: {
        get: api('get', (params) => `admin/profiles/${params.id}/administration`),
        update: api('put', (params) => `admin/profiles/${params.id}/administration`),
      },
      details: {
        get: api('get', (params) => `profiles/${params.id}/details`),
        update: api('put', (params) => `profiles/${params.id}/details`),
      },
      info: {
        get: api('get', (params) => `profiles/${params.id}/info`),
        update: api('put', (params) => `profiles/${params.id}/info`),
      },
      settings: {
        get: api('get', (params) => `profiles/${params.id}/settings`),
        update: api('put', (params) => `profiles/${params.id}/settings`),
      },
    },
    unsubscribe: {
      getOne: api('get', (params) => `public/unsubscribe/${params.hash}`),
      update: api('put', (params) => `public/unsubscribe/${params.hash}`),
    },
  },
  website: {
    classifieds: {
      getAll: api('get', 'public/classifieds'),
      getOne: api('get', (params) => `public/classifieds/${params.id}`),
    },
    documents: {
      getAll: api('get', 'public/documents'),
    },
    news: {
      getAll: api('get', 'public/announcements'),
      getOne: api('get', (params) => `public/announcements/${params.id}`),
    },
    public: {
      getAll: api('get', 'public/website_pages'),
      getOne: api('get', (params) => `public/website_pages/${params.slug}`),
    },
  },
  places: {
    search: api('get', 'places/autocomplete'),
  },
};

export default Api;
