import { FormikValues, Field, FormikHelpers } from 'formik';
import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { PROJECT_TITLE } from 'libs/constants';
import { invert } from 'lodash';
import Text from 'components/Text';
import HorizontalLine from 'components/Website/HorizontalLine';
import Checkbox from 'components/Form/Checkbox';
import pick from 'lodash.pick';
import { IEmailNotificationSettingsValues } from './Form';

export interface IProps {
  values: FormikValues;
  setFieldValue: FormikHelpers<IEmailNotificationSettingsValues>['setFieldValue'];
  isDisabled?: boolean;
}

const fields: { [index: string]: string } = {
  email_notification_amenity: 'Subscribe to amenity notifications',
  email_notification_classifieds: 'Subscribe to classified notifications',
  email_notification_news: 'Subscribe to news notifications',
  email_notification_documents: 'Subscribe to document notifications',
  email_notification_events: 'Subscribe to calendar notifications',
  email_notification_recurring_events: 'Subscribe to recurring calendar event notifications',
  email_notification_message_forum: 'Subscribe to message forum notifications',
  email_notification_tasks: 'Subscribe to tasks and requests notifications',
  email_notification_completed_task: 'Subscribe to task completion notifications',
  email_notification_unit_message: 'Subscribe to unit message notifications',
};

const notificationFields = Object.keys(fields);

const Fields: FunctionComponent<IProps> = ({ values, setFieldValue, isDisabled = false }) => {
  const isSelectedAll = useMemo(
    () => Object.values(pick(values, notificationFields)).every((value) => !value),
    [values, notificationFields]
  );

  const dependenciesFields = useMemo(
    () => ({
      email_notification_recurring_events: 'email_notification_events',
      email_notification_completed_task: 'email_notification_tasks',
    }),
    []
  );

  useEffect(() => {
    setFieldValue('unsubscribe_all_emails', isSelectedAll);
  }, [isSelectedAll]);

  const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('unsubscribe_all_emails', event.target.checked);
    notificationFields.forEach((field) => {
      setFieldValue(field, !event.target.checked);
    });
  };

  const onChange = useCallback(
    (field: string) => (value: ChangeEvent<HTMLInputElement>) => {
      const checked = value.target.checked;
      setFieldValue(field, checked);
      const deps = checked ? dependenciesFields : invert(dependenciesFields);
      Object.keys(deps).forEach((depsField) => {
        //@ts-ignore
        depsField === field && setFieldValue(deps[depsField], checked);
      });
    },
    [dependenciesFields]
  );

  return (
    <>
      <Text size="xs" className="mt32 mb12">
        By selecting the notifications listed below, you will receive e-mail notifications when such
        information has been posted on {PROJECT_TITLE}.
      </Text>
      <Field
        name="unsubscribe_all_emails"
        component={Checkbox}
        label="Unsubscribe from all the emails"
        type="checkbox"
        className="mt8"
        onClick={onSelectAll}
        disabled={isDisabled}
      />
      <HorizontalLine className="mb24" />
      {notificationFields.map((notificationField) => (
        <Field
          key={notificationField}
          name={notificationField}
          component={Checkbox}
          label={fields[notificationField]}
          type="checkbox"
          className="mt8"
          onChange={onChange(notificationField)}
          disabled={isDisabled}
        />
      ))}
      <HorizontalLine className="mb24" />
      <Text size="xs" className="mt32 mb12">
        By selecting "Receive a daily summary only", you will receive a summary of ALL NOTIFICATIONS
        once a day, not every time they happen.
      </Text>
      <Field
        name="daily_summary"
        component={Checkbox}
        label="Receive a Daily Summary only"
        type="checkbox"
        className="mt8"
        disabled={isDisabled}
      />
    </>
  );
};

export default Fields;
