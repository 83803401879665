import Flex from 'components/Flex';
import Small from 'components/PageTitle/Small';
import Text from 'components/Text';
import { IEntity } from 'pages/AppDomain/Pages/Dashboard/useDashboard';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IAppDomainTable {
  entities: IEntity[];
  className?: string;
  title: string;
}

const AppDomainTable: FunctionComponent<IAppDomainTable> = ({ entities, title, className }) => (
  <TableContainer className={className}>
    {entities.length > 0 && <Title>{title}</Title>}
    {entities.map((entity) => (
      <Row justifyContent="space-between" key={entity.id}>
        <Cell>
          <StyledLink
            href={`${window.location.protocol}//${entity.subdomain}${process.env.REACT_APP_FRONTEND_DOMAIN}:${window.location.port}/dashboard`}
          >
            {entity.name}
          </StyledLink>
        </Cell>
        <Cell alignItems="center">
          <Text align="right">{entity.address || ''}</Text>
        </Cell>
      </Row>
    ))}
  </TableContainer>
);

export default AppDomainTable;

const TableContainer = styled.div`
  margin-bottom: 72px;
`;

const Title = styled(Small)`
  color: ${(props) => props.theme.colors.dark};
  text-align: center;
  margin: 16px 0;
`;

const Cell = styled(Flex)`
  padding: 4px 0;
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.accentNormal};
  text-transform: capitalize;
  padding: 13px 23.5px 13px 0;
`;

const Row = styled(Flex)`
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightGrey};
  &:last-child {
    border-bottom: none;
  }
  ${(props) => props.theme.media.sm`
    flex-direction: column;
    & > div {
      justify-content: center;
      & > a {
        padding: 7px 12px 7px 12px;
        & > p {
          text-align: center;
        }
      }
      & > p {
        text-align: center;
      }
    }
  `};
`;
