import Flex from 'components/Flex';
import OverlaySpinner from 'components/OverlaySpinner';
import RouteComponent from 'components/Route';
import {
  tutorialsShowSelector,
  tutorialsVideoUrlSelector,
  tutorialsDocUrlSelector,
} from 'domain/videoTutorials/selectors';
import useCommon from 'hooks/useCommon';
import useProfile from 'hooks/useProfile';
import useRoutes from 'hooks/useRoutes';
import { HeaderLeftComponent } from 'pages/App/Header';
import VideoTutorialsHeaderLink from 'pages/App/Header/ButtonLayouts/VideoTutorialsHeaderLink';
import { mobileMenuWidth } from 'pages/App/Header/constants';
import AddButtonMenu from 'pages/App/Header/Menu/AddButtonMenu';
import LeftMenuDesktop from 'pages/App/Header/Menu/LeftMenu/DesktopView';
import LeftMenuMobile from 'pages/App/Header/Menu/LeftMenu/MobileView';
import React, { FunctionComponent, Suspense, useMemo } from 'react';
import { Navigate, Routes } from 'react-router';
import { useMappedState } from 'redux-react-hook';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components/macro';
import ConfirmLocation from 'pages/App/ConfirmLocation';
import { Route } from 'react-router-dom';
import UnitsModal from 'pages/Profile/UserInformation/UnitsModal';

const selector = createStructuredSelector({
  show: tutorialsShowSelector,
  videoUrl: tutorialsVideoUrlSelector,
  docUrl: tutorialsDocUrlSelector,
});

const AuthorizedLayout: FunctionComponent = () => {
  const { show, videoUrl, docUrl } = useMappedState(selector);
  const { hasAccess, isNoProfile, profile } = useProfile();
  const { isCommonTypeAccount, isLoading, title } = useCommon();
  const { mainRoutes } = useRoutes();

  const addMenu = useMemo(() => isCommonTypeAccount && <AddButtonMenu />, [isCommonTypeAccount]);

  const routes = useMemo(() => {
    return mainRoutes.reduce((routes: Array<{ path: string; element: JSX.Element }>, item) => {
      item.routes?.forEach((route) => {
        if (!item.permission || hasAccess(item.permission, route.type)) {
          routes.push({
            path: route.path,
            element: <RouteComponent title={title} {...route} />,
          });
        }
      });
      return routes;
    }, []);
  }, [mainRoutes, title, hasAccess]);

  return isLoading ? (
    <OverlaySpinner visible={true} />
  ) : (
    <>
      <ConfirmLocation />
      {isCommonTypeAccount && !isNoProfile && <UnitsModal isOpen={!profile.has_units} />}
      <HeaderLeftComponent>
        <LeftMenuMobileAdaptive />
      </HeaderLeftComponent>
      {addMenu}
      {show && (Boolean(videoUrl) || Boolean(docUrl)) && (
        <VideoTutorialsHeaderLink pathToVideo={videoUrl} pathToDoc={docUrl} />
      )}
      <Flex>
        <LeftMenuDesktop />
        <Content>
          <Suspense fallback={<OverlaySpinner visible={true} />}>
            <Routes>
              {routes.map((route) => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </Suspense>
        </Content>
      </Flex>
    </>
  );
};

export default AuthorizedLayout;

const LeftMenuMobileAdaptive = styled(LeftMenuMobile)`
  margin-left: 24px;
  ${(props) => props.theme.media.xs`
    margin-left: 0;
  `}
`;

const Content = styled.div`
  flex: 1;
  width: calc(100% - ${mobileMenuWidth});
  ${(props) => props.theme.media.md`
    width: 100%;
  `}
`;
