import { videoTutorialsSetActions } from 'domain/videoTutorials/actions';
import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

export default function useVideoTutorials(videoUrl?: string, docUrl?: string) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      videoTutorialsSetActions({
        show: true,
        videoUrl,
        docUrl,
      })
    );

    return () => {
      videoTutorialsSetActions({
        show: false,
      });
    };
  }, [videoUrl, docUrl, dispatch]);
}
