const regexString = `(.+)${process.env.REACT_APP_FRONTEND_DOMAIN}`;
const regex = new RegExp(regexString);

export default function getSubdomain(): { subdomain: false | string } {
  const match = window.location.hostname.match(regex);
  if (!match || match[1] === process.env.REACT_APP_APP_DOMAIN) {
    return { subdomain: false };
  }
  return { subdomain: match[1] };
}
