import useAuth from 'hooks/useAuth';
import getSubdomain from 'libs/subdomain';
import SubDomainPage from 'pages/App/Layouts/SubdomainPage';
import AppDomainPage from 'pages/AppDomain';
import React, { FunctionComponent, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Header from '../Header';

const Main: FunctionComponent = () => {
  const { subdomain } = getSubdomain();
  const { isAuthorized } = useAuth();

  const EntityPage = useMemo(() => (subdomain ? SubDomainPage : AppDomainPage), [subdomain]);

  return (
    <div>
      <Header />
      <Routes>
        <Route path="/*" element={<EntityPage />} />
        <Route element={<Navigate to={isAuthorized ? '/' : '/search'} />} />
      </Routes>
    </div>
  );
};

export default Main;
