import { setAccountAction } from 'domain/env/actions';
import {
  envAccountSelector,
  envIsErrorSelector,
  envIsLoadingSelector,
  envAccountPlanSelector,
} from 'domain/env/selectors';
import { IAccount, IPlan } from 'domain/env/types';
import { COOKIES_LIFETIME } from 'libs/constants';
import cookies from 'libs/cookie';
import getSubdomain from 'libs/subdomain';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { createStructuredSelector } from 'reselect';

export type TSetAccount = (account: IAccount) => void;

const useAccount = (): {
  account: IAccount;
  plan: IPlan;
  setAccount: TSetAccount;
  isLoading: boolean;
  isError: boolean;
} => {
  const dispatch = useDispatch();
  const { subdomain } = getSubdomain();

  const selector = createStructuredSelector({
    isError: envIsErrorSelector,
    isLoading: envIsLoadingSelector,
    plan: envAccountPlanSelector,
    resource: envAccountSelector,
  });

  const { resource, plan, isLoading, isError } = useMappedState(selector);

  const setAccount: TSetAccount = (account) => {
    dispatch(setAccountAction({ account }));
    cookies.set('timezone', account.time_zone, {
      domain: `${subdomain}${process.env.REACT_APP_FRONTEND_DOMAIN}`,
      expires: new Date(Date.now() + COOKIES_LIFETIME),
      path: '/',
    });
  };

  return { account: resource, plan, setAccount, isLoading, isError };
};

export default useAccount;
