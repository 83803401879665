import Flex from 'components/Flex';
import Text from 'components/Text';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import HeaderLinkTemplate, { IconContainer } from '../../HeaderLinkTemplate';
import HeaderNavMenuTemplate, { NavMenuLayout } from '../components/HeaderNavMenuTemplate';
import { useAddButtonMenu } from './useAddButtonMenu';

interface IJoinToHeaderLink {
  className?: string;
}

interface ITemplateButton {
  onClick: () => void;
  active: boolean;
}

const AuthorizedAddButtonHeaderLink: FunctionComponent<IJoinToHeaderLink> = ({
  className = '',
}) => {
  const { isMenuOpen, toggle, nodeRef, menuItems } = useAddButtonMenu();

  const plus = useMemo(
    () => (
      <IconContainer alignItems="center" justifyContent="center">
        <PlusIcon />
      </IconContainer>
    ),
    []
  );

  const desktopComponent = useMemo(
    () => (
      <DesktopButton active={isMenuOpen} onClick={toggle} alignItems="center">
        {plus}
        <Text weight="bold" className="ml24">
          Add
        </Text>
      </DesktopButton>
    ),
    [isMenuOpen]
  );

  const mobileComponent = useMemo(
    () => (
      <ButtonTemplate active={isMenuOpen} onClick={toggle} alignItems="center">
        {plus}
      </ButtonTemplate>
    ),
    [isMenuOpen]
  );

  return Boolean(menuItems.length) ? (
    <HeaderLinkTemplate
      className={className}
      desktopView={desktopComponent}
      mobileView={mobileComponent}
    >
      {isMenuOpen && (
        <HeaderNavMenuTemplate nodeRef={nodeRef} items={menuItems} layout={StyledMenu} />
      )}
    </HeaderLinkTemplate>
  ) : null;
};

export default AuthorizedAddButtonHeaderLink;

const ButtonTemplate = styled(Flex)<ITemplateButton>`
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    background-color: ${(props) => props.theme.colors.optionGrey};
  }
  & > div {
    background-color: ${(props) =>
      props.active ? props.theme.colors.accentNormal : props.theme.colors.inputText};
    &:hover {
      opacity: 1;
    }
  }
  & > p {
    color: ${(props) =>
      props.active ? props.theme.colors.accentNormal : props.theme.colors.inputText};
  }
`;

const DesktopButton = styled(ButtonTemplate)`
  padding: 3px 43px 3px 3px;
  border: 2px solid
    ${(props) => (props.active ? props.theme.colors.accentNormal : props.theme.colors.inputText)};
  justify-content: flex-start;
`;

const StyledMenu = styled(NavMenuLayout)`
  margin-top: 0;
  width: 270px;
  top: 60px;
  ${(props) => props.theme.media.xs`
    right: 0px;
    top: 80px;
  `};
`;
