import { mobileHeaderHeight, mobileMenuWidth } from 'pages/App/Header/constants';
import { NavMenuItem as NavMenuItemParent } from 'pages/App/Header/Menu/styled';
import styled from 'styled-components';
import { TDisplaySize } from 'theme';

interface IMenu {
  boundary: TDisplaySize;
}

interface IMobileMenu extends IMenu {
  close: boolean;
}

export const MobileMenuBody = styled.div<IMobileMenu>`
  ${(props) => props.theme.display[props.boundary]};
  position: fixed;
  top: ${mobileHeaderHeight};
  overflow-y: auto;
  left: 0;
  height: calc(100% - ${mobileHeaderHeight});
  width: ${mobileMenuWidth};
  background-color: ${(props) => props.theme.colors.secondary};
  z-index: 100;
  transition: all 0.5s ease 0s;
  ${(props) =>
    props.close &&
    `
    transform: translate3d(-100%, 0px, 0px);
  `}
`;

export const NavMenuItem = styled(NavMenuItemParent)<{ exact?: boolean }>`
  padding: 15px 10px;
  color: ${(props) => props.theme.colors.snow};
  border-radius: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  ${({ theme }) => theme.fillSvg(theme.colors.snow)};
  &.active {
    background-color: transparent;
    color: ${(props) => props.theme.colors.accentHover};
    ${({ theme }) => theme.fillSvg(theme.colors.accentHover)};
  }
  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.colors.accentHover};
    ${({ theme }) => theme.fillSvg(theme.colors.accentHover)};
  }
  &:last-child {
    border-bottom: 0;
  }
`;
