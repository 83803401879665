import React, { FunctionComponent, useMemo } from 'react';
import * as Yup from 'yup';
import Form, { IFormValues } from './Form';
import useOnSubmit from 'hooks/useOnSubmit';
import api from 'libs/api';
import * as validations from 'libs/validations';
import useAccount from 'hooks/useAccount';
import useFetchResource from 'hooks/useFetchResource';
import { getIn } from 'formik';
import { IAccount } from 'domain/env/types';
import { validationErrorSerializer } from 'components/Form/Location/FormikField';

const accountInitialValues: IFormValues = {
  is_location_confirmed: true,
  time_zone: '',
  name: '',
  number_of_units: 0,
  location: {
    postcode: '',
    address: '',
    city: { title: '' },
    region: { title: '', short_code: '' },
    country: { title: '', short_code: '' },
  },
};

export interface IProps {
  onSuccess?: (account: IAccount) => void;
}

const ConfirmLocation: FunctionComponent<IProps> = ({ onSuccess }) => {
  const { setAccount, account } = useAccount();
  const { resource } = useFetchResource<IFormValues>({
    api: api.account.settings.getOne,
    initialValues: accountInitialValues,
  });
  const initialValues: IFormValues = useMemo(
    () => ({
      is_location_confirmed: true,
      time_zone: resource.time_zone,
      name: resource.name,
      number_of_units: resource.number_of_units,
      location: resource.location,
    }),
    [resource]
  );

  const validationsSchema = Yup.object().shape({
    location: validations.location,
  });

  const onSubmit = useOnSubmit<IFormValues>({
    api: api.account.settings.update,
    onSuccess: (response) => {
      const resource = getIn(response, ['data', 'resource']);
      setAccount({ ...account, is_location_confirmed: true });
      if (onSuccess) {
        onSuccess(resource);
      }
    },
    successMessage: 'Account location updated successfully',
    validationErrorSerializer: validationErrorSerializer('location'),
  });

  return (
    <>
      {initialValues.name && (
        <Form
          initialValues={initialValues}
          validationSchema={validationsSchema}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default ConfirmLocation;
