import { useEffect } from 'react';

export interface IAttributes {
  name: string;
  content: string;
  httpEquiv?: string;
}

export default function useDocumentMeta(attributes: IAttributes, isRemove = true) {
  useEffect(() => {
    const meta: HTMLElement = document.createElement('meta');
    meta.setAttribute('name', attributes.name);
    meta.setAttribute('content', attributes.content);
    if (attributes.httpEquiv) {
      meta.setAttribute('httpEquiv', attributes.httpEquiv);
    }
    document.head.appendChild(meta);
    if (isRemove) {
      return () => {
        meta.remove();
      };
    }
  }, [attributes, isRemove]);
}
