import styled from 'styled-components/macro';

const Card = styled.div`
  background-color: ${(props) => props.theme.colors.snow};
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.06);
`;

export default Card;

export const CardBlock = styled.div`
  background-color: ${(props) => props.theme.colors.snow};
  border-radius: 4px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  &:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const Body = styled.div`
  padding: 32px;
  ${(props) => props.theme.media.xs`
    padding: 16px 24px;
  `}
`;

export const TableCard = styled(Card)`
  overflow-x: auto;
`;

export const SpecificBody = styled.div`
  padding: 32px;
  overflow-x: auto;
  ${(props) => props.theme.media.xs`
    padding: 16px;
  `}
`;

export const BorderedBody = styled(SpecificBody)`
  border-top: 2px solid ${(props) => props.theme.colors.separator};
`;
