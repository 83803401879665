import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { FieldProps, ErrorMessage, FormikErrors } from 'formik';
import styled from 'styled-components';
import Location, { IPlace } from './index';
import Wrapper from 'components/Form/Wrapper';
import Label from 'components/Form/Label';
import StyledErrorMessage from 'components/Form/StyledErrorMessage';
import get from 'lodash.get';
import set from 'lodash.set';
import Field from 'components/Form/Field';
import { UppercaseInput } from '../Input';
import Row from 'components/Row';
import Text from 'components/Text';
import Flex from 'components/Flex';
import SingleSelect from '../Select/SingleSelect';
import api from 'libs/api';
import useFetchResource from 'hooks/useFetchResource';
import { selectSerializer } from 'libs/serializers';

export interface IProps extends FieldProps {
  needApartment: boolean;
  initValue?: string;
  className?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

const FormikField: FunctionComponent<IProps> = ({
  field,
  form: { errors, values, setFieldValue, setFieldTouched },
  initValue,
  className,
  disabled,
  placeholder = 'Search your address...',
  needApartment = false,
}) => {
  const selectHandler = useCallback(
    (item?: IPlace) => {
      const location = item || {
        address: '',
        postcode: get(values, field.name)?.postcode,
      };
      const newLocation = needApartment
        ? {
            ...location,
            apartment: get(values, field.name)?.apartment,
          }
        : location;
      setFieldValue(field.name, newLocation);
      setFieldTouched(`${field.name}.address`, true);
    },
    [values, field, needApartment]
  );

  const changeHandler = useCallback(
    (value?: string) => {
      setFieldValue(field.name, { ...get(values, field.name), address: value });
      setFieldTouched(`${field.name}.address`, true);
    },
    [values, field]
  );

  const { resource: countries } = useFetchResource({
    api: api.location.getCountries,
    initialValues: [],
    serializer: (data) =>
      selectSerializer(data.resources, { labelKey: 'title', valueKey: 'short_code' }),
  });

  const { resource: regions, fetchResource: fetchRegions } = useFetchResource({
    api: api.location.getRegions,
    initialValues: [],
    initialLoad: false,
    serializer: (data) =>
      selectSerializer(data.resources, { labelKey: 'title', valueKey: 'short_code' }),
  });

  useEffect(() => {
    if (get(values, field.name)?.country?.short_code) {
      fetchRegions({ countryId: get(values, field.name).country.short_code });
    }
  }, [get(values, field.name).country]);

  const changeCountryHandler = useCallback(
    (country) => {
      const newLocation = {
        ...get(values, field.name),
        country: { title: country.title, short_code: country.short_code },
        region: { short_code: undefined },
      };
      setFieldValue(field.name, newLocation);
    },
    [get(values, field.name)]
  );

  const changeRegionHandler = useCallback(
    (region) => {
      const newLocation = {
        ...get(values, field.name),
        region: { title: region.title, short_code: region.short_code },
      };
      setFieldValue(field.name, newLocation);
    },
    [get(values, field.name)]
  );

  return (
    <Wrapper className={className}>
      <Label>Address</Label>
      <AddressWrap>
        <Location
          initValue={initValue}
          onSelect={selectHandler}
          onChange={changeHandler}
          error={Boolean(get(errors, `${field.name}.address`))}
          placeholder={placeholder}
          disabled={disabled}
        />
        <ErrorMessage name={`${field.name}.address`} component={StyledErrorMessage} />
      </AddressWrap>
      <Row>
        {needApartment && (
          <Field
            name={`${field.name}.apartment`}
            placeholder="Apartment, suite, etc. (optional)"
            className="mt16"
            label="Apartment"
            disabled={disabled}
          />
        )}
        <Field
          name={`${field.name}.postcode`}
          inputComponent={UppercaseInput}
          placeholder="XXXXX"
          label="Postal / ZIP Code"
          className="mt16"
          disabled={disabled}
        />
      </Row>
      <Flex direction="column">
        <Row>
          <Field
            component={SingleSelect}
            name={`${field.name}.country.short_code`}
            label="Country"
            placeholder="Choose a country"
            options={countries}
            onChange={changeCountryHandler}
            isDisabled={disabled}
          />
          <Field
            name={`${field.name}.region.short_code`}
            component={SingleSelect}
            label="Province / State"
            placeholder="Choose a province"
            options={regions}
            onChange={changeRegionHandler}
            isDisabled={disabled}
          />
        </Row>
        <Field
          name={`${field.name}.city.title`}
          label="City"
          placeholder="City"
          disabled={disabled}
        />
        <Text size="xs">
          Type the address in the Address field and select from the dropdown list, to fill in the
          City, Province, and Country fields
        </Text>
      </Flex>
    </Wrapper>
  );
};

export default FormikField;

export const validationErrorSerializer = (field: string) => (errors: FormikErrors<any>) => {
  if (errors?.location_city) {
    set(errors, field, {
      city: { title: errors.location_city },
      region: { short_code: 'Please, check' },
      country: { short_code: 'Please, check' },
    });
  }
  return errors;
};

const AddressWrap = styled.div`
  position: relative;
`;
