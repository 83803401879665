import ErrorView from 'components/ErrorView';
import React, { FunctionComponent } from 'react';
import Header from '../../Header';
import useCommon from 'hooks/useCommon';
import useNavigator from 'hooks/useNavigator';
import { capitalize } from 'libs/utils';

const InviteExpired: FunctionComponent = () => {
  const { title } = useCommon();
  const { navigateCallback } = useNavigator();

  return (
    <div>
      <Header />
      <ErrorView
        title="Your invite was cancelled"
        message="You may join the account following the registration process."
        actionTitle={capitalize(title)}
        onClick={navigateCallback('/')}
      />
    </div>
  );
};

export default InviteExpired;
