import Breadcrumbs from 'components/Breadcrumbs';
import Flex from 'components/Flex';
import PageTitle from 'components/PageTitle';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface IPageHeader {
  className?: string;
  title: string;
  breadcrumbs: Array<{ title: string; to?: string }>;
}

const PageHeader: FunctionComponent<IPageHeader> = ({ className, title, breadcrumbs }) => (
  <FlexPageHeaderContainer justifyContent="space-between" className={className}>
    <PageTitle>{title}</PageTitle>
    <Breadcrumbs data={breadcrumbs} />
  </FlexPageHeaderContainer>
);

export default PageHeader;

const FlexPageHeaderContainer = styled(Flex)`
  padding-top: 20px;
`;
