import ErrorView from 'components/ErrorView';
import React, { Component, ErrorInfo, ReactNode } from 'react';

interface IProp {
  children: ReactNode;
}

interface IState {
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<IProp, IState> {
  constructor(props: IProp) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  reloadPage() {
    window.location.reload();
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      return (
        <ErrorView
          title="Ooops... Something went wrong."
          message="But don't worry, we're working on it!"
          actionTitle="Reload page"
          onClick={this.reloadPage}
        />
      );
    }

    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
