import { IRoutes } from 'hooks/useRoutes';
import React, { lazy } from 'react';

// icons
import { ReactComponent as AdministrationIcon } from 'icons/menu/administration.svg';
import { ReactComponent as DashboardIcon } from 'icons/menu/dashboard.svg';

const routes: IRoutes[] = [
  {
    link: {
      icon: <DashboardIcon />,
      label: 'Dashboard',
      path: '/dashboard',
    },
    routes: [
      {
        component: lazy(() => import('pages/Company/Dashboard')),
        path: '/dashboard',
        type: 'read',
      },
    ],
  },
  {
    permission: 'user_and_residents',
    routes: [
      {
        component: lazy(() => import('pages/Company/Administration/Users/Invite')),
        path: '/administration/users/invite',
        title: 'Invite Users',
        type: 'read',
      },
      {
        component: lazy(() => import('pages/Company/Administration/Users/Invitations')),
        path: '/administration/users/invitations',
        title: 'Invite Users',
        type: 'read',
      },
      {
        component: lazy(() => import('pages/Company/Administration/Users/List')),
        path: '/administration/users',
        title: 'Users',
        type: 'read',
      },
    ],
  },
  {
    permission: 'website_maintenance',
    routes: [
      {
        component: lazy(() => import('pages/Administration/Website/Create')),
        path: '/administration/website/pages/create',
        title: 'Create Website Page',
        type: 'create',
      },
      {
        component: lazy(() => import('pages/Administration/Website/Edit')),
        path: '/administration/website/pages/:id/edit',
        title: 'Edit Website Page',
        type: 'edit',
      },
      {
        component: lazy(() => import('pages/Administration/Website/List')),
        path: '/administration/website/pages',
        title: 'Website Pages',
        type: 'read',
      },
    ],
  },
  {
    permission: 'user_groups',
    routes: [
      // {
      //   component: lazy(() => import('pages/Company/Administration/Groups/Create')),
      //   path: '/administration/groups/create',
      //   title: 'Create Group',
      //   type: 'create',
      // },
      {
        component: lazy(() => import('pages/Company/Administration/Groups/Edit')),
        path: '/administration/groups/:id/edit',
        title: 'Edit Group',
        type: 'edit',
      },
      {
        component: lazy(() => import('pages/Company/Administration/Groups/Specific')),
        path: '/administration/groups/:id',
        title: 'Group',
        type: 'read',
      },
      {
        component: lazy(() => import('pages/Company/Administration/Groups/List')),
        path: '/administration/groups',
        title: 'Groups',
        type: 'read',
      },
    ],
  },
  {
    permission: 'company_settings',
    routes: [
      {
        component: lazy(() => import('pages/Company/Administration/Settings')),
        path: '/administration/settings',
        title: 'Company Settings',
        type: 'edit',
      },
    ],
  },
  {
    permission: 'invoices',
    routes: [
      {
        component: lazy(() => import('pages/Company/Administration/Invoices')),
        path: '/administration/invoices',
        title: 'Invoices',
        type: 'read',
      },
    ],
  },
  {
    link: {
      icon: <AdministrationIcon />,
      label: 'Administration',
      path: '/administration',
    },
    permission: 'administration',
    routes: [
      {
        component: lazy(() => import('pages/Company/Administration')),
        exact: true,
        path: '/administration',
        title: 'Administration',
        type: 'read',
      },
    ],
  },
  {
    routes: [
      {
        component: lazy(() => import('pages/Company/Profile')),
        path: '/profile/:id',
        title: 'Profile',
        type: 'read',
      },
      {
        component: lazy(() => import('pages/Company/Profile')),
        path: '/profile',
        title: 'Profile',
        type: 'read',
      },
    ],
  },
];

export default routes;
