import BackButton from 'components/BackButton';
import styled from 'styled-components/macro';

const ThemedBackButton = styled(BackButton)`
  ${({ theme }) => theme.fillSvg(theme.colors.accentNormal)};
  ${({ theme }) => `
    color: ${theme.colors.accentNormal};
    background-color: transparent;
    &:hover {
      color: ${theme.colors.accentHover};
      ${theme.fillSvg(theme.colors.accentHover)};
    }
  `}
`;

export default ThemedBackButton;
