import { Placement } from '@popperjs/core';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

export const TOOLTIP_DEFAULT_WIDTH = 170;

interface ITooltipElement {
  placement?: Placement;
  width?: number;
}

interface ITooltip extends ITooltipElement {
  className?: string;
  children: ReactNode;
  tooltipText: string;
}

const TooltipComponent: FunctionComponent<ITooltip> = ({
  className,
  children,
  tooltipText,
  placement = 'top',
  width = TOOLTIP_DEFAULT_WIDTH,
}) => {
  const [referenceElement, setReferenceElement] = useState<null | HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<null | HTMLDivElement>(null);
  const [arrowElement, setArrowElement] = useState<null | HTMLDivElement>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [4, 8],
        },
      },
    ],
  });

  return (
    <Wrapper className={className}>
      <div ref={setReferenceElement}>{children}</div>
      <TooltipContainer
        width={width}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {tooltipText}
        <TooltipArrow ref={setArrowElement} style={styles.arrow} placement={placement} />
      </TooltipContainer>
    </Wrapper>
  );
};

export default TooltipComponent;

const Wrapper = styled.div`
  position: relative;
  &:hover {
    & > div:last-child {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const TooltipBase = styled.div<{ width: number }>`
  padding: 5px 10px;
  background-color: ${(props) => props.theme.colors.tooltipBackground};
  color: ${(props) => props.theme.colors.snow};
  text-align: center;
  border-radius: 6px;
  z-index: 5;
  opacity: 1;
  width: ${(props) => props.width}px;
`;

const TooltipArrow = styled.div<{ placement: Placement }>`
  width: 8px;
  height: 8px;
  &:before {
    position: absolute;
    content: '';
    transform: rotate(45deg);
    background: ${(props) => props.theme.colors.tooltipBackground};
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
  }
`;

const TooltipContainer = styled(TooltipBase)`
  visibility: hidden;
  border-width: 0;
  border-style: solid;

  &[data-popper-placement^='top'] > ${TooltipArrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${TooltipArrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${TooltipArrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${TooltipArrow} {
    left: -4px;
  }
`;
