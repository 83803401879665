import StandartCard from 'components/Card';
import Flex from 'components/Flex';
import { headerHeight } from 'pages/App/Header/constants';
import styled from 'styled-components/macro';

interface IImageLayout {
  image?: string;
}

const ImageLayout = styled(Flex)<IImageLayout>`
  min-height: calc(100vh - ${headerHeight});
  background-color: ${(props) => props.theme.colors.dark};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Container = styled.div`
  max-width: 900px;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  ${(props) => props.theme.media.md`
    max-width: 650px;
  `}
  ${(props) => props.theme.media.sm`
    max-width: 500px;
  `}
  ${(props) => props.theme.media.xs`
    width: unset;
  `}
`;

export const Card = styled(StandartCard)`
  padding: 48px;
  margin-bottom: 30px;
  ${(props) => props.theme.media.xs`
    padding: 25px;
  `}
  .stepper {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.snow};
  font-family: Gotham Pro Bold;
  line-height: 42px;
  font-size: 30px;
  text-align: center;
  letter-spacing: -0.04em;
  margin: 30px 0;
  ${(props) => props.theme.media.xs`
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.02em;
  `}
`;

export const SubTitle = styled.p`
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  text-decoration: none;
  padding-bottom: 20px;
  a {
    font-weight: bold;
    font-family: Gotham Pro;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: ${(props) => props.theme.colors.accentNormal};
  }
`;

export default ImageLayout;
