import styled from 'styled-components';

export interface IClearInput {
  border: boolean;
  placeholder: string;
}

export const ClearInput = styled.input<{ border?: boolean; error?: boolean }>`
  color: ${(props) => props.theme.colors.dark};
  font-weight: 500;
  font-size: 16px;
  padding: 16px 40px 16px 61px;
  border: ${(props) =>
    props.border
      ? `2px solid ${props.error ? props.theme.colors.error : props.theme.colors.border}`
      : 'none'};
  border-radius: ${(props) => props.theme.borderRadius};
  width: 100%;
  box-sizing: border-box;
  outline: none;
`;

export default ClearInput;
