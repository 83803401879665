import useAuth from 'hooks/useAuth';
import AppDomainSignUpPages from 'pages/AppDomain/AppDomainSignUpPages';
import AppDomainPages from 'pages/AppDomain/Pages';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import AccountSignUp from 'pages/Auth/AuthorizedSignUp/AccountSignUp/Start';
import UserSignUp from 'pages/Auth/UserSignUp';
import UserSignUpFinish from 'pages/Auth/UserSignUp/Finish';

const AppDomainPage = () => {
  const { isAuthorized } = useAuth();

  return (
    <Routes>
      <Route element={<AppDomainSignUpPages />}>
        {!isAuthorized && <Route path="/user-sign-up" element={<UserSignUp />} />}
        {!isAuthorized && <Route path="/user-sign-up-finish" element={<UserSignUpFinish />} />}
        {isAuthorized && <Route path="/account-sign-up" element={<AccountSignUp />} />}
        <Route element={<Navigate to="/" />} />
      </Route>

      <Route path="/*" element={<AppDomainPages />} />
      <Route element={<Navigate to={isAuthorized ? '/' : '/search'} />} />
    </Routes>
  );
};

export default AppDomainPage;
