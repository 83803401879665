import ImageLayout, { Container } from 'components/ImageLayout';
import OverlaySpinner from 'components/OverlaySpinner';
import React, { FunctionComponent, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

interface IAuthorizedSignUp {
  className?: string;
}

const AuthorizedSignUp: FunctionComponent<IAuthorizedSignUp> = ({ className = '' }) => (
  <ImageLayout direction="column" className={className}>
    <Container>
      <Suspense fallback={<OverlaySpinner visible={true} />}>
        <Outlet />
      </Suspense>
    </Container>
  </ImageLayout>
);

export default AuthorizedSignUp;
