import Flex from 'components/Flex';
import ImageLayout from 'components/ImageLayout';
import useAuth from 'hooks/useAuth';
import {
  PROJECT_TITLE,
  PROJECT_YEAR,
  WEBSITE_PRIVACY_POLICY,
  WEBSITE_TERMS_CONDITIONS,
} from 'libs/constants';
import AccountsSearch from 'pages/AppDomain/Pages/AccountsSearch';
import CheckEmail from 'pages/AppDomain/Pages/CheckEmail';
import Dashboard from 'pages/AppDomain/Pages/Dashboard';
import ProfileDeleted from 'pages/AppDomain/Pages/ProfileDeleted';
import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import styled from 'styled-components';

interface ISearchLayout {
  className?: string;
}

const SearchLayout: FunctionComponent<ISearchLayout> = ({ className }) => {
  const { isAuthorized } = useAuth();

  return (
    <>
      <ImageLayout className={className} direction="column" justifyContent="space-between">
        <Routes>
          <Route path="/deleted" element={<ProfileDeleted />} />
          <Route path="/check-email" element={<CheckEmail />} />
          <Route path="/search" element={<AccountsSearch />} />
          {isAuthorized && <Route path="/" element={<Dashboard />} />}
          <Route element={<Navigate to="/search" />} />
          {!isAuthorized && <Route path="/*" element={<Navigate to="/sign-in" />} />}
        </Routes>

        <Footer justifyContent="space-between">
          <CondoGenieFlex>
            <FooterText className="mr24">
              © {PROJECT_YEAR} {PROJECT_TITLE}
            </FooterText>
            <FooterText>All Rights Reserved</FooterText>
          </CondoGenieFlex>
          <Flex>
            <FooterText href={WEBSITE_TERMS_CONDITIONS} className="mr24">
              Terms & Conditions
            </FooterText>
            <FooterText href={WEBSITE_PRIVACY_POLICY}>Privacy Policy</FooterText>
          </Flex>
        </Footer>
      </ImageLayout>
    </>
  );
};
export default SearchLayout;

const Footer = styled(Flex)`
  margin-top: 40px;
  padding: 0 54px 70px;
  text-align: center;
  z-index: 1;
  ${(props) => props.theme.media.sm`
    padding: 0px 10px 20px;
    flex-direction: column-reverse;
    align-items: center;
  `}
`;

const FooterText = styled.a`
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.disabled};
`;

const CondoGenieFlex = styled(Flex)`
  ${(props) => props.theme.media.sm`
    margin-top: 8px;
    flex-direction: column;
     &:first-child a {
      margin-right: 0;
     }
  `}
`;
