import React, { FunctionComponent } from 'react';
import { CardNumberElement, CardExpiryElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { StyledInputStyles } from 'components/Form/Input';
import { FormikProps } from 'formik';
import StripeCardField from './StripeCardField';

export const CreditCard: FunctionComponent<FormikProps<any>> = ({ errors, touched }) => (
  <>
    <StripeCardField
      name="credit_card_number"
      placeholder="Credit card"
      label="Credit card number"
      component={CardNumberElementStyled}
      error={Boolean(errors?.credit_card_number) && Boolean(touched.credit_card_number)}
    />
    <StripeCardField
      name="credit_card_expiry"
      label="Credit card expiry"
      component={CardExpiryElementStyled}
      error={Boolean(errors?.credit_card_expiry) && Boolean(touched.credit_card_expiry)}
    />
  </>
);

const CardNumberElementStyled = styled(CardNumberElement)<{ error: boolean }>`
  ${StyledInputStyles}
`;

const CardExpiryElementStyled = styled(CardExpiryElement)<{ error: boolean }>`
  ${StyledInputStyles}
`;
