import React, { FunctionComponent } from 'react';
import Subtitle from 'components/PageTitle/Subtitle';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { TextBlock, PageCard } from 'components/AdministrationCards/PageCard';
import styled from 'styled-components';

interface IProps {
  description: string;
  link: string;
  title: string;
}

const PaymentTypeCard: FunctionComponent<IProps> = ({ link, title, description }) => {
  const theme = useTheme();

  return (
    <Card key={link} direction="column" alignItems="center" justifyContent="space-between">
      <Subtitle>{title}</Subtitle>
      <TextBlock className="mt8">
        <Text color={theme.colors.inputText} align="center">
          {description}
        </Text>
      </TextBlock>
      <Link to={link}>
        <Button className="mt24 mb24">Select this option</Button>
      </Link>
    </Card>
  );
};

export default PaymentTypeCard;

const Card = styled(PageCard)`
  height: auto;
  max-height: none;
`;
