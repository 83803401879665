import clonedeep from 'lodash.clonedeep';
import objectToFormdata from './objectToFormdata';

interface IObjectToUrlParams {
  data: any;
}

export const ignoreObjectFalsyValues = (data: any): any => {
  const clonedData = clonedeep(data);
  Object.keys(clonedData).forEach((key) => {
    if (
      clonedData[key] === undefined ||
      clonedData[key] === null ||
      clonedData[key] === false ||
      (Array.isArray(clonedData[key]) && clonedData[key].length === 0)
    ) {
      delete clonedData[key];
    }
  });

  return clonedData;
};

const objectToUrlParams = ({ data }: IObjectToUrlParams): string =>
  // https://github.com/Microsoft/TypeScript/issues/30584
  // @ts-ignore
  new URLSearchParams(objectToFormdata(data)).toString();

export default objectToUrlParams;
