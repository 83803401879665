import styled from 'styled-components/macro';

export const Body = styled.div`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 48px 48px;
  margin-bottom: 30px;
  ${(props) => props.theme.media.xs`
    padding: 15px;
  `}
`;

export const FormContainer = styled.form`
  position: relative;
`;
