import styled from 'styled-components';

export default styled.article`
  color: ${(props) => props.theme.colors.darkLight};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 16px;
  word-wrap: break-word;
  p {
    padding-bottom: 16px;
    &:last-child {
      padding-bottom: 16px;
    }
  }
`;
