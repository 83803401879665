import { IOption } from 'components/Form/Select/SingleSelect';
import { FormikValues, getIn } from 'formik';
import isPlainObject from 'lodash.isplainobject';
import { useCallback, useMemo } from 'react';

export default function useFilterOptions(
  options: IOption[],
  values: FormikValues,
  path: string,
  fieldName?: string
): (index: number) => IOption[] {
  const field = fieldName ? fieldName : '';
  const selectedGroupIds = useMemo(() => {
    return getIn(values, path).map((item: IOption) =>
      isPlainObject(item) ? getIn(item, field) : item
    );
  }, [getIn(values, path)]);

  return useCallback(
    (index) => {
      const getInPath = getIn(values, path)[index];
      const optionValue = isPlainObject(getInPath) ? getInPath[field] : getInPath;
      return options.filter(
        (option) => option.value === optionValue || !selectedGroupIds.includes(option.value)
      );
    },
    [options, getIn(values, path), selectedGroupIds]
  );
}
