import { AxiosResponse } from 'axios';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Input from 'components/Form/Input';
import OverlaySpinner from 'components/OverlaySpinner';
import Text from 'components/Text';
import { Field, FormikProps } from 'formik';
import api from 'libs/api';
import { PROJECT_TITLE, WEBSITE_URL } from 'libs/constants';
import debounce from 'lodash.debounce';
import { FunctionComponent, useCallback, useEffect } from 'react';
import React from 'react';
import { ToastContent, toast } from 'react-toastify';
import styled from 'styled-components';
import { IAccountSignUpValues } from './index';
import WebAddressField from './WebAddressField';

const Form: FunctionComponent<FormikProps<IAccountSignUpValues>> = ({
  values,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  handleSubmit,
  isSubmitting,
  errors,
}) => {
  const subdomainFieldName = 'subdomain';
  const debouncedOnChange = useCallback(
    debounce(async (subdomain: string) => {
      if (subdomain) {
        try {
          const response: AxiosResponse<{
            subdomain_exist: boolean;
          }> = await api.account.service.checkDomain({
            params: {
              subdomain,
            },
          });
          if (response.data.subdomain_exist) {
            setFieldError(subdomainFieldName, 'Subdomain is already in use');
          }
        } catch (error) {
          toast.error(error as ToastContent);
        }
      }
    }, 300),
    []
  );

  useEffect(() => {
    const webAddress: string = values.name
      .split(' ')
      .join('')
      .replace(' ', '')
      .replace(/[^a-zA-Z 0-9_.]+/g, '');
    if (setFieldValue) {
      setFieldValue(subdomainFieldName, webAddress);
      if (webAddress) {
        setFieldTouched(subdomainFieldName, true);
      }
    }
  }, [values.name]);

  // Need use useEffect instead of "validate" prop on field
  // because "validate" works not correct with debounce
  useEffect(() => {
    debouncedOnChange(values.subdomain);
  }, [values.subdomain]);

  return (
    <form onSubmit={handleSubmit}>
      <OverlaySpinner visible={isSubmitting} />
      <Field
        name="name"
        component={Input}
        label="Enter your property name and pick a web address"
        placeholder="Your Property Name"
        className="mt8 mb8"
      />
      <Tip size="xs" className={errors.name ? 'mt16' : ''}>
        *Every {PROJECT_TITLE} account has its own web address.
        <br />
        For example, if you would like for your website address to be
        <span> https://YourPropertyName.{WEBSITE_URL}</span>, then enter{' '}
        <span>YourPropertyName</span> in the box below. Letters and numbers are allowed only, no
        spaces or special characters.
      </Tip>
      <Field
        name={subdomainFieldName}
        component={Input}
        className="mt8"
        label="Account web address"
        placeholder="Your web address"
        inputComponent={WebAddressField}
      />
      <Field
        name="promo"
        component={StyledInput}
        placeholder="Promo code"
        label="If you have a promotional code, enter it below"
      />
      <Text className="ml32" size="xs">
        You can leave it empty
      </Text>
      <Flex justifyContent="center" className="mt32">
        <Button type="submit">Finish</Button>
      </Flex>
    </form>
  );
};

export default Form;

const StyledInput = styled(Input)`
  margin-bottom: 0;
`;

const Tip = styled(Text)`
  margin-bottom: 16px;
  text-align: left;
  margin-left: 22px;
  span {
    color: ${(props) => props.theme.colors.dark};
  }
`;
