import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const checkIsUnauthorizedUrl = () => {
  return (
    window.location.pathname === '/' ||
    window.location.pathname.indexOf('/public') === 0 ||
    window.location.pathname.indexOf('/suspended') === 0 ||
    window.location.pathname.indexOf('/common-deleted') === 0 ||
    window.location.pathname.indexOf('/404') >= 0
  );
};

const useWebsite = () => {
  const [isWebsite, setIsWebsite] = useState(checkIsUnauthorizedUrl());
  const location = useLocation();

  useEffect(() => setIsWebsite(checkIsUnauthorizedUrl()), [location]);

  return {
    isWebsite,
  };
};

export default useWebsite;
