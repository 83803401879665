import useDocumentTitle from 'hooks/useDocumentTitle';
import { IRenderRoute } from 'hooks/useRoutes';
import useVideoTutorials from 'hooks/useVideoTutorials';
import React, { FunctionComponent, memo } from 'react';
import { Route, Routes } from 'react-router-dom';

const RouteComponent: FunctionComponent<Partial<IRenderRoute>> = ({
  title,
  isTitlePrefix,
  videoTutorialsUrl,
  docTutorialsUrl,
  component,
}) => {
  useDocumentTitle(title, isTitlePrefix);
  useVideoTutorials(videoTutorialsUrl, docTutorialsUrl);
  return (
    <Routes>
      <Route path="/*" Component={component} />
    </Routes>
  );
};

export default memo(RouteComponent);
