import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ImageLayout, { Container } from 'components/ImageLayout';
import CreditCardPage from './CreditCardPage';
import DebitCardPage from './DebitCardPage';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`, { locale: 'en' });

const PublicPaymentPage = () => (
  <Elements stripe={stripePromise}>
    <ImageLayout direction="column">
      <Container>
        <Routes>
          <Route path="/:guid/debit_card" element={<DebitCardPage />} />
          <Route path="/:guid/credit_card" element={<CreditCardPage />} />
          <Route element={<Navigate to="/" />} />
        </Routes>
      </Container>
    </ImageLayout>
  </Elements>
);

export default PublicPaymentPage;
