import React, { ReactElement } from 'react';

import { ReactComponent as CsvIcon } from 'icons/file-types/csv.svg';
import { ReactComponent as PdfIcon } from 'icons/file-types/pdf.svg';
import { ReactComponent as WordIcon } from 'icons/file-types/word.svg';
import { ReactComponent as XlsIcon } from 'icons/file-types/xls.svg';

const extensions: { [key: string]: ReactElement } = {
  csv: <CsvIcon width={80} height={80} />,
  docm: <WordIcon width={80} height={80} />,
  docx: <WordIcon width={80} height={80} />,
  pdf: <PdfIcon width={80} height={80} />,
  xls: <XlsIcon width={80} height={80} />,
  xlsm: <XlsIcon width={80} height={80} />,
  xlsx: <XlsIcon width={80} height={80} />,
};

export default extensions;
