import ActionLink from 'components/ActionLink';
import Button from 'components/Button';
import Flex from 'components/Flex';
import ContentItem from 'components/Website/ContentItem';
import HorizontalLine from 'components/Website/HorizontalLine';
import NewsPreviewItem from 'components/Website/NewsPreviewItem';
import PageTitle from 'components/Website/PageTitle';
import SiteContent from 'components/Website/SiteContent';
import useAuth from 'hooks/useAuth';
import useCommon from 'hooks/useCommon';
import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import { INewsItem } from 'pages/News/List';
import React, { FunctionComponent } from 'react';
import useNavigator from 'hooks/useNavigator';

const NewsList: FunctionComponent = () => {
  const { navigateCallback } = useNavigator();
  const { title: commonTitle } = useCommon();
  const { isAuthorized } = useAuth();
  const { resource: news } = useFetchResource<INewsItem[]>({
    api: api.website.news.getAll,
    initialValues: [],
    serializer: (data) => data.resources,
  });

  return (
    <SiteContent>
      {isAuthorized && (
        <>
          <ContentItem>
            <PageTitle>Private News</PageTitle>
          </ContentItem>
          <ContentItem>
            <HorizontalLine />
          </ContentItem>
          <ContentItem>
            <p>
              <ActionLink to="/news?status=active">Go to the Dashboard</ActionLink> to view all the
              News available for residents of {commonTitle}
            </p>
          </ContentItem>
        </>
      )}
      <ContentItem>
        <PageTitle>Public News</PageTitle>
      </ContentItem>
      <ContentItem>
        <HorizontalLine />
      </ContentItem>
      <ContentItem>
        {news.length < 1 && <p>No items were added yet</p>}
        {news.map((item) => (
          <NewsPreviewItem
            key={item.id}
            title={item.title}
            body={item.content}
            publishDate={item.publicated_at}
            link={`/public/announcements/${item.id}`}
          />
        ))}
      </ContentItem>
      {news.length >= 5 && (
        <Flex justifyContent="center">
          <Button
            padding="8px 55px"
            styleType="primary"
            size="sm"
            className="mt24"
            onClick={navigateCallback('/news?status=active')}
          >
            View all news announcements
          </Button>
        </Flex>
      )}
    </SiteContent>
  );
};

export default NewsList;
