import React, { FunctionComponent, useMemo } from 'react';
import Layout from 'components/Layout';
import PageHeader from 'components/PageHeader';
import { Wrapper } from 'components/AdministrationCards';
import PaymentTypeCard from 'pages/Administration/PlanAndBilling/PaymentTypeCard';
import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import ErrorView from 'components/ErrorView';
import OverlaySpinner from 'components/OverlaySpinner';
import { useParams } from 'react-router-dom';
import useNavigator from 'hooks/useNavigator';

interface IPaymentTypes {
  credit_card: boolean;
  debit_card: boolean;
}

export interface IInvoice {
  id: number;
  guid: string;
  plan_name: string;
  total_price: number;
  payment_types: IPaymentTypes;
  is_paid: boolean;
  is_processing: boolean;
}

interface ICard {
  description: string;
  link: string;
  title: string;
  type: keyof IPaymentTypes;
}

const initialValues = {
  id: 0,
  guid: '',
  plan_name: '',
  total_price: 0,
  payment_types: {
    credit_card: false,
    debit_card: false,
  },
  is_paid: false,
  is_processing: false,
};

const ChoosePaymentType: FunctionComponent = () => {
  const { guid } = useParams();
  const { navigateCallback } = useNavigator();
  const { resource: invoice, isLoading } = useFetchResource<IInvoice>({
    api: api.account.billing.invoices.getOne,
    initialParams: {
      guid,
    },
    initialValues,
  });

  const cards = useMemo(() => {
    const cards = [
      {
        description: 'Pay securely with your Credit Card',
        link: `/invoices/${guid}/credit-card`,
        title: 'Credit Card',
        type: 'credit_card',
      } as ICard,
      {
        description:
          'Pay securely with a single Pre Authorized Debit payment by providing information available on your check / cheque',
        link: `/invoices/${guid}/debit-card`,
        title: 'Check / Cheque',
        type: 'debit_card',
      } as ICard,
    ];
    return cards.filter((card) => invoice.payment_types[card.type]);
  }, [invoice]);

  const isNeedPay = !invoice.is_paid && !invoice.is_processing;

  return (
    <>
      {isLoading ? (
        <OverlaySpinner visible={isLoading} />
      ) : (
        <>
          {!isNeedPay && (
            <ErrorView
              title={
                invoice.is_paid
                  ? 'Invoice Already Paid. Thank you!'
                  : 'The payment method is already provided. Wait for its processing in the payment system.'
              }
              message="You can return to dashboard!"
              actionTitle="GO"
              onClick={navigateCallback('/dashboard')}
            />
          )}
          {isNeedPay && (
            <Layout>
              <PageHeader title="Select A Payment Option" breadcrumbs={[]} />
              <Wrapper>
                {cards.map((card) => (
                  <PaymentTypeCard key={card.type} {...card} />
                ))}
              </Wrapper>
            </Layout>
          )}
        </>
      )}
    </>
  );
};

export default ChoosePaymentType;
