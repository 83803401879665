import Card from 'components/Card';
import Flex, { IFlex } from 'components/Flex';
import Title from 'components/PageTitle/Small';
import { FormikConfig } from 'formik';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import styled, { StyledComponent } from 'styled-components/macro';

export interface IPageForm<T> {
  isEditForm: boolean;
  className?: string;
  onSubmit: FormikConfig<T>['onSubmit'];
  breadcrumbs: Array<{ title: string; to?: string }>;
  title: string;
  submitButtonText: string;
  initialValues: T;
}

export interface IBasic {
  children: ReactNode;
  title?: ReactElement | string;
  rightComponent?: ReactNode;
  bottomComponent?: ReactNode;
  className?: string;
  header?: StyledComponent<FunctionComponent<IFlex>, Record<string, never>>;
}

const Basic: FunctionComponent<IBasic> = ({
  className,
  children,
  title,
  rightComponent,
  bottomComponent,
  header: BasicHeader = Header,
}) => (
  <Card className={className}>
    <BasicHeader direction="column">
      <Flex justifyContent="space-between" alignItems="center">
        {typeof title === 'string' ? (
          <CardTitle fontSize="22px" className="mr20">
            {title}
          </CardTitle>
        ) : (
          title
        )}
        {rightComponent}
      </Flex>
      <Flex>{bottomComponent}</Flex>
    </BasicHeader>
    {children}
  </Card>
);

export default Basic;

export const Header = styled(Flex)`
  padding: 32px;
  border-bottom: 2px solid ${(props) => props.theme.colors.separator};
  ${(props) => props.theme.media.xs`
    padding: 20px;
  `}
`;

export const BasicBody = styled.div`
  padding: 32px;
  ${(props) => props.theme.media.xs`
    padding: 20px;
  `}
`;

export const FormBody = styled(BasicBody)`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

export const WideFormBody = styled(BasicBody)`
  max-width: 75%;
  ${(props) => props.theme.media.xs`
    max-width: none;
  `}
`;

export const BasicFlexCard = styled(Basic)<{ flex?: number; boundary?: string }>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex || 1} !important;
  margin-top: 30px;
  flex-basis: content;
`;

export const CardTitle = styled(Title)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
