import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { videoTutorialsSetActions } from './actions';
import { TVideoTutorialsAction, TVideoTutorialsState } from './types';

const getvideoTutorialsInitialState = () =>
  Immutable({
    show: false,
  });

const initialState: TVideoTutorialsState = getvideoTutorialsInitialState();

export const reducer = (
  state: TVideoTutorialsState = initialState,
  action: TVideoTutorialsAction
) => {
  switch (action.type) {
    case getType(videoTutorialsSetActions):
      return state.merge(action.payload);

    default:
      return state;
  }
};
