import Card, { Body } from 'components/Card';
import Flex from 'components/Flex';
import Title from 'components/PageTitle/Small';
import { ReactComponent as Close } from 'icons/close.svg';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

export interface IBasic {
  title?: string;
  close?: () => void;
}

const Basic: FunctionComponent<IBasic> = ({ children, title, close }) => (
  <Card>
    <Header justifyContent="space-between" alignItems="center">
      <Title>{title}</Title>
      {close && <StyledClose onClick={close} />}
    </Header>
    {children}
  </Card>
);

export default Basic;

const Header = styled(Flex)<{ border?: boolean }>`
  padding: 25px;
`;

export const StyledClose = styled(Close)`
  cursor: pointer;
`;

export const TransparentModalCard = styled(Card)`
  background-color: ${(props) => props.theme.colors.modalGrey};
  border-radius: 8px;
`;

export const TransparentModalBody = styled(Body)`
  padding: 20px;
  border-radius: 8px;
`;

export const ModalCloseIcon = styled.div`
  ${(props) => props.theme.fillSvg(props.theme.colors.snow)}
`;
