import Autocomplete from 'components/Autocomplete';
import Card from 'components/Card';
import Flex from 'components/Flex';
import { IOption } from 'components/Form/Select/SingleSelect';
import { buildFullUrl } from 'libs/utils';
import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components/macro';
import Contact from './Contact';
import { IState } from './reducer';
import { Subtitle, Title } from './styled';

interface ISearchResults {
  query?: string;
  onSearch: (value?: string) => void;
  state: IState;
  debouncedOnChange: (value?: string) => void;
  fetchBuildings: (params: { search?: string }) => void;
  suggestions: IOption[];
  handleSelectAccount: (item?: IOption) => void;
}

const SearchResults: FunctionComponent<ISearchResults> = ({
  query,
  onSearch,
  state,
  debouncedOnChange,
  fetchBuildings,
  suggestions,
  handleSelectAccount,
}) => {
  useEffect(() => {
    fetchBuildings({ search: query });
  }, [query]);

  return (
    <Content direction="column" alignItems="center">
      <Title>Search for Your Condominimum or Association</Title>
      <Body>
        <BlackSubtitle>Enter your Condominimum or Association name below</BlackSubtitle>
        <AutocompleteWrap>
          <Autocomplete
            initialValue={query}
            inputProps={{
              border: true,
              placeholder: 'Search...',
            }}
            onSearch={onSearch}
            suggestions={suggestions}
            onChange={debouncedOnChange}
            onSelect={handleSelectAccount}
          />
        </AutocompleteWrap>
        <CountResults>{state.results.length} associations found</CountResults>
        <Results>
          {state.results.map((item) => (
            <ResultItem
              key={item.id}
              justifyContent="space-between"
              onClick={buildFullUrl(item.subdomain)}
            >
              <ResultTitle>{item.name}</ResultTitle>
              <ResultLocation>
                {item.city} {item.province}
              </ResultLocation>
            </ResultItem>
          ))}
        </Results>
        <BlackContact />
      </Body>
      <FooterOverlay />
    </Content>
  );
};

export default SearchResults;

const Content = styled(Flex)`
  padding-top: 100px;
  ${(props) => props.theme.media.xs`
    padding-top: 40px;
  `}
`;

const Body = styled(Card)`
  padding: 32px 48px;
  z-index: 1;
  max-width: 945px;
  width: 100%;
  text-align: center;
  ${(props) => props.theme.media.xs`
    padding: 15px;
  `}
`;

const Results = styled.div`
  padding-top: 50px;
  padding-bottom: 90px;
  ${(props) => props.theme.media.xs`
    padding-top: 20px;
    padding-bottom: 30px;
  `}
`;

const BlackSubtitle = styled(Subtitle)`
  color: ${(props) => props.theme.colors.dark};
  margin-bottom: 10px;
  margin-top: 15px;
`;

export const CountResults = styled.p`
  margin-top: 20px;
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.colors.inputText};
`;

export const ResultItem = styled(Flex)<{ onClick: () => void }>`
  padding: 17px 0 15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightGrey};
`;

const ResultTitle = styled.a`
  color: ${(props) => props.theme.colors.accentNormal};
  font-weight: bold;
  line-height: 24px;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
`;

const ResultLocation = styled.p`
  line-height: 26px;
  font-size: 16px;
  text-align: right;
  color: ${(props) => props.theme.colors.inputText};
  margin-right: 30px;
  margin-left: 20px;
  ${(props) => props.theme.media.xs`
    margin-right: 0;
  `}
`;

const BlackContact = styled(Contact)`
  line-height: 24px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkGrey};
`;

const FooterOverlay = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.dark};
  bottom: 0;
  left: 0;
  right: 0;
  height: 140px;
  z-index: 0;
`;

const AutocompleteWrap = styled.div`
  width: 100%;
  max-width: 525px;
  margin: 0 auto;
`;
