import Flex from 'components/Flex';
import { IUserMainInfo } from 'components/User/Info';
import NewsAuthor from 'components/Website/Items/ItemAuthor';
import ClassifiedContainer from 'components/Website/Items/ItemContainer';
import ClassifiedSmallTitle from 'components/Website/Items/ItemSmallTitle';
import PublishDate from 'components/Website/Items/PublishDate';
import ReadMore from 'components/Website/Items/ReadMore';
import { getIn } from 'formik';
import firstLetter from 'libs/firstLetter';
import React, { FunctionComponent } from 'react';

export interface IClassifiedPreviewItem {
  title: string;
  publishDate: string;
  author: IUserMainInfo;
  link: string;
}

const ClassifiedPreviewItem: FunctionComponent<IClassifiedPreviewItem> = ({
  title,
  publishDate,
  author,
  link,
}) => {
  return (
    <ClassifiedContainer>
      <Flex>
        <PublishDate date={publishDate} />
        <NewsAuthor
          text={`${getIn(author, ['first_name'])} ${firstLetter(getIn(author, ['last_name']))}`}
        />
      </Flex>
      <ClassifiedSmallTitle>{title}</ClassifiedSmallTitle>
      <ReadMore to={link}>More details</ReadMore>
    </ClassifiedContainer>
  );
};

export default ClassifiedPreviewItem;
