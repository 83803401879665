import Flex from 'components/Flex';
import ImageLayout from 'components/ImageLayout';
import OverlaySpinner from 'components/OverlaySpinner';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { useTheme as useSiteTheme } from 'theme/ThemeContext';
import { ReactComponent as Logo } from 'icons/logo-grey.svg';
import buildingsImage from 'images/buildings-filter.png';
import {
  PROJECT_TITLE,
  PROJECT_YEAR,
  WEBSITE_FULL_URL,
  WEBSITE_PRIVACY_POLICY,
  WEBSITE_TERMS_CONDITIONS,
} from 'libs/constants';
import React, { FunctionComponent, Suspense } from 'react';
import styled from 'styled-components/macro';
import useProfile from 'hooks/useProfile';
import useCommon from 'hooks/useCommon';
import { Outlet } from 'react-router-dom';

interface IAuthLayout {
  className?: string;
}

const AuthLayout: FunctionComponent<IAuthLayout> = () => {
  useProfile();
  const theme = useTheme();
  const siteTheme = useSiteTheme();
  const { title, type, isLoaded, isError } = useCommon();

  return (
    <StyledImageLayout
      direction="column"
      siteBackground={
        siteTheme.backgroundImage
          ? `${process.env.REACT_APP_IMAGE_PREFIX}${siteTheme.backgroundImage}`
          : buildingsImage
      }
    >
      <Flex justifyContent="space-between">
        <ImageContainer direction="column" justifyContent="space-between">
          <ImageMain justifyContent="center" alignItems="center">
            <StyledLogo />
            <Flex direction="column">
              <Title>{title}</Title>
              <SubTitle>{type ? 'CondoGenie - ' : ''}Condo Success System</SubTitle>
            </Flex>
          </ImageMain>
          <ImageFooter justifyContent="center">
            <a href={WEBSITE_FULL_URL} className="mr48">
              <Text color={theme.colors.snow} size="sm">
                Powered by {PROJECT_TITLE}
              </Text>
            </a>
            <a href={WEBSITE_TERMS_CONDITIONS} className="mr48">
              <Text color={theme.colors.snow} size="sm">
                Terms & Conditions
              </Text>
            </a>
            <a href={WEBSITE_PRIVACY_POLICY} className="mr48">
              <Text color={theme.colors.snow} size="sm">
                Privacy and Policy
              </Text>
            </a>
          </ImageFooter>
        </ImageContainer>
        <FormContainer direction="column" alignItems="center" justifyContent="space-between">
          <Suspense fallback={<OverlaySpinner visible={true} />}>
            {(isLoaded || isError) &&
              (type || window.location.href.includes(process.env.REACT_APP_URL as string)) && (
                <Outlet />
              )}
          </Suspense>
          <Text color={theme.colors.inputText} className="mt24" size="xs">
            © {PROJECT_YEAR} {PROJECT_TITLE}. All Right Reserved
          </Text>
        </FormContainer>
      </Flex>
    </StyledImageLayout>
  );
};

export default AuthLayout;

export const StyledImageLayout = styled(ImageLayout)<{ siteBackground: string }>`
  background-image: linear-gradient(0deg, rgba(49, 57, 76, 0.7), rgba(49, 57, 76, 0.7)),
    url('${(props) => props.siteBackground}');
  padding: 0;
  min-height: auto;
`;

const ImageContainer = styled(Flex)`
  width: 100%;
  ${(props) => props.theme.media.md`
    display: none;
  `}
`;

const ImageMain = styled(Flex)`
  height: 100%;
  ${(props) => props.theme.media.lg`
    flex-direction: column;
    text-align: center;
  `}
`;

const ImageFooter = styled(Flex)`
  padding: 35px;
  height: 35px;
`;

const StyledLogo = styled(Logo)`
  width: 150px;
  height: 150px;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.colors.snow};
  font-family: Gotham Pro Bold;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-transform: uppercase;
`;

const SubTitle = styled.h2`
  color: ${(props) => props.theme.colors.inputText};
  font-family: Gotham Pro;
  font-size: 17.5px;
  line-height: 36px;
`;

const FormContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.snow};
  overflow: auto;
  padding: 45px;
  min-height: calc(100vh - 90px);
  width: 619px;
  min-width: 619px;
  ${(props) => props.theme.media.md`
    margin: 0 auto;
    min-width: unset;
  `}
  ${(props) => props.theme.media.xs`
    padding: 40px 15px;
  `}
  h1 {
    text-align: center;
  }
`;
