import { getIn } from 'formik';
import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IEntity {
  id: number;
  address: string;
  subdomain: string;
  name: string;
}

interface IResource {
  accounts: IEntity[];
  companies: IEntity[];
}

const useDashboard: () => {
  accounts: IEntity[];
  companies: IEntity[];
  isLoading: boolean;
} = () => {
  const navigate = useNavigate();
  const { resource, isLoading, fetchResource } = useFetchResource<IResource>({
    api: api.account.users.statistic.get,
    initialLoad: false,
    initialValues: { accounts: [], companies: [] },
    serializer: (data) => data.resources,
  });

  const loadResources = useCallback(async () => {
    const data = await fetchResource();
    if (!getIn(data, ['accounts', 'length'], 0) && !getIn(data, ['companies', 'length'], 0)) {
      navigate('/search', { replace: true });
    }
  }, []);

  useEffect(() => {
    loadResources();
  }, []);

  return { ...resource, isLoading };
};

export default useDashboard;
