import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { noticeActions } from './actions';
import { TNoticeAction, TNoticeState } from './types';

const getNoticeInitialState = () =>
  Immutable({
    error: false,
    isLoaded: false,
    loading: false,
    notices: {
      classifieds_page: null,
      documents: null,
      message_forum: null,
      new_classifieds_post: null,
      new_forum_post: null,
      request_create: null,
      sidebar: null,
    },
  });

const initialState: TNoticeState = getNoticeInitialState();

export const reducer = (state: TNoticeState = initialState, action: TNoticeAction) => {
  switch (action.type) {
    case getType(noticeActions.request):
      return state.merge({
        error: false,
        isLoaded: false,
        loading: true,
      });
    case getType(noticeActions.success):
      return state.merge({
        error: false,
        isLoaded: true,
        loading: false,
        notices: action.payload.notices,
      });
    case getType(noticeActions.failure):
      return state.merge({
        error: true,
        isLoaded: false,
        loading: false,
      });

    default:
      return state;
  }
};
