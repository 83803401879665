import { IGalleryItem } from 'components/Gallery';
import { IMAGE_EXTENSIONS } from 'libs/constants';
import EXTENSION_ICONS from 'libs/extensions';
import { downloadFile } from 'libs/utils';
import React, { FunctionComponent, memo, ReactNode, useCallback, useMemo } from 'react';
import { IUserMainInfo } from 'components/User/Info';
import CommonFileView from './Views/CommonFileView';
import Tooltip from '../Tooltip';
import useAccount from 'hooks/useAccount';

export interface IFileView {
  caption: string;
  profile?: IUserMainInfo;
  createdAt?: string;
  icon?: ReactNode;
  showDownloadIcon?: boolean;
  showRemoveIcon?: boolean;
  handleDownload?: () => void;
  handleRemove?: () => void;
  openGallery?: () => void;
  imageIndex?: number;
  index?: number;
  filePath?: string;
  setMainPhoto?: () => void;
  isMain?: boolean;
  isImage?: boolean;
  title?: string;
  id?: number | string;
}

export interface IFileBlockItem extends IGalleryItem {
  profile?: IUserMainInfo;
  createdAt?: string;
  isMain?: boolean;
  id?: number | string;
  title?: string;
}

export interface IFileBlock {
  item: IFileBlockItem;
  removeFile: (index: number) => void;
  editMode: boolean;
  openGallery: (index: number) => void;
  handleDownload?: (src: string, fileName: string) => void;
  children?: FunctionComponent<IFileView>;
  setMainPhoto?: () => void;
  isShowTooltip?: boolean;
}

const FileBlock: FunctionComponent<IFileBlock> = ({
  item,
  removeFile,
  editMode,
  openGallery,
  children: FileView = CommonFileView,
  handleDownload,
  setMainPhoto,
  isShowTooltip = true,
}) => {
  const { account } = useAccount();
  const open = useCallback(() => {
    if (item.imageIndex) {
      openGallery(item.imageIndex - 1);
    }
  }, [openGallery]);

  const isImage = useMemo(() => IMAGE_EXTENSIONS.includes(item.ext.toLowerCase()), [item]);

  const fileIcon = useMemo(() => {
    if (isImage) {
      return <img height={80} src={item.src} alt="file type" />;
    }
    return EXTENSION_ICONS[item.ext] || null;
  }, [item, isImage]);

  const download = useCallback(() => {
    handleDownload ? handleDownload(item.src, item.caption) : downloadFile({ url: item.src });
  }, [item.src, item.caption]);

  const remove = useCallback(() => removeFile(item.index), [removeFile]);

  const renderView = useCallback(
    (item) => (
      <FileView
        caption={item.caption}
        profile={item.profile}
        createdAt={item.createdAt}
        icon={fileIcon}
        openGallery={IMAGE_EXTENSIONS.includes(item.ext.toLowerCase()) ? open : undefined}
        showDownloadIcon={item.download}
        showRemoveIcon={!item.download || editMode}
        handleDownload={download}
        handleRemove={remove}
        imageIndex={item.imageIndex}
        filePath={item.src}
        setMainPhoto={setMainPhoto}
        isMain={item?.isMain}
        isImage={isImage}
        title={item.title}
        id={item.id}
      />
    ),
    [isImage, download, remove, open, setMainPhoto, fileIcon]
  );

  return account.is_hide_attach_title && isImage && isShowTooltip ? (
    <Tooltip tooltipText={item.caption}>{renderView(item)}</Tooltip>
  ) : (
    renderView(item)
  );
};

export default memo(FileBlock);
