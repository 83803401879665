import useApi from 'hooks/useApi';
import { invoiceEmailsRequired, requiredCheckbox, stringRequired } from 'libs/validations';
import React, { FunctionComponent, useMemo } from 'react';
import * as Yup from 'yup';
import { BankAccount, Confirmation, PropertyInfo, YourContact } from './Cards';
import PaymentMethodLayout from './Layout';
import useAccount from 'hooks/useAccount';
import useCommon from 'hooks/useCommon';
import useOnSubmit from 'hooks/useOnSubmit';
import { FormikErrors, FormikProps } from 'formik';
import { IEmailInvoice } from 'hooks/useEmailInvoices';
import clonedeep from 'lodash.clonedeep';
import usePayments from 'pages/Administration/PlanAndBilling/usePayments';
import get from 'lodash.get';
import { useParams } from 'react-router-dom';
import useNavigator from 'hooks/useNavigator';

interface IDebitFormValues {
  account_number: string;
  bank_name: string;
  company_name: string;
  invoice_emails_attributes: IEmailInvoice[];
  is_agreement_payment: boolean;
  is_authorized_to_payments: boolean;
  institution_number: string;
  legal_name: string;
  transit_number: string;
  user_name: string;
  user_position: string;
  user_email: string;
}

const validationSchema = Yup.object().shape({
  account_number: stringRequired,
  bank_name: stringRequired,
  invoice_emails_attributes: invoiceEmailsRequired,
  institution_number: stringRequired,
  is_agreement_payment: requiredCheckbox,
  is_authorized_to_payments: requiredCheckbox,
  legal_name: stringRequired,
  transit_number: stringRequired,
  user_email: stringRequired,
  user_name: stringRequired,
  user_position: stringRequired,
});

const formCards = [
  {
    component: (props: FormikProps<IDebitFormValues>) => <PropertyInfo {...props} />,
    title: 'Property Information',
  },
  {
    component: () => <BankAccount />,
    title: 'Bank Account Information',
  },
  {
    component: () => <YourContact />,
    title: 'Your contact information',
  },
  {
    component: () => <Confirmation />,
    title: 'Confirmation',
  },
];

const DebitCardPage: FunctionComponent = () => {
  usePayments();
  const { guid } = useParams();
  const { navigateCallback } = useNavigator();
  const { getApiPath } = useApi();
  const { plan } = useAccount();
  const { common, type } = useCommon();
  const values: IDebitFormValues = useMemo(() => {
    return {
      account_number: '',
      bank_name: '',
      company_name: '',
      invoice_emails_attributes: type ? clonedeep(get(common, type).invoice_emails_attributes) : [],
      institution_number: '',
      is_agreement_payment: false,
      is_authorized_to_payments: false,
      legal_name: '',
      transit_number: '',
      user_email: '',
      user_name: '',
      user_position: '',
    };
  }, [common, type]);

  const submitHandler = useOnSubmit({
    api: getApiPath('billing.payment.debit', 'create'),
    deps: [guid],
    onSuccess: navigateCallback('/dashboard'),
    params: { guid },
    serializer: (data: IDebitFormValues) => ({ resource: data }),
    validationErrorSerializer: (errors: FormikErrors<IDebitFormValues>) => ({
      ...errors,
      //@ts-ignore
      invoice_emails_attributes: errors['invoice_emails.email'],
    }),
    successMessage: 'Payment successfully saved',
  });

  return (
    <PaymentMethodLayout<IDebitFormValues>
      plan={plan?.title}
      price={plan ? `${plan?.price} / month` : ''}
      initialValues={values}
      validationSchema={validationSchema}
      title="Pre Authorized Debits / Electronic Fund Transfers"
      formCards={formCards}
      onSubmit={submitHandler}
    />
  );
};

export default DebitCardPage;
