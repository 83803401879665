export enum ResultTypes {
  FAILURE = 'GET_RESULTS_FAILURE',
  REQUEST = 'GET_RESULTS_REQUEST',
  SUCCESS = 'GET_RESULTS_SUCCESS',
}

export type IResource = Array<{
  id: number;
  name: string;
  city: string;
  province: string;
  subdomain: string;
}>;

export interface IState {
  isLoading: boolean;
  results: IResource;
  suggests: IResource;
}

type TAction =
  | { type: ResultTypes.REQUEST }
  | { type: ResultTypes.SUCCESS; payload: IResource }
  | { type: ResultTypes.FAILURE; error: Error };

export const initialState: IState = {
  isLoading: false,
  results: [],
  suggests: [],
};

export function reducer(state: IState, action: TAction): IState {
  switch (action.type) {
    case ResultTypes.REQUEST:
      return { ...state, isLoading: true };

    case ResultTypes.SUCCESS:
      return { ...state, isLoading: false, results: action.payload, suggests: action.payload };

    case ResultTypes.FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
