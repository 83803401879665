import { AppState } from 'domain/reducers';
import { peek } from 'libs/selectors';
import { createSelector } from 'reselect';

const baseSelector = (state: AppState) => state.notices;

export const noticeIsLoadingSelector = createSelector(baseSelector, peek('loading'));

export const noticeIsLoadedSelector = createSelector(baseSelector, peek('isLoaded'));

export const noticeIsErrorSelector = createSelector(baseSelector, peek('error'));

export const noticeSelector = createSelector(baseSelector, peek('notices'));
