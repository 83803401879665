import ImageLayout, { Container } from 'components/ImageLayout';
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Navigate, Route, Routes } from 'react-router';
import CreditCardPaymentType from './CreditCardPaymentType';
import DebitCardPaymentType from './DebitCardPaymentType';
import InvoiceRedirectPage from '../InvoiceRedirectPage';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`, { locale: 'en' });

const PaymentTypeLayout = () => (
  <Elements stripe={stripePromise}>
    <ImageLayout direction="column">
      <Container>
        <Routes>
          <Route path="/:guid/credit-card" element={<CreditCardPaymentType />} />
          <Route path="/:guid/debit-card" element={<DebitCardPaymentType />} />
          <Route path="/:guid" element={<InvoiceRedirectPage />} />
          <Route element={<Navigate to="/" />} />
        </Routes>
      </Container>
    </ImageLayout>
  </Elements>
);

export default PaymentTypeLayout;
