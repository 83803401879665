import OverlaySpinner from 'components/OverlaySpinner';
import Stepper from 'components/Stepper';
import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { IErrors } from 'libs/utils';
import intersection from 'lodash.intersection';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import { MixedSchema } from 'yup';
import runValidationSchema from './runValidationSchema';
import useSteps from './useSteps';

interface IStepProps {
  goToNextStep: () => void;
  goToPrevStep: () => void;
}

export interface IWizardFormOnSubmitHelpers {
  setStep: (step: number) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  validations: object[];
  handleFormErrors: (errors: IErrors | false) => void;
}

export type onSubmitType<T> = (
  values: T,
  actions: FormikActions<T>,
  { setStep, validations, handleFormErrors }: IWizardFormOnSubmitHelpers
) => void;

export type StepType<T> = FunctionComponent<FormikProps<T> & IStepProps> & {
  Schema?: MixedSchema;
};

interface IWizardForm<T> {
  className?: string;
  steps: Array<StepType<T>>;
  onSubmit: onSubmitType<T>;
  initialValues: T;
}

function WizardForm<T>({ steps, onSubmit, ...rest }: IWizardForm<T>) {
  const { step, setStep, goToPrevStep, goToNextStep } = useSteps(1);
  const Component = steps[step - 1];

  const validate = useCallback(
    (values) => {
      return Component.Schema ? runValidationSchema<T>(Component.Schema, values) : {};
    },
    [Component]
  );

  const validations = useMemo(
    () =>
      steps.map((item) => {
        if (item.Schema) {
          // @ts-ignore
          return Object.keys(item.Schema.fields);
        }
        return [];
      }),
    []
  );

  const handleFormErrors = useCallback(
    (actions) => (errors: IErrors | false) => {
      if (errors) {
        validations.some((validation, index) => {
          const shapes = intersection(validation, Object.keys(errors));
          if (shapes.length) {
            setStep(index + 1);
            return true;
          }
          return false;
        });
        actions.setErrors(errors);
      }
    },
    []
  );

  const handleFormikSubmit = useCallback(
    (values, bag) => {
      const isLastPage = step === steps.length;
      if (isLastPage) {
        return onSubmit(values, bag, {
          handleFormErrors: handleFormErrors(bag),
          setStep,
          validations,
        });
      } else {
        bag.setTouched({});
        bag.setSubmitting(false);
        goToNextStep();
      }
    },
    [step]
  );

  return (
    <Container>
      <Stepper className="stepper" stepCount={steps.length} currentStep={step} />
      <Formik validate={validate} onSubmit={handleFormikSubmit} {...rest}>
        {({ handleSubmit, isSubmitting, ...props }: FormikProps<T>) => {
          return (
            <form onSubmit={handleSubmit}>
              <OverlaySpinner visible={isSubmitting} />
              <Component
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                goToNextStep={goToNextStep}
                goToPrevStep={goToPrevStep}
                {...props}
              />
            </form>
          );
        }}
      </Formik>
    </Container>
  );
}

export default WizardForm;

const Container = styled.div`
  position: relative;
`;
