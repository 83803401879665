import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import Modal from 'components/Modal';
import Flex from 'components/Flex';
import Stepper from 'components/Stepper';
import useSteps from 'components/WizardForm/useSteps';
import Step from './Step';
import UserConfirm from 'pages/Profile/UserInformation/ConfirmLocationModal/ConfirmLocation';
import AccountConfirm from 'pages/Administration/AccountSettings/ConfirmLocationModal/ConfirmLocation';
import CompanyConfirm from 'pages/Company/Administration/Settings/ConfirmLocationModal/ConfirmLocation';
import useProfile from 'hooks/useProfile';
import useCommon from 'hooks/useCommon';

const ConfirmLocationModal: FunctionComponent = () => {
  const { step, goToNextStep } = useSteps(1);
  const { hasEveryAccess, profile, isNoProfile } = useProfile();
  const { isCommonTypeAccount, isCommonTypeCompany, common } = useCommon();
  const [isUserConfirm, setUserConfirm] = useState<boolean>(false);
  const [isAccountConfirm, setAccountConfirm] = useState<boolean>(false);
  const [isCompanyConfirm, setCompanyConfirm] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(true);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const steps = useMemo(() => {
    const steps = [];
    if (isUserConfirm || (!isNoProfile && !profile.is_location_confirmed)) {
      steps.push({ title: 'Confirm your mailing address', form: UserConfirm });
      setUserConfirm(true);
    }
    if (
      isAccountConfirm ||
      (isCommonTypeAccount &&
        !common.account?.is_location_confirmed &&
        hasEveryAccess('accounts', ['read', 'edit']))
    ) {
      steps.push({ title: 'Confirm the mailing address for the account', form: AccountConfirm });
      setAccountConfirm(true);
    }
    if (
      isCompanyConfirm ||
      (isCommonTypeCompany &&
        !common.company?.is_location_confirmed &&
        hasEveryAccess('company_settings', ['read', 'edit']))
    ) {
      steps.push({ title: 'Confirm the mailing address for the company', form: CompanyConfirm });
      setCompanyConfirm(true);
    }
    return steps;
  }, [isNoProfile, profile, isCommonTypeAccount, isCommonTypeCompany, common]);

  const currentStep = useMemo(() => {
    if (steps.length) {
      const { title, form: Form } = steps[step - 1];
      return (
        <Step
          title={title}
          form={<Form onSuccess={steps.length === step ? close : goToNextStep} />}
        />
      );
    } else {
      return undefined;
    }
  }, [steps, step, close]);

  return (
    <Modal isOpen={steps.length > 0 && isOpen}>
      <Modal.Basic title="Check your data">
        <ModalBody>
          {steps.length > 1 && (
            <Stepper className="stepper" stepCount={steps.length} currentStep={step} />
          )}
          {currentStep}
        </ModalBody>
      </Modal.Basic>
    </Modal>
  );
};

const ModalBody = styled(Flex)`
  border-top: 2px solid ${(props) => props.theme.colors.lightGrey};
  padding: 30px 60px;
  flex-direction: column;
  ${(props) => props.theme.media.sm`
    padding: 10px 20px;
  `}
`;

export default ConfirmLocationModal;
