import React, { FunctionComponent } from 'react';
import RegistrationApproveTemplate from 'templates/RegistrationApproveTemplate';

const UserSignUpFinish: FunctionComponent = () => {
  const email = new URLSearchParams(window.location.search).get('email');

  return <RegistrationApproveTemplate email={email || ''} />;
};

export default UserSignUpFinish;
