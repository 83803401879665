import useAuth from 'hooks/useAuth';
import UpdatePayment from 'pages/Administration/PlanAndBilling/UpdatePayment';
import InvoiceChoosePaymentType from 'pages/Administration/Invoices/AnnualInvoices/ChoosePaymentType';
import InvoicePaymentTypes from 'pages/Administration/Invoices/AnnualInvoices/PaymentTypeLayout';
import AuthorizedLayout from 'pages/App/Layouts/AuthorizedLayout';
import NotFound from 'pages/App/Layouts/NotFound';
import ConfirmedEmail from 'pages/App/Layouts/Emails/Confirmed';
import ConfirmEmailError from 'pages/App/Layouts/Emails/ConfirmError';
import SignUp from 'pages/Auth/AuthorizedSignUp';
import PaymentMethods from 'pages/Payments/PaymentMethods';
import EmailNotificationsSettings from 'pages/Profile/EmailNotificationsSettings';
import WebsitePage from 'pages/Website';
import React, { FunctionComponent } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import useSubdomainPage from './useSubdomainPage';
import useRoutes from 'hooks/useRoutes';
import RouteComponent from 'components/Route';

const SubDomainPage: FunctionComponent = () => {
  const { isAuthorized } = useAuth();
  const { authRenderRoutes } = useRoutes();

  useSubdomainPage();

  return (
    <Routes>
      <Route path="/invoices/:guid/choose-payment-type" element={<InvoiceChoosePaymentType />} />
      <Route path="/invoices/*" element={<InvoicePaymentTypes />} />
      <Route path="/payment/*" element={<PaymentMethods />} />
      <Route path="/administration/:guid/update-payment" element={<UpdatePayment />} />
      <Route path="/email/notifications/:hash" element={<EmailNotificationsSettings />} />
      <Route path="/public/*" element={<WebsitePage />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/email/confirmed" element={<ConfirmedEmail />} />
      <Route path="/email/confirm-error" element={<ConfirmEmailError />} />
      <Route path="/" element={<WebsitePage />} />
      {isAuthorized && (
        <>
          <Route element={<SignUp />}>
            {authRenderRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <RouteComponent
                    title={route.title}
                    path={route.path}
                    component={route.component}
                  />
                }
              />
            ))}
          </Route>
          <Route path="/*" element={<AuthorizedLayout />} />
        </>
      )}
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default SubDomainPage;
