import Wrapper from 'components/Form/Wrapper';
import { ErrorMessage, FieldProps, FormikHandlers, getIn } from 'formik';
import React, { FunctionComponent, memo, ReactElement } from 'react';
import styled, { css } from 'styled-components/macro';
import inputStyles from '../InputStyles';
import Label from '../Label';
import StyledErrorMessage from '../StyledErrorMessage';

export interface IInputComponent {
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  value: any;
  name: string;
  placeholder?: string;
  error: boolean;
}

export interface IInput {
  className?: string;
  label?: string;
  leftIcon?: ReactElement;
  rightIcon: ReactElement;
  placeholder?: string;
  type?: string;
  inputComponent?: FunctionComponent<IInputComponent>;
  errorMessageComponent?: FunctionComponent;
}

const Input: FunctionComponent<IInput & FieldProps> = ({
  label,
  leftIcon,
  rightIcon,
  className,
  placeholder,
  field,
  inputComponent: InputComponent = StyledInput,
  errorMessageComponent: ErrorMessageComponent = StyledErrorMessage,
  form: { touched, errors },
  type,
  ...rest
}) => {
  return (
    <Wrapper className={className}>
      <label>
        {label && <Label>{label}</Label>}

        <InputContainer>
          {leftIcon && <IconContainer>{leftIcon}</IconContainer>}
          <InputComponent
            {...field}
            placeholder={placeholder}
            error={Boolean(getIn(touched, field.name) && getIn(errors, field.name))}
            type={type}
            hasLeftIcon={Boolean(leftIcon)}
            hasRightIcon={Boolean(rightIcon)}
            {...rest}
          />
          {rightIcon && <RightIconContainer>{rightIcon}</RightIconContainer>}
        </InputContainer>
      </label>
      <ErrorMessage name={field.name} component={ErrorMessageComponent} />
    </Wrapper>
  );
};

export default memo(Input);

export const StyledInputStyles = css<{ error: boolean }>`
  ${inputStyles};
  border-radius: 30px;
  &:disabled {
    background: ${(props) => props.theme.colors.lightGrey};
  }
`;

export const StyledInput = styled.input<{
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  error: boolean;
}>`
  ${StyledInputStyles}
  ${(props) => (props.hasLeftIcon ? 'padding-left: 40px;' : '')}
  ${(props) => (props.hasRightIcon ? 'padding-right: 50px;' : '')}
`;

export const UppercaseInput = styled(StyledInput)`
  text-transform: uppercase;
`;

const InputContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 18px;
  left: 22px;
  ${({ theme }) => theme.fillSvg(theme.colors.dark)};
`;

const RightIconContainer = styled.div`
  position: absolute;
  top: 14px;
  right: 22px;
`;
