import { IMultiSelectOption } from 'components/Form/Select/MultiSelect';
import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import { selectSerializer } from 'libs/serializers';

export interface IFloor {
  from: string;
  to: string;
}

export interface IBuilding {
  prefix: string;
  address: string;
  postal_code: string;
  amount_of_floors: number;
  floors: IFloor[];
}

export type TBuildingOption = IBuilding & IMultiSelectOption & { id: number };

export const useGetBuildings: (forProfile?: boolean) => {
  buildings: TBuildingOption[];
  isLoading: boolean;
} = (forProfile = false) => {
  const { resource: buildings, isLoading } = useFetchResource({
    api: api.account.options.buildings.getAll,
    initialValues: [],
    initialParams: forProfile ? { current_profile: forProfile } : {},
    serializer: (data) => selectSerializer(data.resources, { labelKey: 'prefix', valueKey: 'id' }),
  });
  return { buildings, isLoading };
};
