import LoadMore from 'components/Table/LoadMore';
import ClassifiedPreviewItem from 'components/Website/ClassifiedPreviewItem';
import ContentItem from 'components/Website/ContentItem';
import HorizontalLine from 'components/Website/HorizontalLine';
import PageTitle from 'components/Website/PageTitle';
import SiteContent from 'components/Website/SiteContent';
import usePaginatedResources from 'hooks/usePaginatedResources';
import useSearchParams from 'hooks/useSearchParams';
import api from 'libs/api';
import { IClassified } from 'pages/Classifieds/List';
import React, { FunctionComponent } from 'react';
import useAuth from 'hooks/useAuth';
import useCommon from 'hooks/useCommon';
import ActionLink from 'components/ActionLink';

const ClassifiedList: FunctionComponent = () => {
  const { params } = useSearchParams(window.location.search);
  const { title: commonTitle } = useCommon();
  const { isAuthorized } = useAuth();
  const {
    resources: classifieds,
    loadMore,
    hasMore,
  } = usePaginatedResources<IClassified>({
    api: api.website.classifieds.getAll,
    locationSearch: window.location.search,
    search: params.current,
  });

  return (
    <SiteContent>
      {isAuthorized && (
        <>
          <ContentItem>
            <PageTitle>Private Classifieds</PageTitle>
          </ContentItem>
          <ContentItem>
            <HorizontalLine />
          </ContentItem>
          <ContentItem>
            <p>
              <ActionLink to="/classifieds">Go to the Dashboard</ActionLink> to view all the
              Classifieds available for residents of {commonTitle}
            </p>
          </ContentItem>
        </>
      )}
      <ContentItem>
        <PageTitle>Public Classifieds</PageTitle>
      </ContentItem>
      <ContentItem>
        <HorizontalLine />
      </ContentItem>
      <ContentItem>
        {classifieds.length < 1 && <p>No items were added yet</p>}
        {classifieds.map((item) => (
          <ClassifiedPreviewItem
            key={item.id}
            title={item.title}
            author={item.author}
            publishDate={item.created_at}
            link={`/public/classifieds/${item.id}`}
          />
        ))}

        {classifieds.length !== 0 && <LoadMore loadMore={loadMore} hasMore={hasMore} />}
      </ContentItem>
    </SiteContent>
  );
};

export default ClassifiedList;
