import Flex from 'components/Flex';
import { getIn } from 'formik';
import useOutsidePopupToggle from 'hooks/useOutsidePopupToggle';
import useUser from 'hooks/useUser';
import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg';
import AvatarPlaceholder from 'images/avatar-placeholder.png';
import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import HeaderNavMenuTemplate, { NavMenuLayout } from '../components/HeaderNavMenuTemplate';
import useUserMenu from './useUserMenu';

interface IUserNavMenu {
  className?: string;
}

const UserNavMenu: FunctionComponent<IUserNavMenu> = ({ className = '' }) => {
  const { user, fullName } = useUser();
  const { toggle, isMenuOpen, nodeRef } = useOutsidePopupToggle<HTMLDivElement>();
  const { menuItems } = useUserMenu();

  const avatar = useMemo(() => {
    return getIn(user, ['avatar', 'thumb', 'url'])
      ? `${process.env.REACT_APP_IMAGE_PREFIX}${user.avatar.thumb.url}?${Date.now()}`
      : AvatarPlaceholder;
  }, [user]);

  const name = useMemo(() => {
    return getIn(user, ['first_name']) || getIn(user, ['last_name']) ? fullName : 'Welcome, Guest';
  }, [user]);

  return (
    <Container className={`${className} profile-menu`} onClick={toggle} alignItems="center">
      {isMenuOpen && (
        <HeaderNavMenuTemplate nodeRef={nodeRef} items={menuItems} layout={StyledNavMenuLayout} />
      )}
      <Avatar src={avatar} />
      <Name>{name}</Name>
      <Arrow isMenuOpen={isMenuOpen} />
    </Container>
  );
};

export default UserNavMenu;

const Name = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.dark};
  transition: color 0.3s;
  ${(props) => props.theme.media.xs`
    display: none;
  `};
`;

const Container = styled(Flex)<{ onClick: () => void }>`
  margin-left: 16px;
  cursor: pointer;
  position: relative;
  ${(props) => props.theme.media.xs`
    margin-left: 8px;
  `};
  &:hover {
    ${Name} {
      color: ${(props) => props.theme.colors.accentNormal};
    }
  }
`;

const Avatar = styled.img`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Arrow = styled(({ isMenuOpen, ...rest }) => <ArrowDown {...rest} />)<{ isMenuOpen: boolean }>`
  transition: transform 0.3s;
  ${(props) => props.isMenuOpen && `transform: rotate(180deg);`}
`;

const StyledNavMenuLayout = styled(NavMenuLayout)`
  right: 0;
`;
