import Flex from 'components/Flex';
import { ReactComponent as ArrowDown } from 'icons/arrow-down.svg';
import { ReactComponent as BackIcon } from 'icons/back.svg';
import truncate from 'lodash.truncate';
import React, { Fragment, FunctionComponent, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Button from '../Button';
import useNavigator from 'hooks/useNavigator';

interface IBreadcrumbs {
  className?: string;
  data: Array<{ title: string; to?: string }>;
}

const Breadcrumbs: FunctionComponent<IBreadcrumbs> = ({ className, data }) => {
  const reversedData = useMemo(() => data.reverse(), [data]);
  const { backCallback } = useNavigator();

  return (
    <BreadcrumbsWrap className={className}>
      <Flex alignItems="center" className="hidden-sm">
        {reversedData.map((item, index) => (
          <Fragment key={index}>
            <Item to={item.to || ''} as={item.to ? Link : 'span'}>
              {item.title.length > 30
                ? truncate(item.title, {
                    length: 15,
                    separator: /,? +/,
                  })
                : item.title}
            </Item>
            {index !== data.length - 1 && <ArrowLeft />}
          </Fragment>
        ))}
      </Flex>
      <Flex alignItems="center" className="visible-sm">
        {data.length > 0 && (
          <Button onClick={backCallback} size="sm" icon={<BackIcon width={18} height={18} />}>
            Back
          </Button>
        )}
      </Flex>
    </BreadcrumbsWrap>
  );
};

export default memo(Breadcrumbs);

const Item = styled(Link)<{ to: string }>`
  color: ${(props) => props.theme.colors.inputText};
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: transparent;
  &:first-child {
    color: ${(props) => props.theme.colors.darkGrey};
  }
`;

const ArrowLeft = styled(ArrowDown)`
  transform: rotate(90deg);
`;

const BreadcrumbsWrap = styled(Flex)`
  @media print {
    display: none;
  }
`;
