import { AxiosResponse } from 'axios';
import { TApi } from 'libs/api';
import { DependencyList, useCallback } from 'react';
import { toast } from 'react-toastify';
import useHandleHttpError from './useHandleHttpError';

interface IUseOnSave {
  api: TApi;
  // eslint-disable-next-line @typescript-eslint/ban-types
  params?: object;
  successMessage?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: object;
  onSuccess?: (response: AxiosResponse<any>) => void;
  errorField?: string;
}

interface IUseOnSaveExtra {
  data?: IUseOnSave['data'];
}

export default function useOnSave(
  { api, params, successMessage, data = {}, onSuccess, errorField }: IUseOnSave,
  deps?: DependencyList
) {
  const handleHttpError = useHandleHttpError();
  return useCallback(
    async ({ data: extraData = {} }: IUseOnSaveExtra = {}) => {
      try {
        const response = await api({ ...params, data: { ...data, ...extraData } });
        if (successMessage) {
          toast.success(successMessage);
        }
        if (onSuccess) {
          onSuccess(response);
        }
      } catch (error) {
        handleHttpError(error, errorField);
      }
    },
    [deps]
  );
}
