import Flex from 'components/Flex';
import {
  PROJECT_TITLE,
  PROJECT_YEAR,
  VERSION_URL,
  WEBSITE_PRIVACY_POLICY,
  WEBSITE_TERMS_CONDITIONS,
} from 'libs/constants';
import React from 'react';
import styled from 'styled-components';

const MenuFooter = () => (
  <Wrapper>
    <Flex direction="column" className="mb24">
      <Link href={WEBSITE_TERMS_CONDITIONS} target="_blank" className="mb4">
        Terms & Conditions
      </Link>
      <Link href={WEBSITE_PRIVACY_POLICY} target="_blank">
        Privacy Policy
      </Link>
    </Flex>
    <Copyright>
      © {PROJECT_YEAR} {PROJECT_TITLE}
    </Copyright>
    <Copyright className="mt4">All rights reserved</Copyright>
    <Copyright className="mt4">
      <Link href={VERSION_URL} target="_blank">
        Version history
      </Link>
    </Copyright>
  </Wrapper>
);

export default MenuFooter;

const Wrapper = styled.div`
  padding: 25px 30px;
`;

const Copyright = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.noticeGrey};
`;

const Link = styled.a`
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.disabled};
`;
