import { AxiosResponse } from 'axios';
import { IWizardFormOnSubmitHelpers } from 'components/WizardForm';
import { FormikErrors, FormikHelpers as FormikActions } from 'formik';
import { TApi } from 'libs/api';
import { handleApiErrors } from 'libs/utils';
import { DependencyList, useCallback } from 'react';
import { toast } from 'react-toastify';
import useHandleHttpError from './useHandleHttpError';

export interface IUseOnSubmit<T> {
  api: TApi;
  // eslint-disable-next-line @typescript-eslint/ban-types
  params?: object;
  successMessage?: string;
  serializer?: (values: T) => any;
  validationErrorSerializer?: (errors: FormikErrors<T>, values: T) => FormikErrors<T>;
  onSuccess?: (response: AxiosResponse<any>, actions: FormikActions<T>, values?: T) => void;
  deps?: DependencyList;
}

export default function useOnSubmit<T>({
  api,
  params,
  successMessage,
  serializer = (formValues) => ({ resource: formValues }),
  validationErrorSerializer,
  onSuccess,
  deps = [],
}: IUseOnSubmit<T>) {
  const handleHttpError = useHandleHttpError();

  return useCallback(
    async (formValues: T, actions: FormikActions<T>, helpers?: IWizardFormOnSubmitHelpers) => {
      try {
        const response = await api({ ...params, data: serializer(formValues) });
        if (successMessage) {
          toast.success(successMessage);
        }
        if (onSuccess) {
          onSuccess(response, actions, formValues);
        }
      } catch (error) {
        const errors = handleApiErrors(error);
        if (errors) {
          if (validationErrorSerializer) {
            actions.setErrors(validationErrorSerializer(errors as FormikErrors<T>, formValues));
          } else {
            actions.setErrors(errors as FormikErrors<T>);
          }
          if (helpers) {
            helpers.handleFormErrors(errors);
          }
        } else {
          handleHttpError(error);
        }
      } finally {
        actions.setSubmitting(false);
      }
    },
    [serializer, validationErrorSerializer, api, ...deps]
  );
}
