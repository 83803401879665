import Row from 'components/Row';
import styled from 'styled-components';

export const RemoveButtonRow = styled(Row)`
  button {
    padding-top: 0;
    padding-bottom: 0;
  }
  justify-content: flex-end;
`;

export default RemoveButtonRow;
