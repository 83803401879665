import IndentView from 'components/Card/IndentView';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import BackToWebsiteHeaderLink from 'pages/App/Header/ButtonLayouts/BackToWebsite';
import React, { FunctionComponent } from 'react';

interface IRegistrationApproveTemplate {
  className?: string;
  email: string;
}

const RegistrationApproveTemplate: FunctionComponent<IRegistrationApproveTemplate> = ({
  className,
  email,
}) => {
  const theme = useTheme();

  return (
    <>
      <BackToWebsiteHeaderLink />
      <IndentView title="Registration successful" className={className}>
        <Text className="mt24" color={theme.colors.dark}>
          Your account has been created and is awaiting confirmation.
        </Text>

        <Text className="mt24" color={theme.colors.dark}>
          A confirmation email was sent to your account to <strong>{email}</strong>. You must follow
          the instructions in the email before your account can be activated.
        </Text>
      </IndentView>
    </>
  );
};

export default RegistrationApproveTemplate;
