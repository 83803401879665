import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import Flex from 'components/Flex';
import Title from 'components/PageTitle/Small';

export interface IProps {
  title: string;
  form: JSX.Element;
}

const UserConfirmStep: FunctionComponent<IProps> = ({ title, form }) => (
  <Flex direction="column">
    <TitleStyled>{title}</TitleStyled>
    {form}
  </Flex>
);

const TitleStyled = styled(Title)`
  text-align: center;
  margin-top: 20px;
`;

export default UserConfirmStep;
